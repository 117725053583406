import React from 'react';
import cn from 'class-names';
import { SecondaryActionButton as SharedSecondaryActionButton } from '@sendpayments/react-shared/components/buttons/SecondaryActionButton';
import styles from './SecondaryActionButton.module.less';

/**
 * Wrapper for react-shared's `SecondaryActionButton` to force style overrides as a workaround for adobe-spectrum issues
 */
const SecondaryActionButton = ({ children, onPress, darkText, props }) => {
  return (
    <div className={styles.buttonContainer}>
      <SharedSecondaryActionButton onPress={onPress} UNSAFE_className={cn(styles.button, darkText && styles.darkText)} {...props}>
        {children}
      </SharedSecondaryActionButton>
    </div>
  );
};

export default SecondaryActionButton;
