import React, { useEffect, useState } from 'react';
import { useMyFormState } from 'my-react-form-state/react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { breakpoints } from '@send-base/design-system';
import { View } from '@adobe/react-spectrum';
import yup from 'my-react-form-state/yup';
import { CenteredExpandedSection } from '@send-components/CenteredExpandedSection';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { useConfirmation } from '@sendpayments/react-shared/data-hooks/useConfirmation';
import { Confirmation } from '@send-components/Confirmation';
import { Title } from '@send-components/Title';
import { Subtitle } from '@send-components/Subtitle';
import { Loading } from '@send-components/Loading';
import { API } from 'aws-amplify';
import * as YUP from 'yup';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import styles from './Form.module.less';
import { Modal } from '../../Modal';
import { useForm } from '../Provider';
import Form from './Form';

const YUPSchema = YUP.object().shape({
  workEmail: YUP.string().email().required(),
  firstName: YUP.string().required(),
  lastName: YUP.string().required(),
  company: YUP.string().required(),
  jobTitle: YUP.string(),
  workPhone: YUP.string(),
  country: YUP.string(),
  reason: YUP.string(),
});

const Container = ({ props, theme, metadata }) => {
  const { submitText, subtitle, heading, bg, underlineColour } = props;

  const [
    {
      data: { isVisible },
    },
    { setHidden },
  ] = useForm({ name: 'ContactUs' });

  const [formState, { updateField, submitForm, resetForm }] = useMyFormState({
    initialState: {
      firstName: undefined,
      lastName: undefined,
      company: undefined,
      jobTitle: undefined,
      workEmail: undefined,
      workPhone: undefined,
      country: undefined,
      reason: undefined,
    },
    formSchema: yup.formSchema(YUPSchema),
  });

  const [isConfirmationVisible, { setConfirmationVisible, setConfirmationHidden }] = useConfirmation();
  const [isLoading, setIsLoading] = useState(false);

  const { isMedia } = useBreakpoint(breakpoints);

  const isMediaSmall = isMedia({ to: breakpoints.sm });

  const [countries, setCountries] = useState([]);

  const onFieldChangeHandler = (field, value) => updateField({ field, value });

  const onSubmitHandler = async () => {
    setIsLoading(true);
    setConfirmationVisible();

    const payload = {
      body: formState.data,
    };
    await API.post('SendApi', '/web/contact', payload);
    setIsLoading(false);
  };

  const onClearEverything = () => {
    setHidden();
    setConfirmationHidden();
    setIsLoading(false);
    resetForm({});
  };

  useEffect(() => {
    const doFetch = async () => {
      const response = await fetch('https://restcountries.com/v3.1/all');
      const countriesList = await response.json();
      setCountries(countriesList.map(({ name }) => name.common).sort());
    };

    if (isVisible && countries.length === 0) {
      doFetch();
    }
  }, [isVisible]);

  const { isSubmittable, isSubmitted } = formState;
  const footerText = (
    <span>
      By entering your email, you agree to our{' '}
      <a href={metadata?.tc} target="_blank" rel="noreferrer">
        Terms and Conditions
      </a>{' '}
      and{' '}
      <a href={metadata?.privacyPolicy} target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      , as well as occasional updates about our products and services. You can unsubscribe anytime.
    </span>
  );

  return (
    isVisible && (
      <>
        <Modal
          name="ContactUs"
          theme={theme}
          bg={bg}
          onClose={onClearEverything}
          onSubmit={onSubmitHandler}
          footerText={(!isMediaSmall && footerText) || ''}
          submitText={submitText}
          isSubmitDisabled={!isSubmittable || isSubmitted}
          showFooter={!isConfirmationVisible}
          showSubmit={!isConfirmationVisible}
        >
          {isLoading && isConfirmationVisible && <Loading />}
          {!isLoading && isConfirmationVisible && (
            <Confirmation
              iconType="success"
              title="Got <u>it!</u>"
              text="<b>Thanks for your enquiry about partnering with Send</b>. <br/> We’ve got your details and one of our team will be in touch the same working day."
              confirmText="Go back to Partnerships"
              onDismiss={onClearEverything}
              onConfirm={onClearEverything}
            />
          )}
          {!isConfirmationVisible && (
            <CenteredExpandedSection horizontalCentered className={styles.modalContainer}>
              <Title center level="3" underlineColour={underlineColour}>
                <SecondaryFont>
                  <HtmlText>{heading}</HtmlText>
                </SecondaryFont>
              </Title>
              <View height={sizes.size50} />
              <Subtitle level="2" center>
                <HtmlText>{subtitle}</HtmlText>
              </Subtitle>
              <View height={sizes.size50} />
              <Form formState={formState} countries={countries} onFieldChange={onFieldChangeHandler} onSubmit={submitForm} />
            </CenteredExpandedSection>
          )}
        </Modal>
      </>
    )
  );
};

export default Container;
