import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Image = ({ fluidData, alt, objectPosition }) => {
  const imgStyle = {};
  if (objectPosition) {
    imgStyle.objectPosition = objectPosition;
  }

  return <Img fluid={fluidData} alt={alt} imgStyle={imgStyle} style={{ height: 'inherit', maxHeight: 'inherit' }} />;
};

Image.propTypes = {
  fluidData: PropTypes.object.isRequired,
  alt: PropTypes.string,
  objectPosition: PropTypes.string,
};

Image.defaultProps = {
  alt: undefined,
  objectPosition: undefined,
};

export default Image;
