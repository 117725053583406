import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'class-names';
import { useSwipeable } from 'react-swipeable';
import { OverflowDetector } from 'react-overflow';
import styles from './HorizontalSwipeableList.module.less';

const getTransitionPositionStyle = ({ itemWidth, currentPosition }) => {
  const transformNumber = itemWidth * currentPosition;
  if (transformNumber === 0) {
    return {
      transform: `translateX(0px)`,
    };
  }

  return {
    transform: `translateX(-${transformNumber}px)`,
  };
};

const HorizontalSwipeableList = ({ children, itemWidth, disableSwipe, height }) => {
  const [itemPosition, setItemPosition] = useState(0);
  const [lastView, setLastView] = useState(0);

  const incrementPosition = () => {
    const nextPosition = itemPosition + 1;
    if (children.length - 1 >= nextPosition) {
      setItemPosition(nextPosition);
    }
  };
  const decrementPosition = () => {
    const nextPosition = itemPosition - 1;
    if (nextPosition >= 0) {
      setItemPosition(nextPosition);
    }
  };

  const scrollRef = useRef();

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      incrementPosition();
    },
    onSwipedRight: () => {
      decrementPosition();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    if (itemPosition !== lastView) {
      setLastView(itemPosition);
    }
  }, [itemPosition]);

  const onWindowResizeHandler = () => {
    if (itemPosition !== 0) {
      setItemPosition(0);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResizeHandler);
    return () => {
      window.removeEventListener('resize', onWindowResizeHandler);
    };
  }, [itemPosition]);

  const fullWidth = itemWidth * children.filter(({ props } = {}) => !!props).length;

  const stylesTransition = useMemo(
    () => getTransitionPositionStyle({ itemWidth, currentPosition: itemPosition }),
    [children, itemPosition],
  );

  const onOverflowChangeHandler = () => {};

  return (
    <div {...(disableSwipe ? {} : handlers)}>
      <OverflowDetector
        onOverflowChange={onOverflowChangeHandler}
        className={cn(styles.container, styles.centered)}
        style={{ height }}
      >
        <div ref={scrollRef} className={styles.scroll} style={{ width: `${fullWidth}px`, ...stylesTransition }}>
          {children.map(
            ({ props } = {}, index) =>
              props && (
                <div style={{ width: `${itemWidth}px` }} key={`${index}`}>
                  {props.children}
                </div>
              ),
          )}
        </div>
      </OverflowDetector>
    </div>
  );
};

HorizontalSwipeableList.propTypes = {
  children: PropTypes.array.isRequired,
  itemWidth: PropTypes.number.isRequired,
  disableSwipe: PropTypes.bool,
  height: PropTypes.string,
};

HorizontalSwipeableList.defaultProps = {
  disableSwipe: false,
  height: undefined,
};

export default HorizontalSwipeableList;
