import img0 from '@send-images/animations/hero_personal_mob_img_0.png';
import img1 from '@send-images/animations/hero_personal_mob_img_1.png';
import img2 from '@send-images/animations/hero_personal_mob_img_2.png';
import img3 from '@send-images/animations/hero_personal_mob_img_3.png';
import img4 from '@send-images/animations/hero_personal_mob_img_4.png';
import img5 from '@send-images/animations/hero_personal_mob_img_5.png';
import img6 from '@send-images/animations/hero_personal_mob_img_6.png';
import img7 from '@send-images/animations/hero_personal_mob_img_7.png';
import img8 from '@send-images/animations/hero_personal_mob_img_8.png';
import img9 from '@send-images/animations/hero_personal_mob_img_9.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 179,
  w: 500,
  h: 381,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 106, h: 108, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 99, h: 98, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 105, h: 105, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 93, h: 93, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 70, h: 70, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 325, h: 104, u: '', p: img5, e: 0 },
    { id: 'image_6', w: 69, h: 69, u: '', p: img6, e: 0 },
    { id: 'image_7', w: 323, h: 102, u: '', p: img7, e: 0 },
    { id: 'image_8', w: 70, h: 70, u: '', p: img8, e: 0 },
    { id: 'image_9', w: 325, h: 104, u: '', p: img9, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Bolt3.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 131, s: [6] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 133, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 148, s: [100] },
                { t: 150, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 131, s: [0] },
                { t: 149, s: [7] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [482, 413, 0], ix: 2 },
            a: { a: 0, k: [53, 54, 0], ix: 1 },
            s: { a: 0, k: [101.887, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 128,
          op: 152,
          st: 20,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Bolt2.pdf',
          cl: 'pdf',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 128, s: [6] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 130, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 148, s: [100] },
                { t: 150, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 128, s: [10] },
                { t: 149, s: [6] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [85, 477, 0], ix: 2 },
            a: { a: 0, k: [49.5, 49, 0], ix: 1 },
            s: { a: 0, k: [-100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 128,
          op: 152,
          st: 94,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'Bolt4.pdf',
          cl: 'pdf',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 79, s: [6] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 81, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 97, s: [100] },
                { t: 99, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 79, s: [0] },
                { t: 98, s: [6] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [484, 365, 0], ix: 2 },
            a: { a: 0, k: [52.5, 52.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 79,
          op: 101,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'Bolt3.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 83, s: [6] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 85, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 97, s: [100] },
                { t: 99, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 83, s: [0] },
                { t: 98, s: [-8] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [82, 373, 0], ix: 2 },
            a: { a: 0, k: [53, 54, 0], ix: 1 },
            s: { a: 0, k: [-94.34, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 82,
          op: 101,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'Bolt2.pdf',
          cl: 'pdf',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 36, s: [6] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 38, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 51, s: [100] },
                { t: 53, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 36, s: [0] },
                { t: 53, s: [4] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [490, 276, 0], ix: 2 },
            a: { a: 0, k: [49.5, 49, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 37,
          op: 58,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'Bolt1.pdf',
          cl: 'pdf',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 34, s: [6] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 36, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 51, s: [100] },
                { t: 53, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 34, s: [0] },
                { t: 53, s: [4] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [69, 214, 0], ix: 2 },
            a: { a: 0, k: [46.5, 46.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 34,
          op: 58,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 6',
          parent: 11,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [100] },
                { t: 164, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [163, 147, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [56.375, 56.375], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: { a: 0, k: 0, ix: 1 },
                  e: { a: 0, k: 100, ix: 2 },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.458824008119, 0.917646998985, 0.494118006089, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-113, -99.25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 112, s: [0] },
                  { t: 123, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 5',
          parent: 13,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [100] },
                { t: 164, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [162, 145, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [56.375, 56.375], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: { a: 0, k: 0, ix: 1 },
                  e: { a: 0, k: 100, ix: 2 },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.458824008119, 0.917646998985, 0.494118006089, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-113, -99.25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 63, s: [0] },
                  { t: 75, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Shape Layer 4',
          parent: 15,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [100] },
                { t: 164, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [163, 146, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [56.375, 56.375], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 1,
                    k: [
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [0] },
                      { t: 30, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.458824008119, 0.917646998985, 0.494118006089, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-113, -99.25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 2,
          nm: 'GreenBag 2',
          parent: 11,
          refId: 'image_4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 123, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 127, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 167, s: [100] },
                { t: 176, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 47, 0], ix: 2 },
            a: { a: 0, k: [35, 35, 0], ix: 1 },
            s: { a: 0, k: [85, 85, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 2,
          nm: 'Bag 2',
          refId: 'image_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 112, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 119, s: [-2] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 151, s: [-2] },
                { t: 159, s: [0] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [290, 477, 0], ix: 2 },
            a: { a: 0, k: [162.5, 52, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 112,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 119,
                  s: [117, 117, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 151,
                  s: [117, 117, 100],
                },
                { t: 159, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 2,
          nm: 'GreenBasket 2',
          parent: 13,
          refId: 'image_6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 75, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 79, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 167, s: [100] },
                { t: 176, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [49.5, 47, 0], ix: 2 },
            a: { a: 0, k: [34.5, 34.5, 0], ix: 1 },
            s: { a: 0, k: [85, 85, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 2,
          nm: 'Basket 2',
          refId: 'image_7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 63, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 70, s: [2] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 99, s: [2] },
                { t: 107, s: [0] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [290, 383, 0], ix: 2 },
            a: { a: 0, k: [161.5, 51, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 63,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 70,
                  s: [120, 120, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 99,
                  s: [120, 120, 100],
                },
                { t: 107, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 2,
          nm: 'GreenHouse 2',
          parent: 15,
          refId: 'image_8',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 30, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 34, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 167, s: [100] },
                { t: 176, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50.208, 46.792, 0], ix: 2 },
            a: { a: 0, k: [35, 35, 0], ix: 1 },
            s: { a: 0, k: [82.5, 82.5, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 2,
          nm: 'House 2',
          refId: 'image_9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 25, s: [-2] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 54, s: [-2] },
                { t: 62, s: [0] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [290, 289, 0], ix: 2 },
            a: { a: 0, k: [162.5, 52, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 18,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 25,
                  s: [120, 120, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 54,
                  s: [120, 120, 100],
                },
                { t: 62, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Pre-comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 4, ix: 10 },
        p: { a: 0, k: [250, 203.5, 0], ix: 2 },
        a: { a: 0, k: [290, 383, 0], ix: 1 },
        s: { a: 0, k: [84, 84, 100], ix: 6 },
      },
      ao: 0,
      w: 580,
      h: 766,
      ip: 0,
      op: 375,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
