import React from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { breakpoints } from '@send-base/design-system';
import { Flex, View, Form, TextField, Picker, Item } from '@adobe/react-spectrum';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import styles from './Form.module.less';

const showError = (field) => {
  if (field.isTouched) {
    return field.isValid ? 'valid' : 'invalid';
  }
  return undefined;
};

const reasonsList = ['New partnership', 'Existing partnership', 'Affiliate or referrer', 'Support', 'Something else'];

const ContactUsForm = ({
  formState: {
    fields: { firstName, lastName, company, jobTitle, workEmail, workPhone, country, reason },
    isSubmitted,
  },
  countries,
  onFieldChange,
}) => {
  const { isMedia } = useBreakpoint(breakpoints);

  const isMediaSmall = isMedia({ to: breakpoints.sm });

  return (
    <Form>
      <div className={styles.formContainer}>
        <Flex gap={!isMediaSmall && sizes.size400} direction={isMediaSmall && 'column'}>
          <View flex="1">
            <TextField
              validationState={showError(firstName)}
              label="First Name"
              defaultValue={firstName.value}
              onChange={(value) => onFieldChange('firstName', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your first name…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            />
          </View>
          <View flex="1">
            <TextField
              id="lastName"
              validationState={showError(lastName)}
              label="Last name"
              defaultValue={lastName.value}
              onChange={(value) => onFieldChange('lastName', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your last name…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            />
          </View>
        </Flex>
        <Flex gap={!isMediaSmall && sizes.size400} direction={isMediaSmall && 'column'}>
          <View flex="1">
            <TextField
              id="company"
              label="Company"
              validationState={showError(company)}
              defaultValue={company.value}
              onChange={(value) => onFieldChange('company', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your company…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            />
          </View>
          <View flex="1">
            <TextField
              id="workPhone"
              label="Work phone"
              inputMode="tel"
              necessityIndicator="label"
              defaultValue={workPhone.value}
              onChange={(value) => onFieldChange('workPhone', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your work phone…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            />
          </View>
        </Flex>
        <Flex gap={!isMediaSmall && sizes.size400} direction={isMediaSmall && 'column'}>
          <View flex="1">
            <TextField
              id="jobTitle"
              label="Job title"
              necessityIndicator="label"
              defaultValue={jobTitle.value}
              onChange={(value) => onFieldChange('jobTitle', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your job title…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            />
          </View>
          <View flex="1">
            <TextField
              id="workEmail"
              validationState={showError(workEmail)}
              type="email"
              label="Work email"
              defaultValue={workEmail.value}
              onChange={(value) => onFieldChange('workEmail', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your work email…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            />
          </View>
        </Flex>
        <Flex gap={!isMediaSmall && sizes.size400} direction={isMediaSmall && 'column'}>
          <View flex="1">
            <Picker
              id="country"
              label="Country"
              necessityIndicator="label"
              value={country.value}
              onSelectionChange={(value) => onFieldChange('country', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your country…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            >
              {countries.map((c) => (
                <Item key={c}>{c}</Item>
              ))}
            </Picker>
          </View>
          <View flex="1">
            <Picker
              id="reason"
              label="Reason for contacting"
              necessityIndicator="label"
              value={reason.value}
              onSelectionChange={(value) => onFieldChange('reason', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your reason…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            >
              {reasonsList.map((r) => (
                <Item key={r}>{r}</Item>
              ))}
            </Picker>
          </View>
        </Flex>
      </div>
    </Form>
  );
};

export default ContactUsForm;
