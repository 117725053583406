import img0 from '@send-images/animations/textlist_home_3_img_0.png';
import img1 from '@send-images/animations/textlist_home_3_img_1.png';
import img2 from '@send-images/animations/textlist_home_3_img_2.png';
import img3 from '@send-images/animations/textlist_home_3_img_3.png';
import img4 from '@send-images/animations/textlist_home_3_img_4.png';
import img5 from '@send-images/animations/textlist_home_3_img_5.png';
import img6 from '@send-images/animations/textlist_home_3_img_6.png';
import img7 from '@send-images/animations/textlist_home_3_img_7.png';
import img8 from '@send-images/animations/textlist_home_3_img_8.png';
import img9 from '@send-images/animations/textlist_home_3_img_9.png';
import img10 from '@send-images/animations/textlist_home_3_img_10.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 175,
  w: 580,
  h: 766,
  nm: 'HomeAnim',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 51, h: 51, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 151, h: 86, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 151, h: 86, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 151, h: 86, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 151, h: 86, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 151, h: 86, u: '', p: img5, e: 0 },
    { id: 'image_6', w: 151, h: 86, u: '', p: img6, e: 0 },
    { id: 'image_7', w: 151, h: 86, u: '', p: img7, e: 0 },
    { id: 'image_8', w: 151, h: 86, u: '', p: img8, e: 0 },
    { id: 'image_9', w: 151, h: 86, u: '', p: img9, e: 0 },
    { id: 'image_10', w: 588, h: 766, u: '', p: img10, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Trans.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 38, s: [226] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 44, s: [226] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 84, s: [-139] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 112, s: [-139] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 133, s: [312] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 139, s: [313] },
                { t: 156, s: [0] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [290, 383, 0], ix: 2 },
            a: { a: 0, k: [25.5, 25.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'EUR.pdf',
          cl: 'pdf',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [381, 67.75, 0],
                  to: [0, 52.833, 0],
                  ti: [0, -52.833, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.167, y: 0.167 },
                  t: 24,
                  s: [381, 384.75, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 45,
                  s: [381, 384.75, 0],
                  to: [0, 26.833, 0],
                  ti: [0, -26.833, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.167, y: 0.167 },
                  t: 61,
                  s: [381, 545.75, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { i: { x: 0.667, y: 1 }, o: { x: 0.167, y: 0 }, t: 112, s: [381, 545.75, 0], to: [0, -26, 0], ti: [0, 26, 0] },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.167, y: 0.167 },
                  t: 133,
                  s: [381, 389.75, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 139,
                  s: [381, 389.75, 0],
                  to: [0, -53.5, 0],
                  ti: [0, 53.5, 0],
                },
                { t: 156, s: [381, 68.75, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'EUR.pdf',
          cl: 'pdf',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 0, s: [207, 706, 0], to: [0, -93.5, 0], ti: [0, 93.5, 0] },
                { i: { x: 0.667, y: 0.667 }, o: { x: 0.167, y: 0.167 }, t: 38, s: [207, 145, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 45,
                  s: [207, 145, 0],
                  to: [0, 79.333, 0],
                  ti: [0, -79.333, 0],
                },
                { i: { x: 0.667, y: 0.667 }, o: { x: 0.167, y: 0.167 }, t: 83, s: [207, 621, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 112,
                  s: [207, 621, 0],
                  to: [0, -25.167, 0],
                  ti: [0, 25.167, 0],
                },
                { i: { x: 0.667, y: 0.667 }, o: { x: 0.167, y: 0.167 }, t: 133, s: [207, 470, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 139,
                  s: [207, 470, 0],
                  to: [0, 39.667, 0],
                  ti: [0, -39.667, 0],
                },
                { t: 156, s: [207, 708, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'EUR.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75, -437, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'CAD.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, -357, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'AUD.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, -277, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: 'NZD.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, -197, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: 'SGD.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, -117, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 2,
          nm: 'USD.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, -37, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 2,
          nm: 'CAD.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 123, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 2,
          nm: 'AUD.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 203, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 2,
          nm: 'NZD.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 283, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 2,
          nm: 'GBP.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [74.75, 362.5, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 2,
          nm: 'USD.pdf',
          cl: 'pdf',
          parent: 2,
          refId: 'image_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 443, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 3,
          nm: 'Null 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [290, 383, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 2,
          nm: 'EUR.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75, -437, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 2,
          nm: 'THB.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75, -357.75, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 2,
          nm: 'AUD.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, -277, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 2,
          nm: 'USD.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [74.5, -197, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 2,
          nm: 'SGD.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, -117, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 21,
          ty: 2,
          nm: 'USD.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, -37, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 22,
          ty: 2,
          nm: 'THB.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [74.5, 123.662, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 23,
          ty: 2,
          nm: 'AUD.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 203, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 24,
          ty: 2,
          nm: 'NZD.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 283, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 25,
          ty: 2,
          nm: 'SGD.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 363, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 26,
          ty: 2,
          nm: 'USD.pdf',
          cl: 'pdf',
          parent: 3,
          refId: 'image_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 443, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Pre-comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 4, ix: 10 },
        p: { a: 0, k: [244, 531, 0], ix: 2 },
        a: { a: 0, k: [290, 383, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [466.876, 260.363],
                [115.043, 260.363],
                [111.847, 501.717],
                [463.681, 501.717],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      w: 580,
      h: 766,
      ip: 0,
      op: 375,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'textlist_homeBG_3.png',
      cl: 'png',
      refId: 'image_10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [294, 383, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 375,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
