import React, { useEffect } from 'react';
import { initializeUserInteractionTracker } from '@sendpayments/js-utils/services/user-interaction-tracker';
import { initializeAnalytics } from '@sendpayments/js-utils/services/analytics/legacy';
import { initializeAdsManager } from '@sendpayments/js-utils/services/ads-manager';
import credentials from '@send-base/services.config';

const env = process.env.ENV;
const isProd = env === 'prod';
const project = 'sendpayments';

export const credentialKey = isProd ? 'prod' : 'staging';

// we need to use this component to load external libraries that uses the window object. This is because
// SSR do not support it before useEffect is executed
const PackagesLoader = () => {
  useEffect(() => {
    initializeAnalytics({ credentials, credentialKey, project });

    if (isProd) {
      initializeUserInteractionTracker({ credentials: (credentials.hotjar && credentials.hotjar[env]) || {} });
      initializeAdsManager({ credentials: credentials.taboola });
    }
  }, []);

  return <div />;
};

export default PackagesLoader;
