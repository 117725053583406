import React, { useEffect, useState } from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { Flex } from '@adobe/react-spectrum';
import { Title } from '@send-components/Title';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import cn from 'class-names';
import { breakpoints } from '@send-base/design-system';
import styles from './TrustpilotCarousel.module.less';

const TrustpilotCarousel = ({ name, theme, props }) => {
  const { minHeight, heading, underlineColour } = props;
  const { isMedia } = useBreakpoint(breakpoints);
  const isMediaMobile = isMedia({ to: breakpoints.sm });
  const [loaded, setLoaded] = useState(false);

  const trustpilotRef = React.useRef(null);

  useEffect(() => {
    // this is a hack since window is not available on SSR
    const loadTrustPilot = () =>
      setTimeout(() => {
        if (window.Trustpilot && !loaded) {
          setLoaded(true);
          window.Trustpilot.loadFromElement(trustpilotRef.current, true);
        } else {
          loadTrustPilot();
        }
      }, 1000);

    loadTrustPilot();
  }, [isMediaMobile]);

  useEffect(() => {
    if (loaded) {
      setLoaded(false);
    }
  }, [isMediaMobile]);

  return (
    <WebsiteTheme name={name} theme={theme}>
      <div className={styles.container}>
        <Flex
          minHeight={!isMediaMobile ? `${minHeight}px` : 'auto'}
          maxWidth={'1366px'}
          margin="0 auto"
          direction="column"
          justifyContent="center"
          gap={sizes.size1200}
          height="100%"
        >
          <Title level="3" lineHeight="1.4" center underlineColour={underlineColour}>
            <SecondaryFont>
              <HtmlText>{heading}</HtmlText>
            </SecondaryFont>
          </Title>

          <div
            ref={trustpilotRef}
            className={cn('trustpilot-widget', styles.trustpilotWidget)}
            data-locale="en-US"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="6064e8e2e143b400018567c5"
            data-style-height="140px"
            data-style-width="100%"
            data-theme="dark"
            data-tags="SelectedReview"
            data-review-languages="en"
            data-font-family="Poppins"
            data-text-color="#FFFFFF"
          >
            <a href="https://www.trustpilot.com/review/sendpayments.com" target="_blank" rel="noopener noreferrer">
              Trustpilot
            </a>
          </div>
        </Flex>
      </div>
    </WebsiteTheme>
  );
};

TrustpilotCarousel.displayName = 'TrustpilotCarousel';

export default TrustpilotCarousel;
