import React, { useState, useEffect } from 'react';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { View } from '@adobe/react-spectrum';
import { Header } from '@send-components/Header';
import { Footer } from '@send-components/Footer';
import { useFormsContext } from '@send-components/Forms/Provider';
import { getFormByName } from '@send-components/Forms';
import { CookieBar } from '@send-components/CookieBar';
import './Main.modules.less';

const Main = ({ name, metadata, children, theme, header, footer, navigation }) => {
  const [
    {
      data: { registeredForms },
    },
  ] = useFormsContext();

  const cookieBarHeight = 56;
  const [isAccepted, setIsAccepted] = useState(true);
  const onAcceptCookie = () => {
    localStorage.setItem('isAcceptCookie', true);
    setIsAccepted(true);
  };

  useEffect(() => {
    setIsAccepted(localStorage.getItem('isAcceptCookie') === 'true' || false);
  }, []);

  return (
    <WebsiteTheme name={name} theme={theme}>
      {registeredForms.map(({ formName, props, theme: formTheme }) => {
        const Form = getFormByName(formName);
        return <Form key={formName} props={props} theme={formTheme} metadata={metadata} />;
      })}
      <CookieBar onAcceptCookie={onAcceptCookie} isAccepted={isAccepted} height={cookieBarHeight} />
      <WebsiteTheme name={name} theme={header.theme}>
        <Header
          selected={name}
          metadata={metadata}
          theme={header.theme}
          navigation={navigation}
          topGap={isAccepted ? 0 : cookieBarHeight}
          backgroundColor={header.backgroundColor}
        />
      </WebsiteTheme>
      <View>{children}</View>
      <Footer metadata={metadata} theme={footer.theme} bgColour={footer.bgColour} navigation={navigation} />
    </WebsiteTheme>
  );
};

export default Main;
