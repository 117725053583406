import img0 from '@send-images/animations/hero_business_back_img_0.png';
import img1 from '@send-images/animations/hero_business_back_img_1.png';
import img2 from '@send-images/animations/hero_business_back_img_2.png';
import img3 from '@send-images/animations/hero_business_back_img_3.png';
import img4 from '@send-images/animations/hero_business_back_img_4.png';
import img5 from '@send-images/animations/hero_business_back_img_5.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 375,
  w: 580,
  h: 766,
  nm: 'Business Hero 2',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 180, h: 175, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 394, h: 332, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 2642, h: 6000, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 1657, h: 3136, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 1355, h: 3190, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 961, h: 1055, u: '', p: img5, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'Pre-comp 1',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 358, s: [100] },
                { t: 374, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -180, ix: 10 },
            p: { a: 0, k: [299, 478, 0], ix: 2 },
            a: { a: 0, k: [291, 400, 0], ix: 1 },
            s: { a: 0, k: [61, 61, 100], ix: 6 },
          },
          ao: 0,
          w: 582,
          h: 800,
          ip: 285,
          op: 428,
          st: 285,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'Pre-comp 1',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -180, ix: 10 },
            p: { a: 0, k: [299, 478, 0], ix: 2 },
            a: { a: 0, k: [291, 400, 0], ix: 1 },
            s: { a: 0, k: [61, 61, 100], ix: 6 },
          },
          ao: 0,
          w: 582,
          h: 800,
          ip: 142,
          op: 285,
          st: 142,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          nm: 'Pre-comp 1',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                { t: 18, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -180, ix: 10 },
            p: { a: 0, k: [299, 478, 0], ix: 2 },
            a: { a: 0, k: [291, 400, 0], ix: 1 },
            s: { a: 0, k: [61, 61, 100], ix: 6 },
          },
          ao: 0,
          w: 582,
          h: 800,
          ip: 0,
          op: 143,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'FinancialTower.png',
          cl: 'png',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 238, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 248, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 350, s: [100] },
                { t: 357, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 238, s: [-6] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 248, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 350, s: [1.4] },
                { t: 357, s: [5] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [290, 890, 0], ix: 2 },
            a: { a: 0, k: [1331.588, 5771.987, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 238,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 248,
                  s: [15, 15, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 350,
                  s: [15, 15, 100],
                },
                { t: 357, s: [10, 10, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 382,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'Liberty.png',
          cl: 'png',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 128, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 138, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 238, s: [100] },
                { t: 250, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 128, s: [-2] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 138, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 238, s: [-0.9] },
                { t: 250, s: [-6] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [294, 830, 0], ix: 2 },
            a: { a: 0, k: [829.4, 3129.451, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 128,
                  s: [20, 20, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 138,
                  s: [27, 27, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 238,
                  s: [27, 27, 100],
                },
                { t: 250, s: [23, 23, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'BigBen--SoloBW.png',
          cl: 'png',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 10, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 123, s: [100] },
                { t: 132, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 8, s: [-5] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [5] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 123, s: [7] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 131, s: [2.222] },
                { t: 132, s: [14] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [288, 885, 0], ix: 2 },
            a: { a: 0, k: [677.5, 3168.029, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 8,
                  s: [19, 19, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 18,
                  s: [28, 28, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 123,
                  s: [28, 28, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 131,
                  s: [23.111, 23.111, 100],
                },
                { t: 132, s: [0, 0, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 382,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: 'Texture.pdf',
          cl: 'pdf',
          refId: 'image_5',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [50] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 7, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 364, s: [100] },
                { t: 373, s: [50] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [46] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 12, s: [65] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 123, s: [60] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 132, s: [-77] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 237, s: [-74] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 251, s: [17] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 353, s: [7] },
                { t: 374, s: [46] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 12,
                  s: [344, 448, 0],
                  to: [-4.474, 0, 0],
                  ti: [11.367, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 124,
                  s: [344, 448, 0],
                  to: [-0.905, 0, 0],
                  ti: [0.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 133,
                  s: [369.396, 355, 0],
                  to: [-12.299, 0, 0],
                  ti: [0.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 237,
                  s: [369.396, 355, 0],
                  to: [-12.299, 0, 0],
                  ti: [0.906, 0, 0],
                },
                { t: 251, s: [345.396, 418, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [480.5, 527.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 12,
                  s: [57.024, 57.024, 100],
                },
                { t: 353, s: [57.024, 57.024, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 382,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'BOTTOM.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: 0, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 76, s: [10] },
                { t: 151, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 0,
                  s: [443, 664, 0],
                  to: [-1.833, -1.833, 0],
                  ti: [-2.285, -2.285, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 40,
                  s: [436.408, 657.408, 0],
                  to: [2.807, 2.807, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 76,
                  s: [454, 675, 0],
                  to: [0, 0, 0],
                  ti: [2.887, 2.887, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 121,
                  s: [448.165, 677.165, 0],
                  to: [-2.204, -2.204, 0],
                  ti: [0.794, 0.794, 0],
                },
                { t: 151, s: [443, 664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [90, 87.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 0,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 76,
                  s: [92, 92, 100],
                },
                { t: 151, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 450,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'ZigZag.png',
          cl: 'png',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: 0, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 71, s: [10] },
                { t: 151, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 0,
                  s: [150.259, 167.259, 0],
                  to: [1.667, -1.667, 0],
                  ti: [0.167, 0.5, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 32,
                  s: [160.258, 157.258, 0],
                  to: [-0.167, -0.5, 0],
                  ti: [-2.667, -2.167, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 71,
                  s: [149.258, 164.258, 0],
                  to: [2.667, 2.167, 0],
                  ti: [-0.167, -0.5, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 111,
                  s: [176.258, 170.258, 0],
                  to: [0.167, 0.5, 0],
                  ti: [4.333, 0.5, 0],
                },
                { t: 151, s: [150.259, 167.259, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [280.939, 260.857, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.703, 0.703, 0.703], y: [1, 1, 1] },
                  o: { x: [0.197, 0.197, 0.197], y: [0, 0, 0] },
                  t: 0,
                  s: [43, 43, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 71,
                  s: [35, 35, 100],
                },
                { t: 151, s: [43, 43, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 450,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Business Hero',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [290, 383, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [-2, -34],
                [2, 42],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [2, 34],
                [-2, -42],
                [0, 0],
                [0, 0],
              ],
              v: [
                [250, -114],
                [-346, -94],
                [-128, 520],
                [138, 733],
                [454, 733],
                [694, 720],
                [976, 44],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      w: 580,
      h: 766,
      ip: 0,
      op: 375,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
