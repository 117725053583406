import useFormsContext from './useFormsContext';

const useForm = ({ name }) => {
  const [
    {
      data: { forms },
    },
  ] = useFormsContext();
  const form = forms && forms[name];
  return [{ data: { isVisible: !!form.isVisible } }, { setVisible: form.setVisible, setHidden: form.setHidden }];
};

export default useForm;
