import React from 'react';
import { Flex, View } from '@adobe/react-spectrum';
import { StaticImage } from '@send-components/StaticImage';
import { Subtitle } from '@send-components/Subtitle';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import styles from './ImageCarousel.module.less';

const CardImage = ({ image, text }) => (
  <div className={styles.cardContainer}>
    <Flex direction="column">
      <View marginBottom={30}>
        <StaticImage src={image} />
      </View>
      <View>
        <Subtitle level="2" center>
          <HtmlText>{text}</HtmlText>
        </Subtitle>
      </View>
    </Flex>
  </div>
);

export default CardImage;
