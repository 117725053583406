import React from 'react';
import cn from 'class-names';
import { PrimaryButton as SharedPrimaryButton } from '@sendpayments/react-shared/components/buttons/PrimaryButton';
import styles from './PrimaryButton.module.less';

/**
 * Wrapper for react-shared's `PrimaryButton` to force style overrides as a workaround for adobe-spectrum issues
 */
const PrimaryButton = ({ children, isPrimaryHomeBtn, onPress, props }) => {
  return (
    <div className={styles.buttonContainer}>
      <SharedPrimaryButton
        onPress={onPress}
        UNSAFE_className={cn(styles.button, isPrimaryHomeBtn && styles.buttonPrimaryHome)}
        {...props}
      >
        {children}
      </SharedPrimaryButton>
    </div>
  );
};

export default PrimaryButton;
