import React from 'react';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { StaticImage } from '@send-components/StaticImage';
import { Title } from '@send-components/Title';
import { Subtitle } from '@send-components/Subtitle';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { sizes } from '@sendpayments/react-shared/design-system';
import { Flex, View } from '@adobe/react-spectrum';
import { breakpoints } from '@send-base/design-system';
import { SectionLayout } from '../SectionLayout';
import styles from './TextStory.module.less';

const ListFeature = ({ heading, list, underlineColour }) => (
  <Flex justifyContent="center">
    <div className={styles.cardContainer}>
      <View padding={sizes.size300} maxWidth={sizes.size4600}>
        <Flex direction="column" gap={sizes.size300}>
          <Title color="primary" level="5" lineHeight="1.4" center underlineColour={underlineColour}>
            <SecondaryFont>
              <HtmlText>{heading}</HtmlText>
            </SecondaryFont>
          </Title>
          <Flex direction="column" gap={sizes.size225}>
            {list.map((featureItem, idx) => (
              <Flex key={idx} alignItems="center" direction="row" gap={sizes.size130}>
                <View alignSelf="flex-start">
                  <StaticImage src="check-circle.svg" width={sizes.size250} />
                </View>
                <Subtitle level="4" weight={200} leftAlign lineHeight="1.4">
                  <HtmlText>{featureItem}</HtmlText>
                </Subtitle>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </View>
    </div>
  </Flex>
);

const Item = ({ type, data, isMobile }) => {
  if (type === 'text') {
    return (
      <View marginBottom={sizes.size300}>
        <HtmlText>{data}</HtmlText>
      </View>
    );
  }

  if (type === 'listFeature') {
    return isMobile ? (
      <View marginBottom={sizes.size450} marginTop={sizes.size600}>
        <ListFeature {...data} />
      </View>
    ) : null;
  }

  return null;
};

const TextStory = ({ theme, props }) => {
  const { minHeight, heading, paragraphs, underlineColour } = props || {};
  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.sm });

  return (
    <WebsiteTheme name="TextStory" theme={theme}>
      <View paddingY={sizes.size500}>
        <SectionLayout>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={isMobile ? sizes.size400 : sizes.size800}
            height="100%"
            minHeight={isMobile ? 'auto' : `${minHeight}px`}
          >
            <SecondaryFont>
              <Title level="4" lineHeight="1.4" center underlineColour={underlineColour}>
                <HtmlText>{heading}</HtmlText>
              </Title>
            </SecondaryFont>
            <Flex maxWidth="1008px" direction="row" gap={sizes.size900}>
              <View flex="7">
                <SecondaryFont>
                  <Subtitle level="4" lineHeight="1.5" weight={200}>
                    {paragraphs.map((paragraph, idx) => (
                      <Item key={idx} {...paragraph} isMobile={isMobile} />
                    ))}
                  </Subtitle>
                </SecondaryFont>
              </View>
              {!isMobile && (
                <View flex="4">
                  {paragraphs
                    .filter((paragraph) => paragraph.type === 'listFeature')
                    .map((paragraph, idx) => {
                      return <ListFeature key={idx} {...paragraph.data} />;
                    })}
                </View>
              )}
            </Flex>
          </Flex>
        </SectionLayout>
      </View>
    </WebsiteTheme>
  );
};

TextStory.displayName = 'TextStory';

export default TextStory;
