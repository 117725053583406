import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import { ReactSVG } from 'react-svg';
import { View } from '@adobe/react-spectrum';
import { useFluidImageQuery } from '@send-data-hooks/useFluidImageQuery';
import styles from './StaticImage.module.less';
import Image from './Image';

const Container = ({ src, alt, colorScheme, objectPosition, ignoreTheme, ...props }) => {
  const isSvg = src.includes('.svg');
  const srcWithTheme = useMemo(() => (colorScheme ? `${colorScheme}/${src}` : src), [colorScheme, src]);

  const foundFluid = useFluidImageQuery(src, colorScheme);

  if (isSvg) {
    return (
      <View UNSAFE_className={styles.container} {...props}>
        <ReactSVG src={`images/${ignoreTheme ? src : srcWithTheme}`} />
      </View>
    );
  }

  return (
    <View {...props}>
      <Image fluidData={foundFluid} alt={alt} objectPosition={objectPosition} />
    </View>
  );
};

Container.propTypes = {
  src: PropTypes.string.isRequired,
  colorScheme: PropTypes.string,
  alt: PropTypes.string,
  objectPosition: PropTypes.string,
  ignoreTheme: PropTypes.bool,
};

Container.defaultProps = {
  colorScheme: undefined,
  alt: undefined,
  objectPosition: undefined,
  ignoreTheme: false,
};

export default withTheme(Container);
