import img0 from '@send-images/animations/hero_home_img_0.png';
import img1 from '@send-images/animations/hero_home_img_1.png';
import img2 from '@send-images/animations/hero_home_img_2.png';
import img3 from '@send-images/animations/hero_home_img_3.png';
import img4 from '@send-images/animations/hero_home_img_4.png';
import img5 from '@send-images/animations/hero_home_img_5.png';
import img6 from '@send-images/animations/hero_home_img_6.png';
import img7 from '@send-images/animations/hero_home_img_7.png';
import img8 from '@send-images/animations/hero_home_img_8.png';
import img9 from '@send-images/animations/hero_home_img_9.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 450,
  w: 580,
  h: 766,
  nm: 'Personal Hero',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 151, h: 86, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 151, h: 86, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 151, h: 86, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 151, h: 86, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 151, h: 86, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 151, h: 86, u: '', p: img5, e: 0 },
    { id: 'image_6', w: 2564, h: 2564, u: '', p: img6, e: 0 },
    { id: 'image_7', w: 2564, h: 2564, u: '', p: img7, e: 0 },
    { id: 'image_8', w: 2564, h: 2564, u: '', p: img8, e: 0 },
    { id: 'image_9', w: 618, h: 679, u: '', p: img9, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'GBP.pdf',
      cl: 'pdf',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 346, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 351, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 429, s: [100] },
            { t: 436, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [466, 327, 0], ix: 2 },
        a: { a: 0, k: [75.5, 43, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 346,
              s: [70, 70, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 351,
              s: [115, 115, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 353,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 429,
              s: [100, 100, 100],
            },
            { t: 436, s: [70, 70, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 328,
      op: 436,
      st: 57,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'CAD.pdf',
      cl: 'pdf',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 334, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 339, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 429, s: [100] },
            { t: 436, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [250, 255, 0], ix: 2 },
        a: { a: 0, k: [75.5, 43, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 334,
              s: [70, 70, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 339,
              s: [115, 115, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 341,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 429,
              s: [100, 100, 100],
            },
            { t: 436, s: [70, 70, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 328,
      op: 436,
      st: 57,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'NZD.pdf',
      cl: 'pdf',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 210, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 215, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 293, s: [100] },
            { t: 300, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [456, 493, 0], ix: 2 },
        a: { a: 0, k: [75.5, 43, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 210,
              s: [70, 70, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 215,
              s: [115, 115, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 217,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 293,
              s: [100, 100, 100],
            },
            { t: 300, s: [70, 70, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 196,
      op: 300,
      st: 57,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'AUD.pdf',
      cl: 'pdf',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 196, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 201, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 293, s: [100] },
            { t: 300, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [75.5, 43, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 196,
              s: [70, 70, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 201,
              s: [115, 115, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 203,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 293,
              s: [100, 100, 100],
            },
            { t: 300, s: [70, 70, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 196,
      op: 300,
      st: 57,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'EUR.pdf',
      cl: 'pdf',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 19, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 24, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 26, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 115, s: [100] },
            { t: 122, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [444, 248, 0], ix: 2 },
        a: { a: 0, k: [75.5, 43, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 19,
              s: [70, 70, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 24,
              s: [115, 115, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 26,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 115,
              s: [100, 100, 100],
            },
            { t: 122, s: [70, 70, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 58,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: 'USD.pdf',
      cl: 'pdf',
      refId: 'image_5',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 11, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 16, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 115, s: [100] },
            { t: 122, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [75.5, 43, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 11,
              s: [70, 70, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 16,
              s: [115, 115, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 115,
              s: [100, 100, 100],
            },
            { t: 122, s: [70, 70, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 58,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'EUR.pdf',
      cl: 'pdf',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 171, s: [100] },
            { t: 178, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [444, 248, 0], ix: 2 },
        a: { a: 0, k: [75.5, 43, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 171,
              s: [100, 100, 100],
            },
            { t: 178, s: [70, 70, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 57,
      op: 178,
      st: 56,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: 'USD.pdf',
      cl: 'pdf',
      refId: 'image_5',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 171, s: [100] },
            { t: 178, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [75.5, 43, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 171,
              s: [100, 100, 100],
            },
            { t: 178, s: [70, 70, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 57,
      op: 178,
      st: 56,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: '04USA.png',
      cl: 'png',
      refId: 'image_6',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 8, s: [100] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: -9, s: [-10] },
            { t: 56, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [282, 405, 0], ix: 2 },
        a: { a: 0, k: [1282, 1282, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
              t: 0,
              s: [18, 18, 100],
            },
            { t: 8, s: [21, 21, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 57,
      st: -297,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: '02Americas.png',
      cl: 'png',
      refId: 'image_7',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 322, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 330, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 442, s: [100] },
            { t: 450, s: [5] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 322, s: [0] },
            { t: 450, s: [-10] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 332,
              s: [289.414, 406.164, 0],
              to: [-0.318, 0, 0],
              ti: [0.4, 0, 0],
            },
            { t: 452, s: [271.465, 396.164, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [1282, 1282, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
              t: 322,
              s: [18, 18, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 330,
              s: [21, 21, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 442,
              s: [21, 21, 100],
            },
            { t: 450, s: [18, 18, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 322,
      op: 450,
      st: 76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: '03Australia.png',
      cl: 'png',
      refId: 'image_8',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 187, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 195, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 314, s: [100] },
            { t: 322, s: [5] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 187, s: [0] },
            { t: 322, s: [-10] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [294, 409, 0], ix: 2 },
        a: { a: 0, k: [1282, 1282, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.12] },
              t: 187,
              s: [18, 18, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 195,
              s: [21, 21, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 314,
              s: [21, 21, 100],
            },
            { t: 322, s: [18, 18, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 187,
      op: 322,
      st: -56,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: '04USA.png',
      cl: 'png',
      refId: 'image_6',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 179, s: [100] },
            { t: 187, s: [5] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 57, s: [0] },
            { t: 187, s: [10] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [282, 405, 0], ix: 2 },
        a: { a: 0, k: [1282, 1282, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 2.711] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 179,
              s: [21, 21, 100],
            },
            { t: 187, s: [18, 18, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 57,
      op: 187,
      st: -184,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 2,
      nm: 'Texture.pdf',
      cl: 'pdf',
      refId: 'image_9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: 136, s: [-20] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 158, s: [50] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: 294, s: [-20] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 316, s: [50] },
            { t: 449, s: [0] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 0, s: [336, 412, 0], to: [0, 0.129, 0], ti: [0, 0, 0] },
            { i: { x: 0.667, y: 0.683 }, o: { x: 0.333, y: 0 }, t: 136, s: [332, 376, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0.663 }, t: 147, s: [368, 430.001, 0], to: [0, 0, 0], ti: [0, -4, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 158, s: [376, 460, 0], to: [0, 0.129, 0], ti: [0, 0, 0] },
            { i: { x: 0.667, y: 0.683 }, o: { x: 0.333, y: 0 }, t: 294, s: [332, 376, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0.663 }, t: 305, s: [368, 430.001, 0], to: [0, 0, 0], ti: [0, -4, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 316, s: [376, 460, 0], to: [0, 0.129, 0], ti: [0, -4, 0] },
            { t: 449, s: [336, 412, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [309, 339.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [91, 91, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 136,
              s: [91, 91, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 147,
              s: [71, 71, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 158,
              s: [91, 91, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 294,
              s: [91, 91, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 305,
              s: [71, 71, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 316,
              s: [91, 91, 100],
            },
            { t: 449, s: [91, 91, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
