const config = {
  hotjar: {
    prod: {
      id: '2340465',
      version: '6',
    },
  },
  analytics: {
    prod: {
      uid: 'UA-130858412-1',
    },
    staging: {
      uid: 'UA-130858412-2',
    },
  },
  tag: {
    sendpayments: {
      prod: {
        uid: 'GTM-WWWQRZL',
      },
    },
  },
  taboola: {
    id: 1312318,
  },
};

export default config;
