import img0 from '@send-images/animations/feature_partner_1_img_0.png';
import img1 from '@send-images/animations/feature_partner_1_img_1.png';
import img2 from '@send-images/animations/feature_partner_1_img_2.png';
import img3 from '@send-images/animations/feature_partner_1_img_3.png';
import img4 from '@send-images/animations/feature_partner_1_img_4.png';
import img5 from '@send-images/animations/feature_partner_1_img_5.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 100,
  w: 650,
  h: 510,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 46, h: 56, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 55, h: 49, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 142, h: 142, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 127, h: 127, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 113, h: 112, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 108, h: 108, u: '', p: img5, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Zap1.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 36, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 38, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 89, s: [100] },
                { t: 92, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [81, 41.5, 0], ix: 2 },
            a: { a: 0, k: [23, 28, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 36,
          op: 94,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Zap2.pdf',
          cl: 'pdf',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 36, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 38, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 89, s: [100] },
                { t: 92, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [238, 179.5, 0], ix: 2 },
            a: { a: 0, k: [27.5, 24.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 36,
          op: 94,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: '1.pdf',
          cl: 'pdf',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 31, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 36, s: [11] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 94, s: [11] },
                { t: 99, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 31,
                  s: [88, 129.5, 0],
                  to: [1.333, 0.5, 0],
                  ti: [-1.333, -0.5, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 36, s: [96, 132.5, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 94,
                  s: [96, 132.5, 0],
                  to: [-1.333, -0.5, 0],
                  ti: [1.333, 0.5, 0],
                },
                { t: 99, s: [88, 129.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [71, 71, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 31,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 36,
                  s: [110, 110, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 94,
                  s: [110, 110, 100],
                },
                { t: 99, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: '2.pdf',
          cl: 'pdf',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 31, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 36, s: [-8] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 94, s: [-8] },
                { t: 99, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 31,
                  s: [147, 82.5, 0],
                  to: [0.333, 1, 0],
                  ti: [-0.333, -1, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 36, s: [149, 88.5, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 94,
                  s: [149, 88.5, 0],
                  to: [-0.333, -1, 0],
                  ti: [0.333, 1, 0],
                },
                { t: 99, s: [147, 82.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [63.5, 63.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 31,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 36,
                  s: [90, 90, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 94,
                  s: [90, 90, 100],
                },
                { t: 99, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: '3.pdf',
          cl: 'pdf',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 31, s: [12] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 36, s: [-12] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 94, s: [-12] },
                { t: 99, s: [12] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 31,
                  s: [159, 166.5, 0],
                  to: [1.333, -0.667, 0],
                  ti: [-1.333, 0.667, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 36, s: [167, 162.5, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 94,
                  s: [167, 162.5, 0],
                  to: [-1.333, 0.667, 0],
                  ti: [1.333, -0.667, 0],
                },
                { t: 99, s: [159, 166.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [56.5, 56, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 31,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 36,
                  s: [90, 90, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 94,
                  s: [90, 90, 100],
                },
                { t: 99, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: '4.pdf',
          cl: 'pdf',
          refId: 'image_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 31, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 36, s: [16] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 94, s: [16] },
                { t: 99, s: [0] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [212, 105.5, 0], ix: 2 },
            a: { a: 0, k: [54, 54, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 31,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 36,
                  s: [90, 90, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 94,
                  s: [90, 90, 100],
                },
                { t: 99, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Comp 2',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [331, 251, 0], ix: 2 },
        a: { a: 0, k: [154, 115.5, 0], ix: 1 },
        s: { a: 0, k: [222, 222, 100], ix: 6 },
      },
      ao: 0,
      w: 308,
      h: 231,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
