import img0 from '@send-images/animations/callout_business_3_img_0.png';
import img1 from '@send-images/animations/callout_business_3_img_1.png';
import img2 from '@send-images/animations/callout_business_3_img_2.png';
import img3 from '@send-images/animations/callout_business_3_img_3.png';
import img4 from '@send-images/animations/callout_business_3_img_4.png';
import img5 from '@send-images/animations/callout_business_3_img_5.png';
import img6 from '@send-images/animations/callout_business_3_img_6.png';
import img7 from '@send-images/animations/callout_business_3_img_7.png';
import img8 from '@send-images/animations/callout_business_3_img_8.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 111,
  w: 486,
  h: 394,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 49, h: 44, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 41, h: 39, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 53, h: 53, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 50, h: 50, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 183, h: 82, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 49, h: 50, u: '', p: img5, e: 0 },
    { id: 'image_6', w: 182, h: 82, u: '', p: img6, e: 0 },
    { id: 'image_7', w: 50, h: 49, u: '', p: img7, e: 0 },
    { id: 'image_8', w: 183, h: 82, u: '', p: img8, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'Zap1.pdf',
      cl: 'pdf',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [431, 185.5, 0], ix: 2 },
        a: { a: 0, k: [24.5, 22, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 78,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Zap2.pdf',
      cl: 'pdf',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 42, ix: 10 },
        p: { a: 0, k: [439, 262, 0], ix: 2 },
        a: { a: 0, k: [20.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 78,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'Notif.pdf',
      cl: 'pdf',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [263, 96, 0], ix: 2 },
        a: { a: 0, k: [26.5, 26.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 10,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 15,
              s: [115, 115, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 27,
              s: [100, 100, 100],
            },
            { t: 30, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 10,
      op: 30,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'AccountGreen.pdf',
      cl: 'pdf',
      parent: 5,
      refId: 'image_3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 107, s: [100] },
            { t: 111, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [43.75, 38, 0], ix: 2 },
        a: { a: 0, k: [25, 25, 0], ix: 1 },
        s: { a: 0, k: [87, 87, 100], ix: 6 },
      },
      ao: 0,
      ip: 11,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'Account.pdf',
      cl: 'pdf',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 6, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 10, s: [2] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 29, s: [2] },
            { t: 33, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [173, 127, 0], ix: 2 },
        a: { a: 0, k: [91.5, 41, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 6,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 10,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 29,
              s: [110, 110, 100],
            },
            { t: 33, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: 'Notif.pdf',
      cl: 'pdf',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [331, 160, 0], ix: 2 },
        a: { a: 0, k: [26.5, 26.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 41,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 46,
              s: [115, 115, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 49,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 58,
              s: [100, 100, 100],
            },
            { t: 61, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 41,
      op: 61,
      st: 31,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'RecipientGreen.pdf',
      cl: 'pdf',
      parent: 8,
      refId: 'image_5',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 106, s: [100] },
            { t: 111, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [43.5, 38, 0], ix: 2 },
        a: { a: 0, k: [24.5, 25, 0], ix: 1 },
        s: { a: 0, k: [90, 90, 100], ix: 6 },
      },
      ao: 0,
      ip: 41,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: 'Recipient.pdf',
      cl: 'pdf',
      refId: 'image_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 37, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 41, s: [-2] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 60, s: [-2] },
            { t: 64, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [243, 197, 0], ix: 2 },
        a: { a: 0, k: [91, 41, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 37,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 41,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 60,
              s: [110, 110, 100],
            },
            { t: 64, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: 'Notif.pdf',
      cl: 'pdf',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [401, 229, 0], ix: 2 },
        a: { a: 0, k: [26.5, 26.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 72,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 77,
              s: [115, 115, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 80,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 89,
              s: [100, 100, 100],
            },
            { t: 92, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 72,
      op: 92,
      st: 62,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: 'ExchangeGreen.pdf',
      cl: 'pdf',
      parent: 11,
      refId: 'image_7',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 107, s: [100] },
            { t: 111, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [44, 38.25, 0], ix: 2 },
        a: { a: 0, k: [25, 24.5, 0], ix: 1 },
        s: { a: 0, k: [91, 91, 100], ix: 6 },
      },
      ao: 0,
      ip: 72,
      op: 125,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: 'Exchange.pdf',
      cl: 'pdf',
      refId: 'image_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 68, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [2] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 91, s: [0] },
            { t: 95, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [313, 267, 0], ix: 2 },
        a: { a: 0, k: [91.5, 41, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 68,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 72,
              s: [110, 110, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 91,
              s: [110, 110, 100],
            },
            { t: 95, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
