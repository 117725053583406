import React from 'react';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { StaticImage } from '@send-components/StaticImage';
import { Title } from '@send-components/Title';
import { Subtitle } from '@send-components/Subtitle';
import { MobileSectionContainer } from '@send-components/MobileSectionContainer';
import { sizes, staticSizes } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { SecondaryButton } from '@sendpayments/react-shared/components/buttons/SecondaryButton';
import { SecondaryActionButton } from '@send-components';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { forwardParams } from '@sendpayments/js-utils/services/analytics/legacy';
import { Flex, View } from '@adobe/react-spectrum';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import { breakpoints } from '@send-base/design-system';
import { SectionLayout } from '../SectionLayout';
import styles from './CallToAction.module.less';

const CallToAction = ({ theme, metadata, props, onOpenForm }) => {
  const { minHeight, heading, subtitle, underlineColour, showGetInTouchLinks } = props || {};

  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.sm });

  const onPortalSignIn = () => window.open(metadata.portalSignIn);
  const onPortalSignUp = () => window.open(forwardParams(metadata.portalSignUp));
  const onGetInTouch = () => onOpenForm({ name: 'ContactUs' });

  return (
    <WebsiteTheme name="CallToAction" theme={theme}>
      {isMobile && (
        <MobileSectionContainer>
          <SectionLayout>
            <Flex direction="column" alignItems="center" gap={sizes.size400}>
              <View width={staticSizes.size3400}>
                <StaticImage src="fintech-mozo-reward.png" alt="Fintech Mozo Reward" />
              </View>
              <Flex direction="column" gap={sizes.size200} alignItems="center">
                <div className={styles.title}>
                  <Title level="1" underlineColour={underlineColour}>
                    <SecondaryFont>
                      <HtmlText>{heading}</HtmlText>
                    </SecondaryFont>
                  </Title>
                </div>
                <div className={styles.subtitle}>
                  <Subtitle level={3}>
                    <HtmlText>{subtitle}</HtmlText>
                  </Subtitle>
                </div>
              </Flex>
              {showGetInTouchLinks && (
                <View justifyContent="center" alignSelf="center">
                  <SecondaryActionButton onPress={onGetInTouch}>
                    Get in touch <ChevronRight size="M" />
                  </SecondaryActionButton>
                </View>
              )}
              {!showGetInTouchLinks && (
                <Flex direction="column" gap={sizes.size200} alignItems="center">
                  <SecondaryActionButton onPress={onPortalSignUp}>
                    Register now <ChevronRight size="M" />
                  </SecondaryActionButton>
                  <View alignSelf="center">
                    <SecondaryButton onPress={onPortalSignIn}>
                      <SecondaryFont>Already a member? Log in</SecondaryFont>
                    </SecondaryButton>
                  </View>
                </Flex>
              )}
            </Flex>
          </SectionLayout>
        </MobileSectionContainer>
      )}

      {!isMobile && (
        <SectionLayout>
          <Flex direction="column" alignItems="center" justifyContent="center" minHeight={`${minHeight}px`}>
            <Flex direction="column" gap={sizes.size500} alignItems="center">
              <View width={staticSizes.size4600}>
                <StaticImage src="fintech-mozo-reward.png" alt="Fintech Mozo Reward" />
              </View>
              <Flex alignItems="center" direction="column" gap={sizes.size350}>
                <div className={styles.subtitle}>
                  <Title level="1" underlineColour={underlineColour}>
                    <SecondaryFont>
                      <HtmlText>{heading}</HtmlText>
                    </SecondaryFont>
                  </Title>
                </div>
                <div className={styles.subtitle}>
                  <Subtitle level={3}>
                    <HtmlText>{subtitle}</HtmlText>
                  </Subtitle>
                </div>
              </Flex>
              {showGetInTouchLinks && (
                <View justifyContent="center" alignSelf="center">
                  <SecondaryActionButton onPress={onGetInTouch}>
                    Get in touch <ChevronRight size="M" />
                  </SecondaryActionButton>
                </View>
              )}
              {!showGetInTouchLinks && (
                <Flex direction="row" gap={sizes.size200} justifyContent="center">
                  <SecondaryActionButton onPress={onPortalSignUp}>
                    Register now <ChevronRight size="M" />
                  </SecondaryActionButton>
                  <View alignSelf="center">
                    <SecondaryButton onPress={onPortalSignIn}>
                      <SecondaryFont>Already a member? Log in</SecondaryFont>
                    </SecondaryButton>
                  </View>
                </Flex>
              )}
            </Flex>
          </Flex>
        </SectionLayout>
      )}
    </WebsiteTheme>
  );
};

CallToAction.displayName = 'CallToAction';

export default CallToAction;
