import { useRef, useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useIsVisible } from 'react-is-visible';

const step = 2; // each step is 2px

const useParallax = ({ speed, maxMovementPx }) => {
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [delta, setDelta] = useState(0);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (isVisible) {
        const isGrowing = Math.abs(currPos.y) > Math.abs(prevPos.y);

        const value = Math.abs(currPos.y) - Math.abs(prevPos.y);

        if (isGrowing && (!maxMovementPx || delta <= maxMovementPx)) {
          setDelta(delta + (value > 0 ? step : -step) * speed);
        } else if (!isGrowing && delta > 0) {
          setDelta(delta + (value > 0 ? step : -step) * speed);
        }
      }
    },
    [isVisible, delta],
  );

  const newDelta = parseInt(delta, 10);

  return [{ visibleElementRef: nodeRef, delta: newDelta > 0 ? newDelta : 0 }];
};

export default useParallax;
