import img0 from '@send-images/animations/feature_home_1_img_0.png';
import img1 from '@send-images/animations/feature_home_1_img_1.png';
import img2 from '@send-images/animations/feature_home_1_img_2.png';
import img3 from '@send-images/animations/feature_home_1_img_3.png';
import img4 from '@send-images/animations/feature_home_1_img_4.png';
import img5 from '@send-images/animations/feature_home_1_img_5.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 100,
  w: 650,
  h: 510,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 135, h: 135, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 135, h: 135, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 137, h: 137, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 140, h: 140, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 537, h: 508, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 348, h: 348, u: '', p: img5, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'img_0.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 49, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 52, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 79, s: [100] },
                { t: 87, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [121, 178.5, 0], ix: 2 },
            a: { a: 0, k: [67.5, 67.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 49,
                  s: [35, 35, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 52,
                  s: [51, 51, 100],
                },
                { t: 54, s: [35, 35, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 39,
          op: 87,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'img_1.png',
          cl: 'png',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 45, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 48, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 79, s: [100] },
                { t: 87, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [220.5, 130, 0], ix: 2 },
            a: { a: 0, k: [67.5, 67.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 45,
                  s: [35, 35, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 48,
                  s: [51, 51, 100],
                },
                { t: 50, s: [35, 35, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 39,
          op: 87,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'img_2.png',
          cl: 'png',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 42, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 45, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 79, s: [100] },
                { t: 87, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [156.5, 49.5, 0], ix: 2 },
            a: { a: 0, k: [68.5, 68.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 42,
                  s: [35, 35, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 45,
                  s: [51, 51, 100],
                },
                { t: 47, s: [35, 35, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 39,
          op: 87,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'img_3.png',
          cl: 'png',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 39, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 42, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 79, s: [100] },
                { t: 87, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [80.5, 104.25, 0], ix: 2 },
            a: { a: 0, k: [70, 70, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 39,
                  s: [35, 35, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 42,
                  s: [51, 51, 100],
                },
                { t: 44, s: [35, 35, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 39,
          op: 87,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'img_4.png',
          cl: 'png',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [150.5, 113.716, 0], ix: 2 },
            a: { a: 0, k: [268.5, 254, 0], ix: 1 },
            s: { a: 0, k: [35, 35, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'img_5.png',
          cl: 'png',
          refId: 'image_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [151.75, 117.5, 0], ix: 2 },
            a: { a: 0, k: [174, 174, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 31,
                  s: [20, 20, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 35,
                  s: [18, 18, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 37,
                  s: [26, 26, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 39,
                  s: [29, 29, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 41,
                  s: [24, 24, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 77,
                  s: [24, 24, 100],
                },
                { t: 86, s: [20, 20, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Comp 2',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [325, 262, 0], ix: 2 },
        a: { a: 0, k: [154, 115.5, 0], ix: 1 },
        s: { a: 0, k: [229, 229, 100], ix: 6 },
      },
      ao: 0,
      w: 308,
      h: 231,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
