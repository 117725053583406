import React, { useEffect, useState } from 'react';
import { useMyFormState } from 'my-react-form-state/react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { breakpoints } from '@send-base/design-system';
import { View } from '@adobe/react-spectrum';
import yup from 'my-react-form-state/yup';
import { CenteredExpandedSection } from '@send-components/CenteredExpandedSection';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { useConfirmation } from '@sendpayments/react-shared/data-hooks/useConfirmation';
import { Confirmation } from '@send-components/Confirmation';
import { Title } from '@send-components/Title';
import { Subtitle } from '@send-components/Subtitle';
import { Loading } from '@send-components/Loading';
import * as YUP from 'yup';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import styles from './Form.module.less';
import { Modal } from '../../Modal';
import { useForm } from '../Provider';
import Form from './Form';

const YUPSchema = YUP.object().shape({
  email: YUP.string().email().required(),
  firstName: YUP.string().required(),
  lastName: YUP.string().required(),
  position: YUP.string(),
});

const Container = ({ props, theme }) => {
  const { footerText, submitText, subtitle, heading, bg, underlineColour } = props;

  const [
    {
      data: { isVisible },
    },
    { setHidden },
  ] = useForm({ name: 'JoinUs' });

  const [formState, { updateField, submitForm, resetForm }] = useMyFormState({
    initialState: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      position: undefined,
    },
    formSchema: yup.formSchema(YUPSchema),
  });

  const [isConfirmationVisible, { setConfirmationVisible, setConfirmationHidden }] = useConfirmation();
  const [isLoading, setIsLoading] = useState(false);

  const { isMedia } = useBreakpoint(breakpoints);

  const isMediaSmall = isMedia({ to: breakpoints.sm });

  const [countries, setCountries] = useState([]);

  const onFieldChangeHandler = (field, value) => updateField({ field, value });

  const onSubmitHandler = async () => {
    setIsLoading(true);
    setConfirmationVisible();
    // const result = await submitForm();

    await new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });

    setIsLoading(false);

    // USE https://www.npmjs.com/package/node-mandrill ??
  };

  const onClearEverything = () => {
    setHidden();
    setConfirmationHidden();
    setIsLoading(false);
    resetForm({});
  };

  useEffect(() => {
    const doFetch = async () => {
      const response = await fetch('https://restcountries.eu/rest/v2/all');
      const countriesList = await response.json();
      setCountries(countriesList.map(({ name }) => name));
    };

    if (isVisible && countries.length === 0) {
      doFetch();
    }
  }, [isVisible]);

  const { isSubmittable, isSubmitted } = formState;

  return (
    isVisible && (
      <>
        <Modal
          name="JoinUs"
          theme={theme}
          bg={bg}
          onClose={onClearEverything}
          onSubmit={onSubmitHandler}
          footerText={(!isMediaSmall && footerText) || ''}
          submitText={submitText}
          isSubmitDisabled={!isSubmittable || isSubmitted}
          showFooter={!isConfirmationVisible}
          showSubmit={!isConfirmationVisible}
        >
          {isLoading && isConfirmationVisible && <Loading />}
          {!isLoading && isConfirmationVisible && (
            <Confirmation
              iconType="success"
              title="Got <u>it!</u>"
              text="<b>Thanks for your enquiry about joining the Send team</b>. <br/> We’ll be in touch if we think you’d be a good fit for one of our open positions."
              confirmText="Go back to About"
              onDismiss={onClearEverything}
              onConfirm={onClearEverything}
            />
          )}
          {!isConfirmationVisible && (
            <CenteredExpandedSection horizontalCentered className={styles.modalContainer}>
              <Title center level="3" underlineColour={underlineColour}>
                <SecondaryFont>
                  <HtmlText>{heading}</HtmlText>
                </SecondaryFont>
              </Title>
              <View height={sizes.size50} />
              <Subtitle level="2" center>
                <HtmlText>{subtitle}</HtmlText>
              </Subtitle>
              <View height={sizes.size50} />
              <Form formState={formState} countries={countries} onFieldChange={onFieldChangeHandler} onSubmit={submitForm} />
            </CenteredExpandedSection>
          )}
        </Modal>
      </>
    )
  );
};

export default Container;
