import React from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { StaticImage } from '@send-components/StaticImage';
import { Flex, View } from '@adobe/react-spectrum';
import { HorizontalGallery } from '@sendpayments/react-shared/components/HorizontalGallery';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { MobileSectionContainer } from '@send-components/MobileSectionContainer';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { Title } from '@send-components/Title';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { useParallax } from '@send-hooks/useParallax';
import { breakpoints } from '@send-base/design-system';
import styles from './LogoCarousel.module.less';

const LogoCarousel = ({ name, theme, props }) => {
  const { minHeight, heading, images: imagesList, underlineColour, parallaxSpeed } = props;

  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.sm });

  const [{ visibleElementRef, delta }] = useParallax({ speed: parallaxSpeed });

  return (
    <WebsiteTheme name={name} theme={theme}>
      <MobileSectionContainer>
        <Flex
          direction="column"
          justifyContent="center"
          gap={isMobile ? sizes.size1000 : sizes.size1600}
          minHeight={!isMobile ? `${minHeight}px` : 'auto'}
        >
          <View alignSelf="center">
            <div className={styles.title}>
              <Title level="3" lineHeight="1.4" center underlineColour={underlineColour}>
                <SecondaryFont>
                  <HtmlText>{heading}</HtmlText>
                </SecondaryFont>
              </Title>
            </div>
          </View>
          <div ref={visibleElementRef}>
            <Flex direction="column" justifyContent="center" gap={isMobile ? sizes.size450 : sizes.size700}>
              {imagesList.map((images, idx) => {
                const items = [...images, ...images, ...images];
                return (
                  <HorizontalGallery
                    alignCenter
                    scrollLeft={(idx % 2 === 1 ? -delta : delta) + 800}
                    key={idx}
                    length={items.length}
                    height="inherit"
                    onItemRender={({ index }) => {
                      const { src, width, mobileWidth, link } = items[index];
                      const jsx = <StaticImage src={src} width={(isMobile && mobileWidth) || width} />;
                      return <div className={styles.itemContainer}>{link ? <a href={link}>{jsx}</a> : jsx}</div>;
                    }}
                  />
                );
              })}
            </Flex>
          </div>
        </Flex>
      </MobileSectionContainer>
    </WebsiteTheme>
  );
};

export default LogoCarousel;
