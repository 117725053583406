import React from 'react';
import PropTypes from 'prop-types';
import cn from 'class-names';
import styles from './SectionLayout.module.less';

const SectionLayout = ({ children, className, removePadding, height }) => (
  <div className={cn(styles.container, className, !removePadding && styles.padding)} style={{ height }}>
    <div className={styles.item}>{children}</div>
  </div>
);

SectionLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  removePadding: PropTypes.bool,
  height: PropTypes.string,
};

SectionLayout.defaultProps = {
  className: undefined,
  removePadding: false,
  height: undefined,
};

export default SectionLayout;
