import React from 'react';
import { ThemeProvider, colorScheme, ApplyTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import PropTypes from 'prop-types';
import { localTheme, spectrumOverrides } from '../../theme';

const WebsiteTheme = ({ children, name, theme }) => (
  <ThemeProvider
    name={`Website-${name}`}
    colorScheme={colorScheme[theme]}
    localTheme={localTheme}
    spectrumOverrides={spectrumOverrides}
  >
    {<ApplyTheme displayName={name}>{children}</ApplyTheme>}
  </ThemeProvider>
);

WebsiteTheme.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  applySelf: PropTypes.bool,
};

WebsiteTheme.defaultProps = {
  applySelf: false,
};

export default WebsiteTheme;
