import lightLocalTheme from './light/custom-local-theme.module.css';
import darkLocalTheme from './dark/custom-local-theme.module.css';
import globalsTheme from './globals/custom-local-theme.module.css';

// LIGHT
import overridesSecondaryActionButton from './light/components/SecondaryActionButton.module.less';
import overridesPrimaryButton from './light/components/PrimaryButton.module.less';
import overridesNavLink from './light/components/NavLink.module.css';
import overridesHeader from './light/components/Header.module.css';
import overridesSecondaryButton from './light/components/SecondaryButton.module.css';
import overridesLogInNavigationMobile from './light/components/LogInNavigationMobile.module.css';
import overridesActionIcon from './light/components/ActionIcon.module.css';
import overridesPrimaryDropdown from './light/components/PrimaryDropdown.module.less';
import overridesPrimaryDropdownMenuItem from './light/components/PrimaryDropdownMenuItem.module.less';
import overridesPrimaryActionButton from './light/components/PrimaryActionButton.module.less';
import overridesLightCard from './light/components/Card.module.css';
import overridesLightFeature from './light/layouts/Feature.module.css';

// DARK
import overridesFooter from './dark/components/Footer.module.css';
import overridesDarkActionIcon from './dark/components/ActionIcon.module.css';
import overridesDarkActionIconVariation from './dark/components/ActionIconDarkVariation.module.less';
import overridesDarkSecondaryActionButton from './dark/components/SecondaryActionButton.module.less';
import overridesDarkPrimaryDropdown from './dark/components/PrimaryDropdown.module.less';
import overridesPrimaryDropdownDarkVariation from './dark/components/PrimaryDropdownDarkVariation.module.less';
import overridesDarkPrimaryButton from './dark/components/PrimaryButton.module.less';
import overridesDarkNavLink from './dark/components/NavLink.module.css';
import overridesDarkSecondaryHyperlink from './dark/components/SecondaryHyperlink.module.css';
import overridesDarkSecondaryButton from './dark/components/SecondaryButton.module.css';
import overridesDarkPrimaryActionButton from './dark/components/PrimaryActionButton.module.less';
import overridesDarkPickerDropdown from './dark/components/PickerDropdown.module.less';
import overridesDarkFeature from './dark/layouts/Feature.module.css';
import overridesDarkCard from './dark/components/Card.module.css';
import overridesDarkCardContainer from './dark/components/CardContainer.module.less';
import overridesDarkPrimaryHomepageButton from './dark/components/PrimaryHomepageButton.module.less';
import overridesDarkSecondaryHomepageButton from './dark/components/SecondaryHomepageButton.module.less';

const localTheme = {
  globals: globalsTheme,
  light: lightLocalTheme,
  dark: darkLocalTheme,
};

const spectrumOverrides = {
  light: {
    ...overridesPrimaryButton,
    ...overridesSecondaryActionButton,
    ...overridesNavLink,
    ...overridesHeader,
    ...overridesSecondaryButton,
    ...overridesLogInNavigationMobile,
    ...overridesActionIcon,
    ...overridesPrimaryDropdown,
    ...overridesPrimaryDropdownMenuItem,
    ...overridesPrimaryActionButton,
    ...overridesLightCard,
    ...overridesLightFeature,
  },
  dark: {
    ...overridesFooter,
    ...overridesDarkNavLink,
    ...overridesDarkSecondaryHyperlink,
    ...overridesDarkSecondaryButton,
    ...overridesDarkPrimaryActionButton,
    ...overridesDarkPrimaryButton,
    ...overridesDarkPickerDropdown,
    ...overridesDarkSecondaryActionButton,
    ...overridesDarkFeature,
    ...overridesDarkPrimaryDropdown,
    ...overridesPrimaryDropdownDarkVariation,
    ...overridesDarkActionIcon,
    ...overridesDarkActionIconVariation,
    ...overridesDarkCard,
    ...overridesDarkCardContainer,
    ...overridesDarkPrimaryHomepageButton,
    ...overridesDarkSecondaryHomepageButton,
  },
};

export { localTheme, spectrumOverrides };
