import React from 'react';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { View } from '@adobe/react-spectrum';
import './BlankMain.modules.less';

const BlankMain = ({ name, children, theme }) => {
  return (
    <WebsiteTheme name={name} theme={theme}>
      <View>{children}</View>
    </WebsiteTheme>
  );
};

export default BlankMain;
