import React from 'react';
import { sizes, staticSizes } from '@sendpayments/react-shared/design-system';
import { Flex, View } from '@adobe/react-spectrum';
import { LayeredAnimation } from '@sendpayments/react-shared/components/LayeredAnimation';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { SecondaryActionButton } from '@send-components';
import { SecondaryButton } from '@sendpayments/react-shared/components/buttons/SecondaryButton';
import { MediaBreakpoint, useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { PickerDropdown } from '@sendpayments/react-shared/components/PickerDropdown';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { Subtitle } from '@send-components/Subtitle';
import { StaticImage } from '@send-components/StaticImage';
import { Title } from '@send-components/Title';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { forwardParams } from '@sendpayments/js-utils/services/analytics/legacy';
import { navigate } from 'gatsby';
import * as animations from '@send-animations';
import { breakpoints } from '@send-base/design-system';
import styles from './Hero.module.less';
import { SectionLayout } from '../SectionLayout';

const Hero = ({ name, theme, metadata, props, onOpenForm }) => {
  const {
    minHeight,
    heading,
    subtitle,
    showPortalLinks,
    showGetInTouchLinks,
    animation,
    animationMobile,
    dropdownNav,
    textSectionPosition,
    underlineColour,
    image,
  } = props || {};

  const { isMedia } = useBreakpoint(breakpoints);
  const isMediaMobile = isMedia({ to: breakpoints.sm });

  const onGetInTouch = () => onOpenForm({ name: 'ContactUs' });
  const onPortalSignIn = () => window.open(metadata.portalSignIn);
  const onPortalSignUp = () => window.open(forwardParams(metadata.portalSignUp));
  const onDropdownNavChange = (key) => {
    const selectedItem = dropdownNav.items.find((item) => item.value === key);
    if (selectedItem.url === 'openPortalSignIn') {
      window.open(metadata.portalSignIn);
    } else {
      navigate(selectedItem.url);
    }
  };

  let contentViewLg;
  let contentViewSm;
  let contentViewXs;

  if (animation) {
    const heroAnimation = animation.hero;
    const dataLayers = [{ data: animations[heroAnimation.layer1.name], position: animations[heroAnimation.layer1.position] }];
    if (heroAnimation.layer2) {
      dataLayers.push({ data: animations[heroAnimation.layer2.name], position: animations[heroAnimation.layer2.position] });
    }
    contentViewLg = (
      <LayeredAnimation height="771px" name="hero" width="580px" dataLayers={dataLayers} {...heroAnimation.configuration} />
    );
    const heroAnimationMobile = animationMobile?.hero;
    if (heroAnimationMobile) {
      const mobileDataLayers = [
        { data: animations[heroAnimationMobile.layer1.name], position: animations[heroAnimationMobile.layer1.position] },
      ];
      contentViewSm = (
        <LayeredAnimation name="hero" height="290px" dataLayers={mobileDataLayers} {...heroAnimation.configuration} />
      );
      contentViewXs = (
        <LayeredAnimation name="hero" height="261px" dataLayers={mobileDataLayers} {...heroAnimation.configuration} />
      );
    }
  } else if (image) {
    const { src } = image;
    contentViewLg = <StaticImage src={src} />;
  }
  const isLeftContent = textSectionPosition !== 'right';

  return (
    <WebsiteTheme name={name} theme={theme}>
      {isMediaMobile && (
        <div className={styles.mobileContainer}>
          <Flex>
            <Flex flex="1" justifyContent="space-between">
              <View alignSelf="center">
                <Flex direction="column" gap={sizes.size350}>
                  <View>
                    <SectionLayout>
                      <Title level="1" underlineColour={underlineColour} center={isMediaMobile}>
                        <SecondaryFont>
                          <HtmlText>{heading}</HtmlText>
                        </SecondaryFont>
                      </Title>
                    </SectionLayout>
                  </View>

                  {dropdownNav && (
                    <View alignSelf="center">
                      <div className={styles.dropdownNav}>
                        <PickerDropdown
                          width={(isMediaMobile && sizes.size3000) || sizes.size3600}
                          placeholder={dropdownNav.placeHolder}
                          items={dropdownNav.items}
                          onSelectionChange={onDropdownNavChange}
                        />
                      </div>
                    </View>
                  )}

                  {showPortalLinks && (
                    <View>
                      <SectionLayout>
                        <Flex gap={sizes.size130} justifyContent="center">
                          <View justifyContent="center" alignSelf="center">
                            <PrimaryActionButton onPress={onPortalSignUp} UNSAFE_className={styles.primaryActionButton}>
                              Register now <ChevronRight size="M" />
                            </PrimaryActionButton>
                          </View>
                          <View alignSelf="center">
                            <SecondaryButton onPress={onPortalSignIn}>
                              <SecondaryFont>Log in</SecondaryFont>
                            </SecondaryButton>
                          </View>
                        </Flex>
                      </SectionLayout>
                    </View>
                  )}

                  {showGetInTouchLinks && (
                    <View justifyContent="center" alignSelf="center">
                      <SecondaryActionButton onPress={onGetInTouch}>
                        Get in touch <ChevronRight size="M" />
                      </SecondaryActionButton>
                    </View>
                  )}

                  <MediaBreakpoint to={breakpoints.xs}>
                    <View alignSelf="center" width="100%">
                      {contentViewXs}
                    </View>
                  </MediaBreakpoint>

                  <MediaBreakpoint from={breakpoints.xs} to={breakpoints.sm}>
                    <View alignSelf="center" width="100%">
                      {contentViewSm}
                    </View>
                  </MediaBreakpoint>

                  <SectionLayout>
                    <Subtitle level={3} center={isMediaMobile}>
                      <HtmlText>{subtitle}</HtmlText>
                    </Subtitle>
                  </SectionLayout>

                  <View
                    alignSelf="center"
                    width={staticSizes.size2400}
                    UNSAFE_style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                  >
                    <StaticImage src="awards-mobile.svg" alt="Fintech Awards" width="216px" />
                  </View>
                </Flex>
              </View>
            </Flex>
          </Flex>
        </div>
      )}

      {!isMediaMobile && (
        <div
          style={{
            minHeight: `${minHeight}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SectionLayout>
            <Flex direction={isLeftContent ? 'row' : 'row-reverse'} gap={sizes.size350}>
              <Flex flex="1" justifyContent="space-between">
                <View alignSelf="center">
                  <Flex direction="column" gap={sizes.size400}>
                    <Flex direction="column" alignItems="start">
                      <StaticImage src="trustpilot.svg" alt="Trust Pilot" width="200px" />
                    </Flex>
                    <Flex direction="column">
                      <View width={staticSizes.size2400}>
                        <StaticImage src="fintech-awards.svg" alt="Fintech Awards" width="486px" />
                      </View>
                    </Flex>

                    <View>
                      <Title level="1" underlineColour={underlineColour}>
                        <SecondaryFont>
                          <HtmlText>{heading}</HtmlText>
                        </SecondaryFont>
                      </Title>
                    </View>

                    <Subtitle level={3}>
                      <HtmlText>{subtitle}</HtmlText>
                    </Subtitle>
                    {dropdownNav && (
                      <div className={styles.dropdownNavContainer}>
                        <PickerDropdown
                          width={(isMediaMobile && sizes.size3400) || sizes.size3600}
                          placeholder={dropdownNav.placeHolder}
                          items={dropdownNav.items}
                          onSelectionChange={onDropdownNavChange}
                          UNSAFE_className={styles.dropdownNav}
                        />
                      </div>
                    )}
                    <View>
                      {showGetInTouchLinks && (
                        <Flex gap={sizes.size130}>
                          <View justifyContent="center" alignSelf="center">
                            <SecondaryActionButton onPress={onGetInTouch}>
                              Get in touch <ChevronRight size="M" />
                            </SecondaryActionButton>
                          </View>
                        </Flex>
                      )}
                      {showPortalLinks && (
                        <Flex gap={sizes.size130}>
                          <View justifyContent="center" alignSelf="center">
                            <PrimaryActionButton onPress={onPortalSignUp} UNSAFE_className={styles.primaryActionButton}>
                              Register now <ChevronRight size="M" />
                            </PrimaryActionButton>
                          </View>
                          <View alignSelf="center">
                            <SecondaryButton onPress={onPortalSignIn}>
                              <SecondaryFont>Already a member? Log in</SecondaryFont>
                            </SecondaryButton>
                          </View>
                        </Flex>
                      )}
                    </View>
                  </Flex>
                </View>
              </Flex>
              <View flex="1">{contentViewLg}</View>
            </Flex>
          </SectionLayout>
        </div>
      )}
    </WebsiteTheme>
  );
};

export default Hero;
