import img0 from '@send-images/animations/hero_about_img_0.png';
import img1 from '@send-images/animations/hero_about_img_1.png';
import img2 from '@send-images/animations/hero_about_img_2.png';
import img3 from '@send-images/animations/hero_about_img_3.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 272,
  w: 580,
  h: 766,
  nm: 'About Hero',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 1064, h: 1064, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 1064, h: 1064, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 1064, h: 1064, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 1425, h: 1808, u: '', p: img3, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'Mask Group 19930.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 182, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 190, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 264, s: [100] },
            { t: 272, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [234.25, 409.5, 0], ix: 2 },
        a: { a: 0, k: [532, 532, 0], ix: 1 },
        s: { a: 0, k: [42, 42, 100], ix: 6 },
      },
      ao: 0,
      ip: 182,
      op: 274,
      st: 180,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Mask Group 19929.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 91, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 99, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 174, s: [100] },
            { t: 182, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [234.5, 409.75, 0], ix: 2 },
        a: { a: 0, k: [532, 532, 0], ix: 1 },
        s: { a: 0, k: [42, 42, 100], ix: 6 },
      },
      ao: 0,
      ip: 91,
      op: 182,
      st: 91,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'Mask Group 19931.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 8, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 82, s: [100] },
            { t: 90, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [234, 409.5, 0], ix: 2 },
        a: { a: 0, k: [532, 532, 0], ix: 1 },
        s: { a: 0, k: [42, 42, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 91,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'BG.png',
      cl: 'png',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [712.5, 904, 0], ix: 1 },
        s: { a: 0, k: [42, 42, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
