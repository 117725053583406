import img0 from '@send-images/animations/feature_personal_2_img_0.png';
import img1 from '@send-images/animations/feature_personal_2_img_1.png';
import img2 from '@send-images/animations/feature_personal_2_img_2.png';
import img3 from '@send-images/animations/feature_personal_2_img_3.png';
import img4 from '@send-images/animations/feature_personal_2_img_4.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 100,
  w: 650,
  h: 510,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 161, h: 172, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 160, h: 133, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 376, h: 375, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 376, h: 375, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 884, h: 534, u: '', p: img4, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Group3-01.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 46, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 48, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 65, s: [100] },
                { t: 70, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 46, s: [-89.629] },
                { t: 70, s: [-115.629] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [425.061, 159.006, 0], ix: 2 },
            a: { a: 0, k: [80.5, 86, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 46,
                  s: [20.404, 20.404, 100],
                },
                { t: 70, s: [32.404, 32.404, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 46,
          op: 89,
          st: -1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Group4-01.png',
          cl: 'png',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 46, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 48, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 65, s: [100] },
                { t: 70, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 46, s: [34.371] },
                { t: 70, s: [8.371] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [387.319, 127.278, 0], ix: 2 },
            a: { a: 0, k: [80, 66.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 46,
                  s: [17.5, 17.5, 100],
                },
                { t: 70, s: [29.5, 29.5, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 46,
          op: 89,
          st: -1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'Group4-01.png',
          cl: 'png',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 46, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 48, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 65, s: [100] },
                { t: 70, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 46, s: [4.371] },
                { t: 70, s: [-21.629] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [184.072, 270.94, 0], ix: 2 },
            a: { a: 0, k: [80, 66.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 46,
                  s: [17.5, 17.5, 100],
                },
                { t: 70, s: [29.5, 29.5, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 46,
          op: 89,
          st: -1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'Group3-01.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 46, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 48, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 65, s: [100] },
                { t: 70, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 46, s: [19.371] },
                { t: 70, s: [-6.629] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [169.428, 222.89, 0], ix: 2 },
            a: { a: 0, k: [80.5, 86, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 46,
                  s: [20.404, 20.404, 100],
                },
                { t: 70, s: [32.404, 32.404, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 46,
          op: 89,
          st: -1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'Group2-02.png',
          cl: 'png',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 8, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 28, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 41, s: [100] },
                { t: 47, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                { t: 41, s: [42] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.333, y: 0 },
                  t: 8,
                  s: [54, 346, 0],
                  to: [12.667, -7.833, 0],
                  ti: [-1.193, 6.23, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 41,
                  s: [130, 299, 0],
                  to: [0.904, -4.724, 0],
                  ti: [0.159, 1.221, 0],
                },
                { t: 63, s: [320, 540, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [188, 187.5, 0], ix: 1 },
            s: { a: 0, k: [18.933, 18.933, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'Group2-01.png',
          cl: 'png',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 24, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 41, s: [100] },
                { t: 47, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                { t: 41, s: [-37] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [64, 63, 0],
                  to: [10.167, 8.5, 0],
                  ti: [0.159, 1.221, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 41,
                  s: [125, 114, 0],
                  to: [-0.174, -1.332, 0],
                  ti: [56.833, -27, 0],
                },
                { t: 63, s: [-216, 276, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [188, 187.5, 0], ix: 1 },
            s: { a: 0, k: [18.933, 18.933, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: 'Group1-01.png',
          cl: 'png',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [2] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 41, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 43, s: [5] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 45, s: [-6.5] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 47, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 49, s: [5] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 51, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 53, s: [5] },
                { t: 87, s: [2] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 41, s: [293, 197, 0], to: [-1.667, 0, 0], ti: [0, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 42, s: [283, 197, 0], to: [0, 0, 0], ti: [-3.333, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 43, s: [293, 197, 0], to: [3.333, 0, 0], ti: [0, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 44, s: [303, 197, 0], to: [0, 0, 0], ti: [3.333, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 45, s: [293, 197, 0], to: [-3.333, 0, 0], ti: [0, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 46, s: [283, 197, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 47, s: [293, 197, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 48, s: [283, 197, 0], to: [0, 0, 0], ti: [-1.667, 0, 0] },
                { i: { x: 0.667, y: 0.667 }, o: { x: 0.333, y: 0.333 }, t: 49, s: [293, 197, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 50, s: [293, 197, 0], to: [-1.667, 0, 0], ti: [0, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 51, s: [283, 197, 0], to: [0, 0, 0], ti: [-1.667, 0, 0] },
                { i: { x: 0.667, y: 0.667 }, o: { x: 0.333, y: 0.333 }, t: 52, s: [293, 197, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 53, s: [293, 197, 0], to: [-1.667, 0, 0], ti: [0, 0, 0] },
                { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 54, s: [283, 197, 0], to: [0, 0, 0], ti: [-1.667, 0, 0] },
                { t: 55, s: [293, 197, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [442, 267, 0], ix: 1 },
            s: { a: 0, k: [28.09, 28.09, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Comp 1.1',
      cl: '1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [357, 247, 0], ix: 2 },
        a: { a: 0, k: [243, 197, 0], ix: 1 },
        s: { a: 0, k: [141, 141, 100], ix: 6 },
      },
      ao: 0,
      w: 486,
      h: 394,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
