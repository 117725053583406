import img0 from '@send-images/animations/feature_business_2_img_0.png';
import img1 from '@send-images/animations/feature_business_2_img_1.png';
import img2 from '@send-images/animations/feature_business_2_img_2.png';
import img3 from '@send-images/animations/feature_business_2_img_3.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 100,
  w: 650,
  h: 510,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 39, h: 55, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 60, h: 49, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 88, h: 88, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 236, h: 160, u: '', p: img3, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Zap1.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 33, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 35, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 93, s: [100] },
                { t: 95, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [192, 42.5, 0], ix: 2 },
            a: { a: 0, k: [19.5, 27.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 30,
          op: 95,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Zap2.pdf',
          cl: 'pdf',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 33, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 35, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 93, s: [100] },
                { t: 95, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [250, 124.5, 0], ix: 2 },
            a: { a: 0, k: [30, 24.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 30,
          op: 95,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'Flag.pdf',
          cl: 'pdf',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 31,
                  s: [231.5, 78.75, 0],
                  to: [-2.167, 2.333, 0],
                  ti: [-3.25, 3.375, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 36, s: [251, 58.5, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 94,
                  s: [251, 58.5, 0],
                  to: [-0.465, 0.501, 0],
                  ti: [1.701, -1.832, 0],
                },
                { t: 100, s: [231.5, 78.75, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [44, 44, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'Graph.pdf',
          cl: 'pdf',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [154, 115.5, 0], ix: 2 },
            a: { a: 0, k: [118, 80, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Comp 2',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [303, 279, 0], ix: 2 },
        a: { a: 0, k: [154, 115.5, 0], ix: 1 },
        s: { a: 0, k: [229, 229, 100], ix: 6 },
      },
      ao: 0,
      w: 308,
      h: 231,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
