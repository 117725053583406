import React from 'react';
import { View, Flex } from '@adobe/react-spectrum';
import { sizes } from '@sendpayments/react-shared/design-system';
import { PrimaryButton } from '@sendpayments/react-shared/components/buttons/PrimaryButton';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { Title } from '@send-components/Title';
import { Subtitle } from '@send-components/Subtitle';
import CheckmarkCircleOutline from '@spectrum-icons/workflow/CheckmarkCircleOutline';
import styles from './Confirmation.module.less';

const icons = {
  success: CheckmarkCircleOutline,
};

const Confirmation = ({ iconType, title, text, confirmText, onConfirm }) => {
  const Icon = icons[iconType];
  return (
    <div className={styles.container}>
      <Flex direction="column" gap={sizes.size250}>
        <Flex direction="column" gap={sizes.size100} alignItems="center">
          <Icon size="XL" />
          <Title center level="3" lineHeight="1.4" underlineColour="#4AFFE2">
            <SecondaryFont>
              <HtmlText>{title}</HtmlText>
            </SecondaryFont>
          </Title>
        </Flex>
        <Subtitle center level="4">
          <HtmlText>{text}</HtmlText>
        </Subtitle>
        <View alignSelf="center" marginTop="10px">
          <PrimaryButton onPress={onConfirm}>{confirmText}</PrimaryButton>
        </View>
      </Flex>
    </div>
  );
};

export default Confirmation;
