import img0 from '@send-images/animations/hero_personal_back_img_0.png';
import img1 from '@send-images/animations/hero_personal_back_img_1.png';
import img2 from '@send-images/animations/hero_personal_back_img_2.png';
import img3 from '@send-images/animations/hero_personal_back_img_3.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 447,
  w: 580,
  h: 766,
  nm: 'Personal Hero 2',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 3876, h: 5137, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 4981, h: 6226, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 3226, h: 5037, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 1463, h: 1167, u: '', p: img3, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'OldGirlBW.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 289, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 296, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 437, s: [100] },
                { t: 446, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 257, s: [-6] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 296, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 437, s: [2] },
                { t: 475, s: [14] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [290, 765, 0], ix: 2 },
            a: { a: 0, k: [1953.533, 4941.783, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 257,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 296,
                  s: [15, 15, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 437,
                  s: [15, 15, 100],
                },
                { t: 475, s: [0, 0, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 80,
          op: 462,
          st: 80,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'ManBW.png',
          cl: 'png',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 148, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 285, s: [100] },
                { t: 293, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 105, s: [16] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 285, s: [-0.6] },
                { t: 326, s: [-9] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [294, 767, 0], ix: 2 },
            a: { a: 0, k: [2493.205, 6212.999, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 105,
                  s: [-3, -3, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 155,
                  s: [12, 12, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 285,
                  s: [12, 12, 100],
                },
                { t: 326, s: [-3, -3, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'YoungGirlBW.png',
          cl: 'png',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 11, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 138, s: [100] },
                { t: 145, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: -16, s: [-15] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 18, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 138, s: [2] },
                { t: 157, s: [14] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [300, 773, 0], ix: 2 },
            a: { a: 0, k: [1613, 5002.309, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: -25,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 18,
                  s: [15, 15, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 138,
                  s: [15, 15, 100],
                },
                { t: 162, s: [0, 0, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 382,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'HeroTexture-01.png',
          cl: 'png',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [50] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 13, s: [100] },
                { t: 449, s: [50] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 12, s: [65] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 140, s: [60] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 149, s: [-107] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 284, s: [-116] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 298, s: [-295] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 434, s: [-309] },
                { t: 449, s: [65] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 291,
                  s: [286, 448, 0],
                  to: [1.667, 0, 0],
                  ti: [1.5, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 293,
                  s: [296, 448, 0],
                  to: [-1.5, 0, 0],
                  ti: [3.167, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 298,
                  s: [277, 448, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 434, s: [277, 448, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [731.5, 583.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 12,
                  s: [35.024, 35.024, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 434,
                  s: [36.024, 36.024, 100],
                },
                { t: 448, s: [35.024, 35.024, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 451,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Personal Hero',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [290, 383, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [-0.716, -153.928],
                [6.001, 127],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0.5, 107.5],
                [-4.214, -89.187],
                [0, 0],
              ],
              v: [
                [239, -569],
                [-432, 280],
                [31.5, 674.5],
                [573.999, 673],
                [1112, 275.999],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      w: 580,
      h: 766,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
