import React from 'react';
import { breakpoints } from '@send-base/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { StaticImage } from '@send-components/StaticImage';
import { HorizontalGallery } from '@sendpayments/react-shared/components/HorizontalGallery';
import { useParallax } from '@send-hooks/useParallax';

const ImagesLoop = ({ props }) => {
  const { images, imagesHeight, imagesMobileHeight, parallaxSpeed } = props;

  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.sm });

  const calculateHeight = () => {
    if (isMobile) {
      return imagesMobileHeight;
    }

    return imagesHeight;
  };

  const height = calculateHeight();

  const [{ visibleElementRef, delta }] = useParallax({ speed: parallaxSpeed });

  const onItemRenderHandler = ({ index }) => {
    const { src, width, mobileWidth } = images[index];
    return <StaticImage src={src} height={height} width={(isMobile && mobileWidth) || width} />;
  };

  return (
    <div ref={visibleElementRef}>
      <HorizontalGallery
        scrollLeft={(!isMobile && delta) || 0}
        height={height}
        length={images.length}
        onItemRender={onItemRenderHandler}
      />
    </div>
  );
};

export default ImagesLoop;
