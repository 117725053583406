import React from 'react';
import { StaticImage } from '@send-components/StaticImage';
import { WebsiteTheme } from '@send-components/WebsiteTheme';

const FullWidthImage = ({ name, theme, props }) => {
  const { image } = props;

  return (
    <WebsiteTheme name={name} theme={theme}>
      <StaticImage
        src={image.src}
        alt={image.alt}
        width="100%"
        height={image.height}
        maxHeight={image.maxHeight}
        objectPosition={image.objectPosition}
      />
    </WebsiteTheme>
  );
};

export default FullWidthImage;
