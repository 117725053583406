import React from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { ActionIcon } from '@sendpayments/react-shared/components/ActionIcon';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import cn from 'class-names';
import { Link, Flex, View, Divider } from '@adobe/react-spectrum';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { ApplyTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import { SecondaryButton } from '@sendpayments/react-shared/components/buttons/SecondaryButton';
import { SecondaryHyperlink } from '@sendpayments/react-shared/components/SecondaryHyperlink';
import { sortBy } from 'lodash';
import { Link as GatsbyLink, navigate } from 'gatsby';
import { breakpoints } from '@send-base/design-system';
import { BaseLayout } from '../../layouts/BaseLayout';
import { SecondaryFont } from '../SecondaryFont';
import { StaticImage } from '../StaticImage';
import styles from './Footer.module.less';

const Footer = ({ theme, navigation, metadata, bgColour }) => {
  const sortedNavigation = sortBy(navigation, ['index']);

  const { isMedia } = useBreakpoint(breakpoints);
  const isMediaMobile = isMedia({ to: breakpoints.sm });
  const onSupport = () => window.open(metadata.sendPaymentsHelp);

  return (
    <WebsiteTheme name="Footer" theme={theme}>
      <BaseLayout bgColour={bgColour}>
        <Flex direction="column" minHeight={320}>
          <Divider />
          <Flex
            justifyContent={isMediaMobile ? 'center' : 'space-between'}
            marginTop={sizes.size160}
            marginBottom={isMediaMobile && sizes.size900}
          >
            <Flex direction="row" gap={sizes.size200} wrap justifyContent={isMediaMobile ? 'center' : 'start'}>
              {sortedNavigation.map(({ name, url }) => (
                <View key={name} alignSelf="center" gap={isMediaMobile && sizes.size100}>
                  <SecondaryButton size="S">
                    <ApplyTheme displayName="FooterNav">
                      <SecondaryFont>
                        <GatsbyLink to={url}>{name}</GatsbyLink>
                      </SecondaryFont>
                    </ApplyTheme>
                  </SecondaryButton>
                </View>
              ))}
              <View alignSelf="center">
                <SecondaryButton size="S">
                  <ApplyTheme displayName="FooterNav">
                    <SecondaryFont>
                      <Link onPress={onSupport}>
                        <a>Support</a>
                      </Link>
                    </SecondaryFont>
                  </ApplyTheme>
                </SecondaryButton>
              </View>
            </Flex>

            {!isMediaMobile && (
              <Flex flex="1" justifyContent="end" gap={sizes.size400} wrap direction="row">
                <View alignSelf="center">
                  <a href="https://www.facebook.com/sendpaymentscom" target="_blank" rel="noreferrer">
                    <StaticImage src="facebook.svg" alt="Facebook" width={sizes.size300} />
                  </a>
                </View>
                <View alignSelf="center">
                  <a href="https://www.instagram.com/sendpayments" target="_blank" rel="noreferrer">
                    <StaticImage src="instagram.svg" alt="Instagram" width={sizes.size300} />
                  </a>
                </View>
                <View alignSelf="center">
                  <a href="https://www.linkedin.com/company/sendpayments" target="_blank" rel="noreferrer">
                    <StaticImage src="linkedin.svg" alt="Linkedin" width={sizes.size300} />
                  </a>
                </View>
                <View alignSelf="center">
                  <a href="https://www.twitter.com/sendpayments" target="_blank" rel="noreferrer">
                    <StaticImage src="twitter.svg" alt="Twitter" width={sizes.size300} />
                  </a>
                </View>
              </Flex>
            )}
          </Flex>

          <Flex flexGrow="1" />

          <Flex justifyContent={isMediaMobile ? 'center' : undefined} marginBottom={isMediaMobile && sizes.size100}>
            <View marginBottom={sizes.size400}>
              <ActionIcon onPress={() => navigate('/')}>
                <StaticImage src="logo.svg" alt="Logo" width={(isMediaMobile && sizes.size1000) || sizes.size1200} />
              </ActionIcon>
            </View>
          </Flex>

          {isMediaMobile && (
            <Flex justifyContent={isMediaMobile ? 'center' : undefined} gap={sizes.size400} marginBottom={sizes.size100}>
              <View alignSelf="center">
                <a href="https://www.facebook.com/sendpaymentscom" target="_blank" rel="noreferrer">
                  <StaticImage src="facebook.svg" alt="Facebook" width={isMediaMobile ? sizes.size450 : sizes.size300} />
                </a>
              </View>
              <View alignSelf="center">
                <a href="https://www.instagram.com/sendpayments" target="_blank" rel="noreferrer">
                  <StaticImage src="instagram.svg" alt="Instagram" width={isMediaMobile ? sizes.size450 : sizes.size300} />
                </a>
              </View>
              <View alignSelf="center">
                <a href="https://www.linkedin.com/company/sendpayments" target="_blank" rel="noreferrer">
                  <StaticImage src="linkedin.svg" alt="Linkedin" width={isMediaMobile ? sizes.size450 : sizes.size300} />
                </a>
              </View>
              <View alignSelf="center">
                <a href="https://www.twitter.com/sendpayments" target="_blank" rel="noreferrer">
                  <StaticImage src="twitter.svg" alt="Twitter" width={isMediaMobile ? sizes.size450 : sizes.size300} />
                </a>
              </View>
            </Flex>
          )}

          <View marginBottom={sizes.size200}>
            <Flex direction="row" gap={sizes.size200} wrap justifyContent={isMediaMobile ? 'center' : 'start'}>
              <View alignSelf="center" gap={isMediaMobile && sizes.size100}>
                <SecondaryHyperlink size="S">
                  <SecondaryFont>
                    <Link>
                      <a href={metadata.tc} target="_blank" rel="noreferrer">
                        {' '}
                        Terms & Conditions
                      </a>
                    </Link>
                  </SecondaryFont>
                </SecondaryHyperlink>
              </View>
              <View>|</View>
              <View alignSelf="center" marginEnd={isMediaMobile && sizes.size100} marginStart={isMediaMobile && sizes.size100}>
                <SecondaryHyperlink size="S">
                  <SecondaryFont>
                    <Link>
                      <a href={metadata.privacyPolicy} target="_blank" rel="noreferrer">
                        {' '}
                        Privacy Policy
                      </a>
                    </Link>
                  </SecondaryFont>
                </SecondaryHyperlink>
              </View>
              <View>|</View>
              <View alignSelf="center" marginEnd={isMediaMobile && sizes.size100} marginStart={isMediaMobile && sizes.size100}>
                <SecondaryHyperlink size="S">
                  <SecondaryFont>
                    <Link>
                      <a href={metadata.financialServiceGide} target="_blank" rel="noreferrer">
                        {' '}
                        Financial Service Guides
                      </a>
                    </Link>
                  </SecondaryFont>
                </SecondaryHyperlink>
              </View>
              <View>|</View>
              <View alignSelf="center" marginEnd={isMediaMobile && sizes.size100} marginStart={isMediaMobile && sizes.size100}>
                <SecondaryHyperlink size="S">
                  <SecondaryFont>
                    <Link>
                      <a href={metadata.productDisclosure} target="_blank" rel="noreferrer">
                        {' '}
                        Product Disclosure Statements
                      </a>
                    </Link>
                  </SecondaryFont>
                </SecondaryHyperlink>
              </View>
              <View>|</View>
              <View alignSelf="center" marginEnd={isMediaMobile && sizes.size100} marginStart={isMediaMobile && sizes.size100}>
                <SecondaryHyperlink size="S">
                  <SecondaryFont>
                    <Link>
                      <a href={metadata.targetMarketDetermination} target="_blank" rel="noreferrer">
                        {' '}
                        Target Market Determination
                      </a>
                    </Link>
                  </SecondaryFont>
                </SecondaryHyperlink>
              </View>
            </Flex>
          </View>
          <View alignSelf="center" marginBottom={sizes.size200}>
            <div className={cn(styles.copy, isMediaMobile && styles.centered)}>
              SendFX Limited ACN 617 647 220 is regulated by the Australian Securities and Investments Commission (‘ASIC’), is a
              registered remittance provider with the Australian Transaction Reports and Analysis Centre (‘AUSTRAC’), is a member
              of the Australian Financial Complaints Authority (‘AFCA’) and holds an Australian Financial Services Licence
              (‘AFSL’) (number 509635).
            </div>
          </View>
          <View alignSelf="center" marginBottom={sizes.size350}>
            <div className={cn(styles.copy, isMediaMobile && styles.centered)}>
              The information on this website is general and does not take into account your objectives, financial situation, or
              needs. Before acting on any advice, you should consider whether it’s appropriate to you in light of your objectives,
              financial situation, or needs. Please review our&nbsp;
              <a className={cn(styles.disclaimerLink)} href={metadata.productDisclosure} target="_blank" rel="noreferrer">
                Product Disclosure Statement
              </a>
              &nbsp;and&nbsp;
              <a className={cn(styles.disclaimerLink)} href={metadata.financialServiceGide} target="_blank" rel="noreferrer">
                Financial Services Guide
              </a>
              &nbsp;prior to making a decision.
            </div>
          </View>
        </Flex>
      </BaseLayout>
    </WebsiteTheme>
  );
};

Footer.displayName = 'Footer';

export default Footer;
