import img0 from '@send-images/animations/hero_business_front_img_0.png';
import img1 from '@send-images/animations/hero_business_front_img_1.png';
import img2 from '@send-images/animations/hero_business_front_img_2.png';
import img3 from '@send-images/animations/hero_business_front_img_3.png';
import img4 from '@send-images/animations/hero_business_front_img_4.png';
import img5 from '@send-images/animations/hero_business_front_img_5.png';
import img6 from '@send-images/animations/hero_business_front_img_6.png';
import img7 from '@send-images/animations/hero_business_front_img_7.png';
import img8 from '@send-images/animations/hero_business_front_img_8.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 101,
  w: 580,
  h: 766,
  nm: 'Pre-comp 2',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 94, h: 94, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 206, h: 126, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 64, h: 64, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 76, h: 76, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 176, h: 101, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 76, h: 76, u: '', p: img5, e: 0 },
    { id: 'image_6', w: 176, h: 101, u: '', p: img6, e: 0 },
    { id: 'image_7', w: 76, h: 76, u: '', p: img7, e: 0 },
    { id: 'image_8', w: 176, h: 101, u: '', p: img8, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Transfer.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 13, s: [0] },
                { t: 59, s: [360] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [384, 303, 0], ix: 2 },
            a: { a: 0, k: [47, 47, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 4,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 6,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 10,
                  s: [78, 78, 100],
                },
                { t: 13, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Card.pdf',
          cl: 'pdf',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [290, 363, 0], ix: 2 },
            a: { a: 0, k: [103, 63, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 375,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'Tick.pdf',
          cl: 'pdf',
          parent: 5,
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 56, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 60, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 87, s: [100] },
                { t: 98, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [48, 47.25, 0], ix: 2 },
            a: { a: 0, k: [32, 32, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 56,
                  s: [35, 35, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 60,
                  s: [116, 116, 100],
                },
                { t: 63, s: [105, 105, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 20,
          op: 386,
          st: 11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'Face1.pdf',
          cl: 'pdf',
          parent: 5,
          refId: 'image_3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 68, s: [100] },
                { t: 69, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [48, 46.5, 0], ix: 2 },
            a: { a: 0, k: [38, 38, 0], ix: 1 },
            s: { a: 0, k: [80, 80, 100], ix: 6 },
          },
          ao: 0,
          ip: 20,
          op: 386,
          st: 11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'GBP.pdf',
          cl: 'pdf',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 87, s: [100] },
                { t: 98, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 19,
                  s: [290, 343, 0],
                  to: [0, 24.5, 0],
                  ti: [0, -23, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 25, s: [290, 490, 0], to: [0, 23, 0], ti: [0, 1, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 28,
                  s: [290, 481, 0],
                  to: [0, -1, 0],
                  ti: [0, -0.5, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 30, s: [290, 484, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                { t: 32, s: [290, 484, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [88, 50.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 20,
          op: 386,
          st: 11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'Tick.pdf',
          cl: 'pdf',
          parent: 8,
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 56, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 60, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 87, s: [100] },
                { t: 98, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [48, 47.25, 0], ix: 2 },
            a: { a: 0, k: [32, 32, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 56,
                  s: [35, 35, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 60,
                  s: [116, 116, 100],
                },
                { t: 63, s: [105, 105, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 20,
          op: 386,
          st: 11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: 'Face2.pdf',
          cl: 'pdf',
          parent: 8,
          refId: 'image_5',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 68, s: [100] },
                { t: 69, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [48, 46.5, 0], ix: 2 },
            a: { a: 0, k: [38, 38, 0], ix: 1 },
            s: { a: 0, k: [80, 80, 100], ix: 6 },
          },
          ao: 0,
          ip: 20,
          op: 386,
          st: 11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: 'NZD.pdf',
          cl: 'pdf',
          refId: 'image_6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 87, s: [100] },
                { t: 98, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 19,
                  s: [290, 343, 0],
                  to: [0, 24.5, 0],
                  ti: [0, -23, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 25, s: [290, 490, 0], to: [0, 23, 0], ti: [0, 1, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 28,
                  s: [290, 481, 0],
                  to: [0, -1, 0],
                  ti: [0, -0.5, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 30, s: [290, 484, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 32,
                  s: [290, 484, 0],
                  to: [-30, 0, 0],
                  ti: [28, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 37,
                  s: [110, 484, 0],
                  to: [-28, 0, 0],
                  ti: [-1, 0, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 40, s: [122, 484, 0], to: [1, 0, 0], ti: [1, 0, 0] },
                { t: 42, s: [116, 484, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [88, 50.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 20,
          op: 386,
          st: 11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 2,
          nm: 'Tick.pdf',
          cl: 'pdf',
          parent: 11,
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 56, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 60, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 87, s: [100] },
                { t: 98, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [48, 47.25, 0], ix: 2 },
            a: { a: 0, k: [32, 32, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 56,
                  s: [35, 35, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 60,
                  s: [116, 116, 100],
                },
                { t: 63, s: [105, 105, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 20,
          op: 386,
          st: 11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 2,
          nm: 'Face3.pdf',
          cl: 'pdf',
          parent: 11,
          refId: 'image_7',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 68, s: [100] },
                { t: 69, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [48, 46.5, 0], ix: 2 },
            a: { a: 0, k: [38, 38, 0], ix: 1 },
            s: { a: 0, k: [80, 80, 100], ix: 6 },
          },
          ao: 0,
          ip: 20,
          op: 386,
          st: 11,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 2,
          nm: 'USD.pdf',
          cl: 'pdf',
          refId: 'image_8',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 87, s: [100] },
                { t: 98, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 19,
                  s: [290, 343, 0],
                  to: [0, 24.5, 0],
                  ti: [0, -23, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 25, s: [290, 490, 0], to: [0, 23, 0], ti: [0, 1, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 28,
                  s: [290, 481, 0],
                  to: [0, -1, 0],
                  ti: [0, -0.5, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 30,
                  s: [290, 484, 0],
                  to: [0, 0, 0],
                  ti: [0.108, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 31,
                  s: [290, 484, 0],
                  to: [-0.055, 0, 0],
                  ti: [-5.312, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 32,
                  s: [290, 484, 0],
                  to: [47.8, 0, 0],
                  ti: [-22.587, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 37,
                  s: [470, 484, 0],
                  to: [30, 0, 0],
                  ti: [1.667, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 40,
                  s: [460, 484, 0],
                  to: [-1.667, 0, 0],
                  ti: [-1.667, 0, 0],
                },
                { t: 42, s: [470, 484, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [88, 50.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 20,
          op: 386,
          st: 11,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Pre-comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 2, ix: 10 },
        p: { a: 0, k: [270, 509, 0], ix: 2 },
        a: { a: 0, k: [290, 383, 0], ix: 1 },
        s: { a: 0, k: [65, 65, 100], ix: 6 },
      },
      ao: 0,
      w: 580,
      h: 766,
      ip: 0,
      op: 101,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
