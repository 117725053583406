import img0 from '@send-images/animations/hero_partner_img_0.png';
import img1 from '@send-images/animations/hero_partner_img_1.png';
import img2 from '@send-images/animations/hero_partner_img_2.png';
import img3 from '@send-images/animations/hero_partner_img_3.png';
import img4 from '@send-images/animations/hero_partner_img_4.png';
import img5 from '@send-images/animations/hero_partner_img_5.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 450,
  w: 580,
  h: 766,
  nm: 'Personal Hero',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 180, h: 175, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 115, h: 133, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 284, h: 530, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 563, h: 361, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 312, h: 469, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 521, h: 521, u: '', p: img5, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'BOTTOM.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: 0, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 76, s: [10] },
                { t: 151, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 0,
                  s: [443, 664, 0],
                  to: [-1.833, -1.833, 0],
                  ti: [-2.285, -2.285, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 40,
                  s: [436.408, 657.408, 0],
                  to: [2.807, 2.807, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 76,
                  s: [454, 675, 0],
                  to: [0, 0, 0],
                  ti: [2.887, 2.887, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 121,
                  s: [448.165, 677.165, 0],
                  to: [-2.204, -2.204, 0],
                  ti: [0.794, 0.794, 0],
                },
                { t: 151, s: [443, 664, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [90, 87.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 0,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 76,
                  s: [92, 92, 100],
                },
                { t: 151, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 450,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Top.pdf',
          cl: 'pdf',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: 0, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 71, s: [10] },
                { t: 151, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 0,
                  s: [150.259, 167.259, 0],
                  to: [1.667, -1.667, 0],
                  ti: [0.167, 0.5, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 32,
                  s: [160.258, 157.258, 0],
                  to: [-0.167, -0.5, 0],
                  ti: [-2.667, -2.167, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 71,
                  s: [149.258, 164.258, 0],
                  to: [2.667, 2.167, 0],
                  ti: [-0.167, -0.5, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 111,
                  s: [176.258, 170.258, 0],
                  to: [0.167, 0.5, 0],
                  ti: [4.333, 0.5, 0],
                },
                { t: 151, s: [150.259, 167.259, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [82, 104.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 0,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 71,
                  s: [92, 92, 100],
                },
                { t: 151, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 450,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Pre-comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -4, ix: 10 },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [291, 400, 0], ix: 1 },
        s: { a: 0, k: [86, 86, 100], ix: 6 },
      },
      ao: 0,
      w: 582,
      h: 800,
      ip: 298,
      op: 450,
      st: 298,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'Pre-comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -4, ix: 10 },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [291, 400, 0], ix: 1 },
        s: { a: 0, k: [86, 86, 100], ix: 6 },
      },
      ao: 0,
      w: 582,
      h: 800,
      ip: 149,
      op: 298,
      st: 149,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: 'Pre-comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -4, ix: 10 },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [291, 400, 0], ix: 1 },
        s: { a: 0, k: [86, 86, 100], ix: 6 },
      },
      ao: 0,
      w: 582,
      h: 800,
      ip: 0,
      op: 149,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'Phone.pdf',
      cl: 'pdf',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 333, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 338, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 435, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 440, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 485, s: [100] },
            { t: 491, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 333, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 435, s: [6] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 441, s: [29] },
            { t: 490, s: [12] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [142, 265, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 332,
      op: 444,
      st: 175,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'Tab.pdf',
      cl: 'pdf',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 175, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 180, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 315, s: [100] },
            { t: 321, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 175, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 315, s: [-8] },
            { t: 321, s: [-30] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [264, 383, 0], ix: 2 },
        a: { a: 0, k: [264.382, 180.5, 0], ix: 1 },
        s: { a: 0, k: [99, 99, 100], ix: 6 },
      },
      ao: 0,
      ip: 175,
      op: 328,
      st: 147,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: 'Card.pdf',
      cl: 'pdf',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 14, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 19, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 157, s: [100] },
            { t: 163, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 14, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 157, s: [12] },
            { t: 163, s: [37] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [290, 383, 0], ix: 2 },
        a: { a: 0, k: [156, 234.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 14,
      op: 172,
      st: 14,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'Shape.pdf',
      cl: 'pdf',
      refId: 'image_5',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [50] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 13, s: [100] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 437, s: [100] },
            { t: 449, s: [50] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [24] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 13, s: [50] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 159, s: [57.626] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 171, s: [129.918] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 316, s: [134.918] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 328, s: [224.918] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 437, s: [224.918] },
            { t: 449, s: [24] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [282, 443, 0],
              to: [-1.333, -4.167, 0],
              ti: [1.333, 4.167, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 13,
              s: [274, 418, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 159,
              s: [274, 418, 0],
              to: [-3.833, -5.167, 0],
              ti: [3.833, 5.167, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 171,
              s: [251, 387, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 316,
              s: [251, 387, 0],
              to: [9.5, -3, 0],
              ti: [-9.5, 3, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 328,
              s: [308, 369, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 437,
              s: [308, 369, 0],
              to: [-4.333, 12.333, 0],
              ti: [4.333, -12.333, 0],
            },
            { t: 449, s: [282, 443, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [276, 298, 0], ix: 1 },
        s: { a: 0, k: [90.024, 90.024, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
