import React from 'react';
import { View, Flex } from '@adobe/react-spectrum';
import { staticSizes } from '@sendpayments/react-shared/design-system';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import AnchorSelect from '@spectrum-icons/workflow/AnchorSelect';
import PublishPending from '@spectrum-icons/workflow/PublishPending';
import ClockCheck from '@spectrum-icons/workflow/ClockCheck';
import Game from '@spectrum-icons/workflow/Game';
import EmailLightning from '@spectrum-icons/workflow/EmailLightning';
import Refresh from '@spectrum-icons/workflow/Refresh';
import Camera from '@spectrum-icons/workflow/Camera';
import FlashOn from '@spectrum-icons/workflow/FlashOn';
import GraphBarVerticalAdd from '@spectrum-icons/workflow/GraphBarVerticalAdd';
import Location from '@spectrum-icons/workflow/Location';
import PeopleGroup from '@spectrum-icons/workflow/PeopleGroup';
import Orbit from '@spectrum-icons/workflow/Orbit';
import Pending from '@spectrum-icons/workflow/Pending';
import Deduplication from '@spectrum-icons/workflow/Deduplication';
import { StaticImage } from '@send-components/StaticImage';
import { Subtitle } from '@send-components/Subtitle';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { breakpoints } from '@send-base/design-system';
import styles from './CardCarousel.module.less';

const availableIcons = {
  AnchorSelect,
  PublishPending,
  ClockCheck,
  Game,
  EmailLightning,
  Refresh,
  Camera,
  FlashOn,
  GraphBarVerticalAdd,
  Location,
  PeopleGroup,
  Orbit,
  Pending,
  Deduplication,
};

const Card = ({ title, text, bottomLayerImage, icon, iconBackground, iconColor }) => {
  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.sm });
  const Icon = availableIcons[icon];
  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.imageOverlay}>
        <StaticImage src={bottomLayerImage} />
      </div>
      <View position="relative" margin={isMobile ? staticSizes.size400 : staticSizes.size550}>
        <div className={styles.cardBox}>
          <div className={styles.cardCircle} style={{ backgroundColor: iconBackground, color: iconColor }}>
            <Icon size="L" />
          </div>
          <Flex direction="column">
            <View>
              <Subtitle level="2">
                <strong>{title}</strong>
              </Subtitle>
            </View>
            <View marginTop={staticSizes.size225}>
              <Subtitle level="4" leftAlign>
                {text}
              </Subtitle>
            </View>
          </Flex>
        </div>
      </View>
    </div>
  );
};

Card.displayName = 'Card';

export default withTheme(Card);
