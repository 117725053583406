import img0 from '@send-images/animations/feature_partner_3_img_0.png';
import img1 from '@send-images/animations/feature_partner_3_img_1.png';
import img2 from '@send-images/animations/feature_partner_3_img_2.png';
import img3 from '@send-images/animations/feature_partner_3_img_3.png';
import img4 from '@send-images/animations/feature_partner_3_img_4.png';
import img5 from '@send-images/animations/feature_partner_3_img_5.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 100,
  w: 650,
  h: 510,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 71, h: 70, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 142, h: 142, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 104, h: 104, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 162, h: 162, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 218, h: 218, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 284, h: 284, u: '', p: img5, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'Group 75047.pdf',
      cl: 'pdf',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [503, 294, 0], ix: 2 },
        a: { a: 0, k: [35.5, 35, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Group 75048.pdf',
      cl: 'pdf',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [177, 298, 0], ix: 2 },
        a: { a: 0, k: [71, 71, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'Group 75046.pdf',
      cl: 'pdf',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [372, 186, 0], ix: 2 },
        a: { a: 0, k: [52, 52, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'Path 392450.pdf',
      cl: 'pdf',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 34, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 47, s: [45] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 62, s: [45] },
            { t: 71, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [503, 295, 0], ix: 2 },
        a: { a: 0, k: [81, 81, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'Path 392449.pdf',
      cl: 'pdf',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 34, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 47, s: [-45] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 62, s: [-45] },
            { t: 71, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [371, 190, 0], ix: 2 },
        a: { a: 0, k: [109, 109, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: 'Path 392451.pdf',
      cl: 'pdf',
      refId: 'image_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 34, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 47, s: [45] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 62, s: [45] },
            { t: 71, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [177, 299, 0], ix: 2 },
        a: { a: 0, k: [142, 142, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
