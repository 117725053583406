import img0 from '@send-images/animations/textlist_home_1_img_0.png';
import img1 from '@send-images/animations/textlist_home_1_img_1.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 450,
  w: 582,
  h: 800,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 219, h: 1289, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 215, h: 1271, u: '', p: img1, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Right.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 0, s: [432, 710, 0], to: [0, -78, 0], ti: [0, 78, 0] },
                { t: 449, s: [432, 242, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [109.5, 633.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 450,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Left.pdf',
          cl: 'pdf',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [174, 106, 0],
                  to: [0, 115.833, 0],
                  ti: [0, -115.833, 0],
                },
                { t: 449, s: [174, 801, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [107.5, 635.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 450,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Pre-comp 2',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [291, 403, 0], ix: 2 },
        a: { a: 0, k: [291, 400, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [602, 68],
                [-58.297, 66],
                [-58.297, 723.57],
                [602, 723.57],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 1, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      w: 582,
      h: 800,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
