import React from 'react';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { Flex } from '@adobe/react-spectrum';
import { Confirmation } from '@send-components/Confirmation';
import { navigate } from 'gatsby';

const NoticeError = ({ name, theme, props }) => {
  const { heading, subtitle, type, confirmBtn } = props;
  const onConfirm = confirmBtn.url ? () => navigate(confirmBtn.url) : () => navigate(-1);

  return (
    <WebsiteTheme name={name} theme={theme}>
      <Flex alignItems="center" justifyContent="center" width="100vw" height="100vh">
        <Confirmation iconType={type} title={heading} text={subtitle} confirmText={confirmBtn.text} onConfirm={onConfirm} />
      </Flex>
    </WebsiteTheme>
  );
};

export default NoticeError;
