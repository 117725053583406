import React, { useRef, useState, useEffect } from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { Flex, View } from '@adobe/react-spectrum';
import { PrimaryButton } from '@send-components';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { Title } from '@send-components/Title';
import { Subtitle } from '@send-components/Subtitle';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { MobileSectionContainer } from '@send-components/MobileSectionContainer';
import { AnimationTransition } from '@sendpayments/react-shared/components/AnimationTransition';
import { forwardParams } from '@sendpayments/js-utils/services/analytics/legacy';
import transitions from '@send-less/transitions.module.less';
import { useIsVisible } from 'react-is-visible';
import { breakpoints } from '@send-base/design-system';
import styles from './CardCarousel.module.less';
import { SectionLayout } from '../SectionLayout';
import Card from './Card';

import { HorizontalSwipeableList, HorizontalSwipeableListItem } from '../../components/HorizontalSwipableList';

const Section = ({ name, theme, metadata, props, onOpenForm }) => {
  const {
    minHeight,
    heading,
    showPortalLinks,
    showReachOutLink,
    showPartnershipForm,
    cards,
    cardHeight = 380,
    underlineColour,
    subtitle,
    textButton = 'Register now',
  } = props || {};
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [onceVisible, setOnceVisible] = useState(false);

  const onPortalSignUp = () => window.open(forwardParams(metadata.portalSignUp));
  const onReachOut = () => window.open(showReachOutLink);
  const onGetInTouch = () => onOpenForm({ name: 'ContactUs' });
  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.sm });

  useEffect(() => {
    if (!onceVisible && isVisible) {
      setOnceVisible(true);
    }
  }, [isVisible]);

  const cardItem = (card, height, paddingTop, index) => (
    <HorizontalSwipeableListItem key={index}>
      <View UNSAFE_lassName={styles.cardItem} height={height} paddingTop={paddingTop}>
        {onceVisible && (
          <AnimationTransition
            startClassName={transitions.elementNotVisible}
            endClassName={transitions.elementVisible}
            applyTransition="opacity 0.5s"
            delayMs="0"
          >
            <Card
              title={card.title}
              bottomLayerImage={card.bottomLayerImage || ''}
              text={card.text}
              icon={card.icon}
              iconBackground={card.iconBackground}
              iconColor={card.iconColor}
            />
          </AnimationTransition>
        )}
      </View>
    </HorizontalSwipeableListItem>
  );
  const cardSwipeSm = () => (
    <View flex="1" width="100vw" overflow="hidden">
      <HorizontalSwipeableList height="400px" itemWidth={310}>
        {cards.map((c, index) => cardItem(c, index))}
      </HorizontalSwipeableList>
      <div className={styles.visibleIndicator} ref={nodeRef} />
    </View>
  );
  const cardSwipeLg = () => {
    const rows = [];
    const itemPerRow = 3;
    for (let i = 0; i < cards.length; i += itemPerRow) {
      const row = cards.slice(i, i + itemPerRow);
      rows.push(
        <HorizontalSwipeableList key={i} height={`${cardHeight + 60}px`} itemWidth={450}>
          {row.map((c, index) => cardItem(c, cardHeight, `${index * 26}px`, (i + 1) * index))}
        </HorizontalSwipeableList>,
      );
    }
    return (
      <View flex="1" width="100vw" overflow="hidden">
        {rows}
      </View>
    );
  };
  const cardSwipeView = isMobile ? cardSwipeSm() : cardSwipeLg();

  return (
    <WebsiteTheme name={name} theme={theme}>
      <SectionLayout removePadding>
        <MobileSectionContainer>
          <Flex direction="column" minHeight={!isMobile ? `${minHeight}px` : 'auto'}>
            <Flex direction="column" justifyContent="space-between">
              <View flex="1" alignSelf="center" paddingTop={!isMobile ? '60px' : '0px'}>
                <SectionLayout>
                  <Title level="1" underlineColour={underlineColour}>
                    <SecondaryFont>
                      <HtmlText>{heading}</HtmlText>
                    </SecondaryFont>
                  </Title>
                </SectionLayout>
              </View>
              {subtitle && (
                <View flex="1" alignSelf="center">
                  <SectionLayout>
                    <Subtitle level={3}>
                      <HtmlText>{subtitle}</HtmlText>
                    </Subtitle>
                  </SectionLayout>
                </View>
              )}
              <View height={sizes.size700} />
              <View flex="1" overflow="hidden">
                {cardSwipeView}
                <div className={styles.visibleIndicator} ref={nodeRef} />
              </View>
              <View height={sizes.size700} />
              <View flex="1" alignSelf="center" paddingBottom={!isMobile ? '60px' : '0px'}>
                <SectionLayout>
                  {showPortalLinks && (
                    <Flex gap={sizes.size130}>
                      <View alignSelf="center">
                        <SecondaryFont>Sounds good?</SecondaryFont>
                      </View>
                      <View justifyContent="center" alignSelf="center">
                        <PrimaryButton onPress={onPortalSignUp}>{textButton}</PrimaryButton>
                      </View>
                    </Flex>
                  )}
                  {showReachOutLink && (
                    <Flex gap={sizes.size130}>
                      <View alignSelf="center">
                        <SecondaryFont>Sounds good?</SecondaryFont>
                      </View>
                      <View justifyContent="center" alignSelf="center">
                        <PrimaryButton onPress={onReachOut}>{textButton}</PrimaryButton>
                      </View>
                    </Flex>
                  )}
                  {showPartnershipForm && (
                    <Flex gap={sizes.size130}>
                      <View alignSelf="center">
                        <SecondaryFont>Sounds good?</SecondaryFont>
                      </View>
                      <View justifyContent="center" alignSelf="center">
                        <PrimaryButton onPress={onGetInTouch}>{textButton}</PrimaryButton>
                      </View>
                    </Flex>
                  )}
                </SectionLayout>
              </View>
            </Flex>
          </Flex>
        </MobileSectionContainer>
      </SectionLayout>
    </WebsiteTheme>
  );
};

export default Section;
