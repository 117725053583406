import React, { useState, useCallback } from 'react';
import { ContextDevTool } from 'react-context-devtool';
import { FormsContext } from './Context';

const FormsProvider = ({ children, registeredForms }) => {
  const [modalsState, setVisible] = useState({});

  const setModalVisible = useCallback(({ name }) => setVisible({ ...modalsState, [name]: { isVisible: true } }));
  const setModalHidden = useCallback(({ name }) => setVisible({ ...modalsState, [name]: { isVisible: false } }));

  return (
    <FormsContext.Provider
      value={{
        forms: registeredForms.reduce((acc, { formName }) => {
          if (!acc[formName]) {
            acc[formName] = {
              isVisible: !!modalsState[formName]?.isVisible,
              setVisible: () => setModalVisible({ name: formName }),
              setHidden: () => setModalHidden({ name: formName }),
            };
          }
          return acc;
        }, {}),
        registeredForms,
      }}
    >
      <ContextDevTool context={FormsContext} id={`FormsContext`} displayName={`FormsContext`} />
      {children}
    </FormsContext.Provider>
  );
};

export default FormsProvider;
