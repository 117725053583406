import React, { useRef, useEffect, useState } from 'react';
import { Flex, View } from '@adobe/react-spectrum';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { AnimationTransition } from '@sendpayments/react-shared/components/AnimationTransition';
import {
  HorizontalSwipeableList,
  HorizontalSwipeableListItem,
} from '@sendpayments/react-shared/components/HorizontalSwipeableList';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { Title } from '@send-components/Title';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { MobileSectionContainer } from '@send-components/MobileSectionContainer';
import transitions from '@send-less/transitions.module.less';
import { useIsVisible } from 'react-is-visible';
import { breakpoints } from '@send-base/design-system';
import styles from './ImageCarousel.module.less';
import { SectionLayout } from '../SectionLayout';
import CardImage from './CardImage';

const Section = ({ name, theme, props }) => {
  const { minHeight, heading, images, underlineColour } = props || {};
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [onceVisible, setOnceVisible] = useState(false);

  const { isMedia } = useBreakpoint(breakpoints);

  const images1 = images[0];
  const images2 = images[1];
  const images3 = images[2];

  useEffect(() => {
    if (!onceVisible && isVisible) {
      setOnceVisible(true);
    }
  }, [isVisible]);

  const isMobile = isMedia({ to: breakpoints.sm });
  const isXs = isMedia({ to: breakpoints.xs });

  const itemWidth = (isXs && 260) || 450;
  const itemsSize = (images3 && 3) || 2;

  return (
    <WebsiteTheme name={name} theme={theme}>
      <SectionLayout removePadding>
        <MobileSectionContainer>
          <Flex direction="column" minHeight={!isMobile ? `${minHeight}px` : 'auto'} justifyContent="center">
            <Flex direction="column" justifyContent="space-between">
              <View flex="1" alignSelf="center">
                <div className={styles.title}>
                  <Title level="3" center lineHeight="1.5" underlineColour={underlineColour}>
                    <SecondaryFont>
                      <HtmlText>{heading}</HtmlText>
                    </SecondaryFont>
                  </Title>
                </div>
              </View>
              <View
                flex="1"
                UNSAFE_style={{
                  maxWidth: '100vw',
                  width: isXs ? '100vw' : itemsSize * itemWidth,
                  overflow: 'hidden',
                  margin: itemsSize === 2 && !isXs && 'auto',
                }}
              >
                <HorizontalSwipeableList itemWidth={itemWidth}>
                  <HorizontalSwipeableListItem>
                    <div className={styles.cardItem}>
                      {onceVisible && (
                        <AnimationTransition
                          startClassName={transitions.elementNotVisible}
                          endClassName={transitions.elementVisible}
                          applyTransition="opacity 0.5s"
                          delayMs="80"
                        >
                          <CardImage text={images1.text} image={images1.image} />
                        </AnimationTransition>
                      )}
                    </div>
                  </HorizontalSwipeableListItem>
                  <HorizontalSwipeableListItem>
                    <div className={styles.cardItem}>
                      {onceVisible && (
                        <AnimationTransition
                          startClassName={transitions.elementNotVisible}
                          endClassName={transitions.elementVisible}
                          applyTransition="opacity 0.5s"
                          delayMs="480"
                        >
                          <CardImage text={images2.text} image={images2.image} />
                        </AnimationTransition>
                      )}
                    </div>
                  </HorizontalSwipeableListItem>
                  {images3 && (
                    <HorizontalSwipeableListItem>
                      <div className={styles.cardItem}>
                        {onceVisible && (
                          <AnimationTransition
                            startClassName={transitions.elementNotVisible}
                            endClassName={transitions.elementVisible}
                            applyTransition="opacity 0.5s"
                            delayMs="880"
                          >
                            <CardImage text={images3.text} image={images3.image} />
                          </AnimationTransition>
                        )}
                      </div>
                    </HorizontalSwipeableListItem>
                  )}
                </HorizontalSwipeableList>
                <div className={styles.visibleIndicator} ref={nodeRef} />
              </View>
            </Flex>
          </Flex>
        </MobileSectionContainer>
      </SectionLayout>
    </WebsiteTheme>
  );
};

export default Section;
