import React, { useRef, useState, useEffect } from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { Flex, View } from '@adobe/react-spectrum';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { StaticImage } from '@send-components/StaticImage';
import { Title } from '@send-components/Title';
import { Subtitle } from '@send-components/Subtitle';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { MobileSectionContainer } from '@send-components/MobileSectionContainer';
import { AnimationTransition } from '@sendpayments/react-shared/components/AnimationTransition';
import transitions from '@send-less/transitions.module.less';
import { ApplyTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import { useIsVisible } from 'react-is-visible';
import { breakpoints } from '@send-base/design-system';
import styles from './Showcase.module.less';
import { SectionLayout } from '../SectionLayout';
import { HorizontalSwipeableList, HorizontalSwipeableListItem } from '../../components/HorizontalSwipableList';

const Showcase = ({ name, theme, props }) => {
  const { minHeight, heading, cards, cardHeight = 320, cardHeightMobile = 550, underlineColour, subtitle } = props || {};
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [onceVisible, setOnceVisible] = useState(false);

  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.sm });

  useEffect(() => {
    if (!onceVisible && isVisible) {
      setOnceVisible(true);
    }
  }, [isVisible]);

  const cardItem = (i, { image, description, author, title }, height) => (
    <HorizontalSwipeableListItem key={i}>
      <View height={height} overflow="hidden">
        <div className={styles.cardItem}>
          {onceVisible && (
            <AnimationTransition
              startClassName={transitions.elementNotVisible}
              endClassName={transitions.elementVisible}
              applyTransition="opacity 0.5s"
              delayMs="0"
            >
              <Flex justifyContent="center">
                <ApplyTheme displayName="CardContainer">
                  <div className={styles.cardContainer}>
                    <View paddingX={sizes.size400} paddingY={sizes.size600}>
                      <Flex direction="column" gap={sizes.size300}>
                        <Flex justifyContent="center">
                          <StaticImage src={image} width={sizes.size1200} />
                        </Flex>
                        <Flex direction="column" justifyContent="center" gap={sizes.size225}>
                          <View>
                            <Subtitle level="3" center>
                              <HtmlText>{description}</HtmlText>
                            </Subtitle>
                          </View>
                          <View>
                            <Subtitle level="5" center>
                              <HtmlText>{author}</HtmlText>
                            </Subtitle>
                          </View>
                          <View>
                            <Subtitle level="5" center>
                              <HtmlText>{title}</HtmlText>
                            </Subtitle>
                          </View>
                        </Flex>
                      </Flex>
                    </View>
                  </div>
                </ApplyTheme>
              </Flex>
            </AnimationTransition>
          )}
        </div>
      </View>
    </HorizontalSwipeableListItem>
  );
  const cardSwipeSm = () => (
    <View flex="1" width="100vw">
      <HorizontalSwipeableList height={`${cardHeightMobile}px`} itemWidth={310}>
        {cards.map((c, i) => cardItem(i, c))}
      </HorizontalSwipeableList>
      <div className={styles.visibleIndicator} ref={nodeRef} />
    </View>
  );
  const cardSwipeLg = () => (
    <View flex="1" width="100vw" alignSelf="center">
      <HorizontalSwipeableList height={`${cardHeight}px`} itemWidth={900}>
        {cards.map((c, i) => cardItem(i, c, cardHeight))}
      </HorizontalSwipeableList>
    </View>
  );
  const cardSwipeView = isMobile ? cardSwipeSm() : cardSwipeLg();

  return (
    <WebsiteTheme name={name} theme={theme}>
      <SectionLayout removePadding>
        <MobileSectionContainer>
          <Flex direction="column" minHeight={!isMobile ? `${minHeight}px` : 'auto'} justifyContent="center">
            <Flex direction="column" justifyContent="space-between">
              <View flex="1" alignSelf="center">
                <SectionLayout>
                  <Title level="3" lineHeight="1.5" underlineColour={underlineColour}>
                    <SecondaryFont>
                      <HtmlText>{heading}</HtmlText>
                    </SecondaryFont>
                  </Title>
                </SectionLayout>
              </View>
              {subtitle && (
                <View flex="1" alignSelf="center">
                  <SectionLayout>
                    <Subtitle level={3} center>
                      <HtmlText>{subtitle}</HtmlText>
                    </Subtitle>
                  </SectionLayout>
                </View>
              )}
              <View height={sizes.size700} />
              <View flex="1" UNSAFE_style={{ overflow: 'hidden' }}>
                {cardSwipeView}
                <div className={styles.visibleIndicator} ref={nodeRef} />
              </View>
              <View height={sizes.size700} />
            </Flex>
          </Flex>
        </MobileSectionContainer>
      </SectionLayout>
    </WebsiteTheme>
  );
};

export default Showcase;
