import React from 'react';
import styles from './Loading.module.less';
import { StaticImage } from '../StaticImage';

const Loading = () => (
  <div className={styles.container}>
    <StaticImage src="loading.svg" />
  </div>
);

export default Loading;
