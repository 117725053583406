import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as componentExports from '@send-components';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { FormsContext } from '@send-components/Forms/Provider/Context';
import { ParallaxLayer } from '@send-components/ParallaxLayer';
import { PackagesLoader } from '@send-components/PackagesLoader';
import { withPrefix } from 'gatsby';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useIsVisible } from 'react-is-visible';
import * as layoutExports from '@send-layouts';
import { SSRProvider } from '@react-aria/ssr';
import { Helmet } from 'react-helmet';
import { breakpoints } from '../../design-system/index';
import './Composite.module.less';

const getComponent = (name) => {
  if (componentExports[name]) {
    return componentExports[name];
  }

  if (layoutExports[name]) {
    return layoutExports[name];
  }

  return undefined;
};

const getBackgroundImage = ({ isMediaSm, info }) => {
  if (isMediaSm) {
    if (info.mobileBgImage !== undefined) {
      return info.mobileBgImage;
    }

    return info.bgImage;
  }

  return info.bgImage;
};

const getBackgroundColor = ({ isMediaSm, info }) => {
  if (isMediaSm) {
    if (info?.mobileBgColor !== undefined) {
      return info?.mobileBgColor;
    }

    return info?.bgColour;
  }

  return info?.bgColour;
};

const IsVisible = ({ onVisible }) => {
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);

  useEffect(() => {
    onVisible(isVisible);
  }, [isVisible]);

  return <div ref={nodeRef} />;
};

// TODO: will remove the Parallax effect for now. Is causing performance issues.
const Compose = ({ sections, layout, metadata, header, footer, navigation }) => {
  const LayoutComponent = getComponent(layout.component);
  const [bg, setBg] = useState();
  const [scrollDirectionUp, setScrollDirectionUp] = useState(false);

  const { isMedia } = useBreakpoint(breakpoints);
  const { forms } = useContext(FormsContext);

  const onOpenForm = ({ name }) => {
    forms[name]?.setVisible();
  };

  const onCloseForm = ({ name }) => {
    forms[name]?.setHidden();
  };

  const onIsVisibleHandler = (isVisible, { backgroundColor, sectionTheme }) => {
    if (isVisible) {
      setBg({ backgroundColor, sectionTheme });
    }
  };

  const section0Info = sections && sections[0]?.info;
  const section0Theme = sections && sections[0]?.layout?.theme;
  const backgroundColorSection0 = getBackgroundColor({ isMediaSm: isMedia({ to: breakpoints.sm }), info: section0Info });

  useScrollPosition(({ prevPos, currPos }) => {
    setScrollDirectionUp(currPos.y > prevPos.y);
  }, []);

  // Comment this out due to it will disfunction sticky mode on (SSD-1159)
  useEffect(() => {
    const gatsbyNode = document.getElementById('___gatsby');
    gatsbyNode.style = 'overflow-x: hidden;';
  }, []);

  if (!LayoutComponent) {
    return null;
  }

  return (
    <>
      <PackagesLoader />
      <SSRProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metadata.title}</title>
          <meta name="description" content={metadata.description} />
          <meta name="facebook-domain-verification" content={metadata.facebookDomainVerification} />
          <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
        </Helmet>
        <div style={{ backgroundColor: backgroundColorSection0 }}>
          <LayoutComponent
            metadata={metadata}
            name={layout.name}
            theme={layout.theme}
            header={{
              ...header,
              backgroundColor: bg?.backgroundColor || backgroundColorSection0,
              theme: bg?.sectionTheme || header.theme || section0Theme,
            }}
            footer={footer}
            navigation={navigation}
            {...(layout.props || {})}
            onOpenForm={onOpenForm}
            onCloseForm={onCloseForm}
          >
            {sections.map(({ name, parallaxLayers, layout: { component, theme }, id, props, info, index }) => {
              const Component = getComponent(component);
              const bgImage = getBackgroundImage({ isMediaSm: isMedia({ to: breakpoints.sm }), info });
              const bgImageUrl = bgImage && withPrefix(`/images/${theme.toLowerCase()}/${bgImage}`);
              const backgroundColor = getBackgroundColor({ isMediaSm: isMedia({ to: breakpoints.sm }), info });
              const backgroundImage = info?.bgImage && `url(${bgImageUrl})`;

              return (
                <div
                  key={id}
                  style={{
                    backgroundImage,
                    backgroundColor,
                    backgroundPosition: info?.bgImage && 'center',
                    backgroundSize: info?.bgImage && 'cover',
                    zIndex: 1,
                  }}
                >
                  {!scrollDirectionUp && (
                    <IsVisible
                      onVisible={(isVisible) =>
                        onIsVisibleHandler(isVisible, { backgroundColor, backgroundImage, sectionTheme: theme })
                      }
                    />
                  )}
                  <ParallaxLayer key={id} layers={parallaxLayers}>
                    <Component
                      index={index}
                      name={name}
                      info={info}
                      metadata={metadata}
                      theme={theme}
                      props={props}
                      onOpenForm={onOpenForm}
                      onCloseForm={onCloseForm}
                      bgColour={backgroundColor}
                    />
                  </ParallaxLayer>
                  {scrollDirectionUp && (
                    <IsVisible
                      onVisible={(isVisible) =>
                        onIsVisibleHandler(isVisible, { backgroundColor, backgroundImage, sectionTheme: theme })
                      }
                    />
                  )}
                </div>
              );
            })}
          </LayoutComponent>
        </div>
      </SSRProvider>
    </>
  );
};

// TODO define correct prop types
Compose.propTypes = {
  sections: PropTypes.array.isRequired,
  layout: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  navigation: PropTypes.array.isRequired,
};

export default Compose;
