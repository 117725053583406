import img0 from '@send-images/animations/feature_personal_3_img_0.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 100,
  w: 650,
  h: 510,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 197, h: 190, u: '', p: img0, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Curr.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [154, 115.5, 0], ix: 2 },
            a: { a: 0, k: [98.5, 95, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 129, ix: 10 },
            p: { a: 0, k: [154, 115.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [20.26, -68.967],
                        [-52.048, -41.693],
                        [-50.286, 39.952],
                        [29.854, 59.612],
                        [73.655, -5.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: {
                    a: 1,
                    k: [
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 31, s: [100] },
                      { t: 90, s: [0] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 31, s: [100] },
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 37, s: [75.876] },
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 46, s: [62.189] },
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 62, s: [40.458] },
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 82, s: [2.559] },
                      { t: 90, s: [0] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 26, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [154, 115.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-0.327, -66.154],
                        [-64.5, -14.288],
                        [-41.673, 60],
                        [40.615, 59.212],
                        [66.538, -13.788],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: {
                    a: 1,
                    k: [
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 31, s: [100] },
                      { t: 90, s: [0] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 31, s: [100] },
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 37, s: [75.876] },
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 46, s: [62.189] },
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 62, s: [40.458] },
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 82, s: [2.559] },
                      { t: 90, s: [0] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 26, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Comp 2',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [325, 259, 0], ix: 2 },
        a: { a: 0, k: [154, 115.5, 0], ix: 1 },
        s: { a: 0, k: [250, 250, 100], ix: 6 },
      },
      ao: 0,
      w: 308,
      h: 231,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
