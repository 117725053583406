import React from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { Flex, View } from '@adobe/react-spectrum';
import { LayeredAnimation } from '@sendpayments/react-shared/components/LayeredAnimation';
import { PrimaryButton } from '@send-components';
import { SecondaryButton } from '@sendpayments/react-shared/components/buttons/SecondaryButton';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { Subtitle } from '@send-components/Subtitle';
import { Title } from '@send-components/Title';
import * as animations from '@send-animations';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { StaticImage } from '@send-components/StaticImage';
import { CenteredExpandedSection } from '@send-components/CenteredExpandedSection';
import { MobileSectionContainer } from '@send-components/MobileSectionContainer';
import { forwardParams } from '@sendpayments/js-utils/services/analytics/legacy';
import { ApplyTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import { VARIATION_THEME_COLOUR } from '@send-static/constants';
import { navigate } from 'gatsby';
import { breakpoints } from '@send-base/design-system';
import { DoubleCarousel } from '@sendpayments/react-shared/components/DoubleCarousel';
import styles from './TextList.module.less';
import { SectionLayout } from '../SectionLayout';

const TextList = ({ name, theme, bgColour, props, metadata, onOpenForm }) => {
  const {
    minHeight,
    heading,
    section,
    textSectionPosition,
    animation,
    list,
    underlineColour,
    getInTouchButton,
    registerButton,
    registerLinkParams,
    secondaryButtonLink,
    secondaryButtonText,
    id,
  } = props || {};

  const { isMedia } = useBreakpoint(breakpoints);
  const isMediaMobile = isMedia({ to: breakpoints.sm });

  const heroAnimation = animation.hero;

  const onSecondaryLinkClick = () => navigate(secondaryButtonLink);
  const onGetInTouch = () => onOpenForm({ name: 'ContactUs' });
  const onPortalSignUp = () => window.open(forwardParams(`${metadata.portalSignUp}${registerLinkParams ?? ''}`));

  const dataLayers = [
    heroAnimation.layer1 && { data: animations[heroAnimation.layer1.name], position: animations[heroAnimation.layer1.position] },
    heroAnimation.layer2 && { data: animations[heroAnimation.layer2.name], position: animations[heroAnimation.layer2.position] },
  ].filter(Boolean);

  const isPositionedLeft = textSectionPosition === 'left';

  return (
    <WebsiteTheme name={name} theme={theme}>
      {isMediaMobile && (
        <MobileSectionContainer>
          <CenteredExpandedSection>
            <Flex direction="column" alignItems="center">
              <View>
                <SectionLayout>
                  <Subtitle level="2" color="secondary" bold underlineColour={underlineColour}>
                    <SecondaryFont>
                      <HtmlText>{section}</HtmlText>
                    </SecondaryFont>
                  </Subtitle>
                </SectionLayout>
              </View>

              <View>
                <SectionLayout>
                  <Title level="1">
                    <SecondaryFont>{heading}</SecondaryFont>
                  </Title>
                </SectionLayout>
              </View>

              <SectionLayout>
                {list.map(({ image, text }) => (
                  <Flex key={text} direction="column" marginTop={sizes.size400}>
                    <Flex flex="1" justifyContent="center">
                      <StaticImage width={sizes.size2400} src={image} />
                    </Flex>
                    <View flex="2">
                      <Subtitle level="2" center lineHeight="36px">
                        <HtmlText>{text}</HtmlText>
                      </Subtitle>
                    </View>
                  </Flex>
                ))}
              </SectionLayout>

              <View marginTop={sizes.size500}>
                <SectionLayout>
                  {bgColour === VARIATION_THEME_COLOUR && (
                    <Flex gap={sizes.size130} justifyContent="center">
                      {getInTouchButton && (
                        <View justifyContent="center" alignSelf="center">
                          <ApplyTheme displayName="PrimaryHomepageButton">
                            <PrimaryButton onPress={onGetInTouch} isPrimaryHomeBtn>
                              Get in touch
                            </PrimaryButton>
                          </ApplyTheme>
                        </View>
                      )}
                      {registerButton && (
                        <View justifyContent="center" alignSelf="center">
                          <ApplyTheme displayName="PrimaryHomepageButton">
                            <PrimaryButton onPress={onPortalSignUp} isPrimaryHomeBtn>
                              Register now
                            </PrimaryButton>
                          </ApplyTheme>
                        </View>
                      )}
                      <View alignSelf="center">
                        <SecondaryFont>
                          <ApplyTheme displayName="SecondaryHomepageButton">
                            <SecondaryButton onPress={onSecondaryLinkClick}>{secondaryButtonText}</SecondaryButton>
                          </ApplyTheme>
                        </SecondaryFont>
                      </View>
                    </Flex>
                  )}
                  {bgColour !== VARIATION_THEME_COLOUR && (
                    <Flex gap={sizes.size130} justifyContent="center">
                      {getInTouchButton && (
                        <View justifyContent="center" alignSelf="center">
                          <PrimaryButton onPress={onGetInTouch}>Get in touch</PrimaryButton>
                        </View>
                      )}
                      {registerButton && (
                        <View justifyContent="center" alignSelf="center">
                          <PrimaryButton onPress={onPortalSignUp}>Register now</PrimaryButton>
                        </View>
                      )}
                      <View alignSelf="center">
                        <SecondaryFont>
                          <SecondaryButton onPress={onSecondaryLinkClick}>{secondaryButtonText}</SecondaryButton>
                        </SecondaryFont>
                      </View>
                    </Flex>
                  )}
                </SectionLayout>
              </View>
            </Flex>
          </CenteredExpandedSection>
        </MobileSectionContainer>
      )}

      {!isMediaMobile && (
        <SectionLayout className={styles.mobileContainer}>
          <Flex direction="row" minHeight={`${minHeight}px`}>
            <div style={{ order: (isPositionedLeft && 2) || 1, flex: 1 }}>
              {id === 'partnerships' ? (
                <div className={styles.carousel}>
                  <DoubleCarousel
                    verticalGap={55}
                    horizontalGap={50}
                    height={771}
                    leftImages={animation.hero.layer1.leftImages}
                    rightImages={animation.hero.layer1.rightImages}
                  />
                </div>
              ) : (
                <LayeredAnimation
                  name="hero"
                  height="771px"
                  width="580px"
                  dataLayers={dataLayers}
                  {...heroAnimation.configuration}
                />
              )}
            </div>
            <CenteredExpandedSection order={(isPositionedLeft && 1) || 2}>
              <Flex direction="column" gap={sizes.size350}>
                <View>
                  <SectionLayout>
                    <Subtitle level="2" center color="secondary" bold underlineColour={underlineColour}>
                      <SecondaryFont>
                        <HtmlText>{section}</HtmlText>
                      </SecondaryFont>
                    </Subtitle>
                  </SectionLayout>
                  <Flex justifyContent="center">
                    <div className={styles.title}>
                      <Title level="3" lineHeight="1.4">
                        <SecondaryFont>{heading}</SecondaryFont>
                      </Title>
                    </div>
                  </Flex>
                </View>
                <Flex direction="column" gap={sizes.size300}>
                  {list.map(({ image, text }) => (
                    <Flex key={text}>
                      <Flex flex="1" justifyContent="center">
                        <View alignSelf="center">
                          <StaticImage src={image} />
                        </View>
                      </Flex>
                      <Flex justifyContent="center" flex="2">
                        <View alignSelf="center">
                          <Subtitle level="2" lineHeight="1.5" weight="300">
                            <HtmlText>{text}</HtmlText>
                          </Subtitle>
                        </View>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>

                <View marginTop={sizes.size500}>
                  {bgColour === VARIATION_THEME_COLOUR && (
                    <Flex gap={sizes.size130} justifyContent="center">
                      {getInTouchButton && (
                        <View justifyContent="center" alignSelf="center">
                          <ApplyTheme displayName="PrimaryHomepageButton">
                            <PrimaryButton onPress={onGetInTouch} isPrimaryHomeBtn>
                              Get in touch
                            </PrimaryButton>
                          </ApplyTheme>
                        </View>
                      )}
                      {registerButton && (
                        <View justifyContent="center" alignSelf="center">
                          <ApplyTheme displayName="PrimaryHomepageButton">
                            <PrimaryButton onPress={onPortalSignUp} isPrimaryHomeBtn>
                              Register now
                            </PrimaryButton>
                          </ApplyTheme>
                        </View>
                      )}
                      <View alignSelf="center">
                        <SecondaryFont>
                          <ApplyTheme displayName="SecondaryHomepageButton">
                            <SecondaryButton onPress={onSecondaryLinkClick}>{secondaryButtonText}</SecondaryButton>
                          </ApplyTheme>
                        </SecondaryFont>
                      </View>
                    </Flex>
                  )}
                  {bgColour !== VARIATION_THEME_COLOUR && (
                    <Flex gap={sizes.size130} justifyContent="center">
                      {getInTouchButton && (
                        <View justifyContent="center" alignSelf="center">
                          <PrimaryButton onPress={onGetInTouch}>Get in touch</PrimaryButton>
                        </View>
                      )}
                      {registerButton && (
                        <View justifyContent="center" alignSelf="center">
                          <PrimaryButton onPress={onPortalSignUp}>Register now</PrimaryButton>
                        </View>
                      )}
                      <View alignSelf="center">
                        <SecondaryFont>
                          <SecondaryButton onPress={onSecondaryLinkClick}>{secondaryButtonText}</SecondaryButton>
                        </SecondaryFont>
                      </View>
                    </Flex>
                  )}
                </View>
              </Flex>
            </CenteredExpandedSection>
          </Flex>
        </SectionLayout>
      )}
    </WebsiteTheme>
  );
};

export default TextList;
