import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { sizes, staticSizes } from '@sendpayments/react-shared/design-system';
import { breakpoints } from '@send-base/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { ActionIcon } from '@sendpayments/react-shared/components/ActionIcon';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { Flex, View } from '@adobe/react-spectrum';
import { SecondaryFont } from '@send-components/SecondaryFont';
import Close from '@spectrum-icons/workflow/Close';
import { withPrefix } from 'gatsby';
import styles from './Modal.module.less';

const getBackgroundImage = ({ isMediaSm, bg }) => {
  if (isMediaSm) {
    if (bg.mobileBgImage !== undefined) {
      return bg.mobileBgImage;
    }

    return bg.bgImage;
  }

  return bg.bgImage;
};

const getBackgroundColor = ({ isMediaSm, bg }) => {
  if (isMediaSm) {
    if (bg.mobileBgColor !== undefined) {
      return bg.mobileBgColor;
    }

    return bg.bgColour;
  }

  return bg.bgColour;
};

const Modal = ({
  children,
  onClose,
  onSubmit,
  footerText,
  showFooter,
  showSubmit,
  showClose,
  submitText,
  name,
  theme,
  isSubmitDisabled,
  bg,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const { isMedia } = useBreakpoint(breakpoints);
  const isMediaSm = isMedia({ to: breakpoints.sm });

  const bgImage = getBackgroundImage({ isMediaSm, bg });
  const bgImageUrl = bgImage && withPrefix(`/images/${bgImage}`);

  return (
    <div
      className={styles.menuOverlay}
      style={{
        backgroundImage: bg.bgImage && `url(${bgImageUrl})`,
        backgroundPosition: bg.bgImage && 'center',
        backgroundSize: bg.bgImage && 'cover',
        backgroundColor: getBackgroundColor({ isMediaSm, bg }),
      }}
    >
      <WebsiteTheme name={`Modal_${name}`} theme={theme}>
        <Flex direction="column" justifyContent="space-between" marginX={sizes.size300} height="100%">
          <View alignSelf="flex-end" position="absolute" marginTop={sizes.size300} marginEnd={sizes.size50}>
            {showClose && (
              <ActionIcon onPress={onClose}>
                <Close size="M" />
              </ActionIcon>
            )}
          </View>
          <Flex
            marginTop={isMediaSm && sizes.size800}
            direction="column"
            flex="1"
            justifyContent="center"
            alignSelf="center"
            alignItems="center"
          >
            <View alignSelf="center">{children}</View>
            {showSubmit && (
              <View marginTop={sizes.size200} marginBottom={sizes.size200}>
                <div className={styles.buttonContainer}>
                  <PrimaryActionButton
                    onPress={onSubmit}
                    isDisabled={isSubmitDisabled}
                    UNSAFE_className={styles.primaryActionButton}
                  >
                    {submitText}
                    <ChevronRight size="M" />
                  </PrimaryActionButton>
                </div>
              </View>
            )}
          </Flex>
          <View alignSelf="center" height={staticSizes.size1000}>
            {showFooter && (
              <div className={styles.footer}>
                <SecondaryFont>{footerText}</SecondaryFont>
              </div>
            )}
          </View>
        </Flex>
      </WebsiteTheme>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  footerText: PropTypes.string,
  submitText: PropTypes.string,
  name: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  bg: PropTypes.object.isRequired,
  isSubmitDisabled: PropTypes.bool,
  showFooter: PropTypes.bool,
  showSubmit: PropTypes.bool,
  showClose: PropTypes.bool,
};

Modal.defaultProps = {
  footerText: undefined,
  submitText: undefined,
  isSubmitDisabled: false,
  showFooter: true,
  showSubmit: true,
  showClose: true,
};

export default Modal;
