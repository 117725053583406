import React from 'react';
import { Heading } from '@adobe/react-spectrum';

const JustHeading = ({ children, ...props }) => (
  <Heading UNSAFE_style={{ margin: 0 }} {...props}>
    {children}
  </Heading>
);

export default JustHeading;
