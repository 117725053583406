import React, { useRef, useState } from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { breakpoints } from '@send-base/design-system';
import { Flex, View, Form, TextField, Picker, Item } from '@adobe/react-spectrum';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import styles from './Form.module.less';

const showError = (field) => {
  if (field.isTouched) {
    return field.isValid ? 'valid' : 'invalid';
  }
  return undefined;
};

const reasonsList = ['New partnership', 'Existing partnership', 'Affiliate or referrer', 'Support', 'Something else'];

const JoinUsForm = ({
  formState: {
    fields: { firstName, lastName, email, position },
    isSubmitted,
  },
  onFieldChange,
}) => {
  const { isMedia } = useBreakpoint(breakpoints);

  const isMediaSmall = isMedia({ to: breakpoints.sm });
  const hiddenFileInput = useRef(null);
  const FileChange = () => useState()[1];

  return (
    <Form>
      <div className={styles.formContainer}>
        <Flex gap={!isMediaSmall && sizes.size400} direction={isMediaSmall && 'column'}>
          <View flex="1">
            <TextField
              validationState={showError(firstName)}
              label="First Name"
              defaultValue={firstName.value}
              onChange={(value) => onFieldChange('firstName', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your first name…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            />
          </View>
          <View flex="1">
            <TextField
              id="lastName"
              validationState={showError(lastName)}
              label="Last name"
              defaultValue={lastName.value}
              onChange={(value) => onFieldChange('lastName', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your last name…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            />
          </View>
        </Flex>
        <Flex gap={!isMediaSmall && sizes.size400} direction={isMediaSmall && 'column'}>
          <View flex="1">
            <TextField
              id="email"
              validationState={showError(email)}
              type="email"
              label="Email"
              defaultValue={email.value}
              onChange={(value) => onFieldChange('email', value)}
              isDisabled={isSubmitted}
              placeholder="Please enter your email address…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            />
          </View>
          <View flex="1">
            <Picker
              id="position"
              label="Position you're interested in"
              necessityIndicator="label"
              value={position.value}
              onSelectionChange={(value) => onFieldChange('position', value)}
              isDisabled={isSubmitted}
              placeholder="Please select the position…"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            >
              {reasonsList.map((r) => (
                <Item key={r}>{r}</Item>
              ))}
            </Picker>
          </View>
        </Flex>
        <Flex gap={!isMediaSmall && sizes.size400} direction={isMediaSmall && 'column'}>
          <View flex="1">
            <TextField
              id="portfolio"
              label="Upload CV/portfolio or cover letter"
              onChange={FileChange()}
              onFocus={() => hiddenFileInput.current.click()}
              isDisabled={isSubmitted}
              placeholder="Click to upload your files"
              height={(!isMediaSmall && sizes.size1200) || sizes.size900}
              width="100%"
            />
          </View>
          <View flex="1">
            <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={FileChange()} />
          </View>
        </Flex>
      </div>
    </Form>
  );
};

export default JoinUsForm;
