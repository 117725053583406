import React, { useState, useEffect, useRef } from 'react';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { Title } from '@send-components/Title';
import { Subtitle } from '@send-components/Subtitle';
import { Flex, View } from '@adobe/react-spectrum';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { StaticImage } from '@send-components/StaticImage';
import { useIsVisible } from 'react-is-visible';
import { MobileSectionContainer } from '@send-components/MobileSectionContainer';
import { breakpoints } from '@send-base/design-system';
import { SectionLayout } from '../SectionLayout';
import styles from './Callout.module.less';

const sectionHeight = '800px';
const sectionHeightMobile = '500px';

const Item = ({ bottomLayerImage }) => {
  return (
    <div className={styles.imageContainer}>
      <div className={styles.fillContainer} style={{ height: '100%' }}>
        <StaticImage src={bottomLayerImage} />
      </div>
    </div>
  );
};

const Section = ({ name, theme, info, props }) => {
  const { isLeftLayout, listIcon, list, heading, underlineColour, isAnimationRight } = props;
  const [openAnimationIndex, setOpenAnimationIndex] = useState(0);
  const nodeRef1 = useRef();
  const nodeRef2Start = useRef();
  const nodeRef2 = useRef();
  const nodeRef3 = useRef();
  const nodeEndRef = useRef();
  const isVisibleText1 = useIsVisible(nodeRef1);
  const isVisibleText2 = useIsVisible(nodeRef2);
  const isVisibleText3 = useIsVisible(nodeRef3);

  const item1 = list[0];
  const item2 = list[1];
  const item3 = list[2];

  const icon1 = listIcon[0];
  const icon2 = listIcon[1];

  const [calloutOpacityText, setOpacityText] = useState([1, 0, 0]);

  const [calloutListPosition, setCallOutListPosition] = useState('0%');
  const [calloutProgessBarPosition, setCalloutProgessBarPosition] = useState('10%');
  const [calloutListPhonePosition, setCallOutListPhonePosition] = useState('0%');

  const [calloutPhoneImg, setCallOutPhoneImg] = useState(item1.bottomLayerImage);

  useEffect(() => {
    if (isVisibleText1 && openAnimationIndex !== 0) {
      setOpenAnimationIndex(0);
    } else if (isVisibleText1 && openAnimationIndex === 1) {
      setOpenAnimationIndex(0);
    } else if (isVisibleText2 && openAnimationIndex === 0) {
      setOpenAnimationIndex(1);
    } else if (isVisibleText2 && openAnimationIndex === 2) {
      setOpenAnimationIndex(1);
    } else if (isVisibleText3 && openAnimationIndex !== 2) {
      setOpenAnimationIndex(2);
    }
  }, [isVisibleText1, isVisibleText2, isVisibleText3]);

  useEffect(() => {
    if (isVisibleText1 && !isVisibleText2 && !isVisibleText3) {
      setOpacityText([1, 0, 0]);
      setCallOutListPosition('0%');
      setCalloutProgessBarPosition('10%');
      setCallOutListPhonePosition('0%');
      setCallOutPhoneImg(item1.bottomLayerImage);
    } else if (!isVisibleText1 && isVisibleText2 && !isVisibleText3) {
      setOpacityText([0, 1, 0]);
      setCallOutListPosition('43%');
      setCalloutProgessBarPosition('46%');
      setCallOutListPhonePosition('37%');
      setCallOutPhoneImg(item2.bottomLayerImage);
    } else if (!isVisibleText1 && !isVisibleText2 && isVisibleText3) {
      setOpacityText([0, 0, 1]);
      setCallOutListPosition('100%');
      setCalloutProgessBarPosition('78%');
      setCallOutListPhonePosition('70%');
      setCallOutPhoneImg(item3.bottomLayerImage);
    }
  }, [isVisibleText1, isVisibleText2, isVisibleText3]);

  const { isMedia } = useBreakpoint(breakpoints);
  const isMediaSmall = isMedia({ to: breakpoints.sm });

  const opacityText = (position) => {
    const opacityChanges = {
      opacity: calloutOpacityText[position],
      transition: 'opacity 0.5s',
    };

    return opacityChanges;
  };

  const progressBarPosition = () => (!isLeftLayout ? 'row-reverse' : 'row');
  const callOutImagesMobilewidth = 264;

  return (
    <WebsiteTheme name={name} theme={theme}>
      {isMediaSmall && (
        <MobileSectionContainer style={{ paddingBottom: '120px' }}>
          <Flex alignItems="center" justifyContent="center">
            <div className={styles.title}>
              <Title center underlineColour={underlineColour}>
                <SecondaryFont>
                  <HtmlText>{heading}</HtmlText>
                </SecondaryFont>
              </Title>
            </div>
          </Flex>

          <Flex>
            <View flex="1">
              <div style={{ height: sectionHeightMobile }}>
                <Flex direction="column" height="100%">
                  <View alignSelf="center" height="100%" width={callOutImagesMobilewidth}>
                    <Item bottomLayerImage={item1.bottomLayerImage} />
                  </View>
                </Flex>
              </div>
              <SectionLayout height="unset" className={styles.phoneTitleContainer}>
                <Subtitle>
                  <SecondaryFont>
                    <HtmlText>{item1.text}</HtmlText>
                  </SecondaryFont>
                </Subtitle>
              </SectionLayout>
              <div style={{ height: sectionHeightMobile }}>
                <Flex direction="column" height="100%">
                  <View alignSelf="center" height="100%" width={callOutImagesMobilewidth}>
                    <Item bottomLayerImage={item2.bottomLayerImage} />
                  </View>
                </Flex>
              </div>
              <SectionLayout height="unset" className={styles.phoneTitleContainer}>
                <Subtitle>
                  <SecondaryFont>
                    <HtmlText>{item2.text}</HtmlText>
                  </SecondaryFont>
                </Subtitle>
              </SectionLayout>
              <div style={{ height: sectionHeightMobile }}>
                <Flex direction="column" height="90%">
                  <View alignSelf="center" height="100%" width={callOutImagesMobilewidth}>
                    <Item bottomLayerImage={item3.bottomLayerImage} />
                  </View>
                </Flex>
              </div>
              <SectionLayout className={styles.phoneTitleContainer}>
                <Subtitle>
                  <SecondaryFont>
                    <HtmlText>{item3.text}</HtmlText>
                  </SecondaryFont>
                </Subtitle>
              </SectionLayout>
            </View>
          </Flex>
        </MobileSectionContainer>
      )}
      {!isMediaSmall && (
        <SectionLayout>
          <MobileSectionContainer>
            <Flex alignItems="center" justifyContent="center">
              <div className={styles.title}>
                <Title center underlineColour={underlineColour}>
                  <SecondaryFont>
                    <HtmlText>{heading}</HtmlText>
                  </SecondaryFont>
                </Title>
              </div>
            </Flex>

            <Flex direction="row">
              <div style={{ order: isAnimationRight && 1, flex: 1 }}>
                <View flex="1">
                  <div style={{ height: '800px' }}>
                    <Flex direction="column" justifyContent="center" height="100%">
                      <View alignSelf="center">
                        <Subtitle>
                          <div ref={nodeRef1}>
                            <div style={opacityText(0)}>
                              <SecondaryFont>
                                <HtmlText>{item1.text}</HtmlText>
                              </SecondaryFont>
                            </div>
                          </div>
                        </Subtitle>
                      </View>
                    </Flex>
                  </div>

                  <div style={{ height: sectionHeight }}>
                    <Flex direction="column" justifyContent="center" height="100%">
                      <View alignSelf="center">
                        <div ref={nodeRef2Start}>
                          <Subtitle>
                            <div style={opacityText(1)}>
                              <SecondaryFont>
                                <HtmlText>{item2.text}</HtmlText>
                              </SecondaryFont>
                            </div>
                          </Subtitle>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <div ref={nodeRef2} style={{ position: 'absolute', width: '300px' }} />
                        </div>
                      </View>
                    </Flex>
                    <div style={{ position: 'relative' }}>
                      <div ref={nodeEndRef} style={{ position: 'absolute', width: '300px', top: '-130px' }} />
                    </div>
                  </div>

                  <div style={{ height: '650px' }}>
                    <Flex direction="column" justifyContent="center" height="100%">
                      <View alignSelf="center">
                        <Subtitle>
                          <div style={opacityText(2)}>
                            <SecondaryFont>
                              <HtmlText>{item3.text}</HtmlText>
                            </SecondaryFont>
                          </div>
                        </Subtitle>
                        <div style={{ position: 'relative' }}>
                          <div ref={nodeRef3} style={{ position: 'absolute', width: '300px', top: '130px' }} />
                        </div>
                      </View>
                    </Flex>
                  </div>
                </View>
              </div>

              <View flex="1">
                <Flex direction="column" justifyContent="center" height="100%">
                  <div className={styles.stickyContainerLeft} style={{ flexDirection: progressBarPosition() }}>
                    <div className={styles.progressBarStyle} style={{ top: calloutProgessBarPosition }}>
                      <div className={styles.progressBarIndicator} style={{ top: calloutListPosition }}>
                        <div className={styles.progressBarInnerIndicator} style={{ backgroundColor: info.bgColour }} />
                      </div>
                    </div>

                    <div className={styles.phoneSizeContainer} style={{ top: calloutListPhonePosition }}>
                      <div className={styles.redSVGIcon} style={listIcon[0].position}>
                        <StaticImage src={icon1.bottomLayerImage} />
                      </div>

                      <StaticImage src={calloutPhoneImg} />

                      <div className={styles.blueSVGIcon} style={listIcon[1].position}>
                        <StaticImage src={icon2.bottomLayerImage} />
                      </div>
                    </div>
                  </div>
                </Flex>
              </View>
            </Flex>
          </MobileSectionContainer>
        </SectionLayout>
      )}
    </WebsiteTheme>
  );
};

export default Section;
