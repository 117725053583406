import React from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { PrimaryButton } from '@sendpayments/react-shared/components/buttons/PrimaryButton';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import { AnimationTransition } from '@sendpayments/react-shared/components/AnimationTransition';
import { Flex } from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import styles from './CookieBar.module.less';
import { BaseLayout } from '../../layouts/BaseLayout';
import { StaticImage } from '../StaticImage';

const CookieBar = ({ onAcceptCookie, isAccepted, height }) => {
  return (
    <AnimationTransition
      startClassName={styles.wrapperHidden}
      endClassName={styles.wrapper}
      applyTransition="visibility 0s linear 0.3s, opacity 0.3s linear 0s"
      isEnabled={!isAccepted}
    >
      <div style={{ height }}>
        <WebsiteTheme name="CookieBar" theme="LIGHT">
          <div className={styles.container} style={{ height }}>
            <BaseLayout>
              <Flex alignItems="center">
                <Flex flexGrow="1" gap={sizes.size115} alignItems="center">
                  <StaticImage src="cookie.svg" width={sizes.size250} />
                  We use cookies to improve your experience.
                </Flex>
                <PrimaryButton height="auto" onPress={onAcceptCookie}>
                  Accept
                </PrimaryButton>
              </Flex>
            </BaseLayout>
          </div>
        </WebsiteTheme>
      </div>
    </AnimationTransition>
  );
};

CookieBar.propTypes = {
  isAccepted: PropTypes.bool,
  onAcceptCookie: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
};

CookieBar.defaultProps = {
  isAccepted: false,
};

CookieBar.displayName = 'CookieBar';

export default withTheme(CookieBar);
