import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useIsVisible } from 'react-is-visible';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { breakpoints } from '@send-base/design-system';
import styles from './ParallaxLayer.module.less';
import { StaticImage } from '../StaticImage';

const step = 5; // 5px

const ParallaxItem = ({ shapeSrc, width, height, top, left, right, bottom, movementPx, speed }) => {
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [position, setPosition] = useState(0);

  const { isMedia } = useBreakpoint(breakpoints);
  const isMediaSm = isMedia({ to: breakpoints.sm });

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (isVisible) {
        const isGrowing = Math.abs(currPos.y) > Math.abs(prevPos.y);

        const value = Math.abs(currPos.y) - Math.abs(prevPos.y);

        if (isGrowing && position <= movementPx) {
          setPosition(position + (value > 0 ? step : -step) * speed);
        } else if (!isGrowing && position > 0) {
          setPosition(position + (value > 0 ? step : -step) * speed);
        }
      }
    },
    [isVisible, position],
  );

  return (
    !isMediaSm && (
      <div className={styles.parallaxLayerItem} style={{ top, left, right, bottom, transform: `translateY(-${position}px)` }}>
        <StaticImage src={shapeSrc} width={width} height={height} />
        <div ref={nodeRef} />
      </div>
    )
  );
};

const ParallaxLayer = ({ layers, children, theme }) => {
  return (
    <div className={styles.container}>
      {layers.map((layer) => (
        <ParallaxItem key={layer.shapeSrc} theme={theme} {...layer} />
      ))}
      {children}
    </div>
  );
};

ParallaxLayer.propTypes = {
  layers: PropTypes.array,
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
};

ParallaxLayer.defaultProps = {
  layers: [],
};

export default withTheme(ParallaxLayer);
