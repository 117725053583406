import img0 from '@send-images/animations/feature_home_2_img_0.png';
import img1 from '@send-images/animations/feature_home_2_img_1.png';
import img2 from '@send-images/animations/feature_home_2_img_2.png';
import img3 from '@send-images/animations/feature_home_2_img_3.png';
import img4 from '@send-images/animations/feature_home_2_img_4.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 100,
  w: 677,
  h: 510,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 94, h: 94, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 151, h: 86, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 151, h: 86, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 151, h: 86, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 151, h: 86, u: '', p: img4, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Transfer.pdf',
          cl: 'pdf',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [187, 114.5, 0], ix: 2 },
            a: { a: 0, k: [47, 36.101, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 31,
                  s: [62, 62, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 34,
                  s: [43, 43, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 37,
                  s: [67, 67, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 39,
                  s: [62, 62, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 80,
                  s: [62, 62, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 83,
                  s: [43, 43, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 86,
                  s: [67, 67, 100],
                },
                { t: 88, s: [62, 62, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'GBP.pdf',
          cl: 'pdf',
          parent: 4,
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 23, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 24, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 73, s: [100] },
                { t: 74, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 41.856, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'Aus.pdf',
          cl: 'pdf',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 15,
                  s: [105, 124.5, 0],
                  to: [0, -6.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 23,
                  s: [105, 84.5, 0],
                  to: [0, 0, 0],
                  ti: [0, -6.667, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 30, s: [105, 124.5, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 65,
                  s: [105, 124.5, 0],
                  to: [0, 6.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 73,
                  s: [105, 164.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 6.667, 0],
                },
                { t: 80, s: [105, 124.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'GBP.pdf',
          cl: 'pdf',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 15,
                  s: [105, 124.5, 0],
                  to: [0, 6.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 23,
                  s: [105, 164.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 6.667, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 30, s: [105, 124.5, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 65,
                  s: [105, 124.5, 0],
                  to: [0, -6.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 73,
                  s: [105, 84.5, 0],
                  to: [0, 0, 0],
                  ti: [0, -6.667, 0],
                },
                { t: 80, s: [105, 124.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'EUR.pdf',
          cl: 'pdf',
          parent: 7,
          refId: 'image_3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 93, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 94, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 99, s: [100] },
                { t: 100, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 43, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'USD.pdf',
          cl: 'pdf',
          parent: 8,
          refId: 'image_4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 43, s: [0] },
                { t: 44, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.5, 43, 0], ix: 2 },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: 'EUR.pdf',
          cl: 'pdf',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 93, s: [100] },
                { t: 94, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 35,
                  s: [277, 124.5, 0],
                  to: [0, 6.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 43,
                  s: [277, 164.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 6.667, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 50, s: [277, 124.5, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 85,
                  s: [277, 124.5, 0],
                  to: [0, -6.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 93,
                  s: [277, 84.5, 0],
                  to: [0, 0, 0],
                  ti: [0, -6.667, 0],
                },
                { t: 100, s: [277, 124.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: 'USD.pdf',
          cl: 'pdf',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 43, s: [100] },
                { t: 44, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 35,
                  s: [277, 124.5, 0],
                  to: [0, -6.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 43,
                  s: [277, 84.5, 0],
                  to: [0, 0, 0],
                  ti: [0, -6.667, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 50, s: [277, 124.5, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 85,
                  s: [277, 124.5, 0],
                  to: [0, 6.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 93,
                  s: [277, 164.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 6.667, 0],
                },
                { t: 100, s: [277, 124.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [75.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Pre-comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [338.5, 255, 0], ix: 2 },
        a: { a: 0, k: [187, 115.5, 0], ix: 1 },
        s: { a: 0, k: [179, 179, 100], ix: 6 },
      },
      ao: 0,
      w: 374,
      h: 231,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
