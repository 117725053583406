import img0 from '@send-images/animations/feature_personal_1_img_0.png';
import img1 from '@send-images/animations/feature_personal_1_img_1.png';
import img2 from '@send-images/animations/feature_personal_1_img_2.png';
import img3 from '@send-images/animations/feature_personal_1_img_3.png';
import img4 from '@send-images/animations/feature_personal_1_img_4.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 100,
  w: 650,
  h: 510,
  nm: 'Comp 1.1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 78, h: 78, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 356, h: 211, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 337, h: 211, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 601, h: 156, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 601, h: 156, u: '', p: img4, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'img_0.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 62, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 64, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 97, s: [100] },
                { t: 106, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [320.25, 175, 0], ix: 2 },
            a: { a: 0, k: [39, 39, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 62,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 66,
                  s: [29, 29, 100],
                },
                { t: 69, s: [24, 24, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 62,
          op: 106,
          st: -7,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 1, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 14, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 65, s: [100] },
                { t: 66, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [243, 252.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.5, -21.033],
                        [-28.25, -21.033],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.945098102093, 0.92156869173, 0.309803932905, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 25, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 1,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.823528992896, 0.827450980392, 0.788234994926, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [99] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 10, s: [72.346] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 13, s: [79.635] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 29, s: [43.23] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 32, s: [41.404] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 40, s: [23.29] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 44, s: [22.939] },
                  { t: 62, s: [0] },
                ],
                ix: 1,
              },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 1, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 14, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 97, s: [100] },
                { t: 106, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [243.5, 196.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [76.75, -21],
                        [-28.25, -21],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.454901993275, 0.913725554943, 0.494117677212, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 25, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 1,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.823528992896, 0.827450980392, 0.788234994926, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [99] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 47, s: [16.564] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 51, s: [18.371] },
                  { t: 62, s: [0] },
                ],
                ix: 1,
              },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'Front Cracked',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 69, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 79, s: [100] },
                { t: 97, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 66, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 70, s: [-8] },
                { t: 97, s: [-8] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 66,
                  s: [187, 236, 0],
                  to: [0.167, 0.833, 0],
                  ti: [-0.167, -0.833, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 70, s: [188, 241, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                { t: 108, s: [188, 241, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [178, 105.5, 0], ix: 1 },
            s: { a: 0, k: [36, 36, 100], ix: 6 },
          },
          ao: 0,
          ip: 66,
          op: 110,
          st: -23,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'BackCracked',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 69, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 79, s: [100] },
                { t: 97, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 66, s: [0] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 70, s: [27] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 75, s: [19] },
                { t: 79, s: [27] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 66,
                  s: [292, 236, 0],
                  to: [2.167, 3.833, 0],
                  ti: [-3.333, -3.833, 0],
                },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 70, s: [305, 259, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 75, s: [305, 255, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                { t: 79, s: [305, 259, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [168.5, 105.5, 0], ix: 1 },
            s: { a: 0, k: [36, 36, 100], ix: 6 },
          },
          ao: 0,
          ip: 66,
          op: 110,
          st: -23,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'img_4.png',
          cl: 'png',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [243, 177, 0], ix: 2 },
            a: { a: 0, k: [300.5, 78, 0], ix: 1 },
            s: { a: 0, k: [36, 36, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: 'img_5.png',
          cl: 'png',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 96, s: [0] },
                { t: 109, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [243, 232, 0], ix: 2 },
            a: { a: 0, k: [300.5, 78, 0], ix: 1 },
            s: { a: 0, k: [36, 36, 100], ix: 6 },
          },
          ao: 0,
          ip: 78,
          op: 125,
          st: 52,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: 'img_5.png',
          cl: 'png',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [243, 232, 0], ix: 2 },
            a: { a: 0, k: [300.5, 78, 0], ix: 1 },
            s: { a: 0, k: [36, 36, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 67,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -5, ix: 10 },
        p: { a: 0, k: [332, 248, 0], ix: 2 },
        a: { a: 0, k: [243, 197, 0], ix: 1 },
        s: { a: 0, k: [243, 243, 100], ix: 6 },
      },
      ao: 0,
      w: 486,
      h: 394,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
