import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const queryImages = graphql`
  query ImageQuery {
    allImageSharp {
      nodes {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
        parent {
          ... on File {
            relativePath
          }
        }
      }
    }
  }
`;

const findImageSharp = (queryResult, src) => {
  return queryResult.allImageSharp.nodes.find((node) => {
    return node.parent?.relativePath?.endsWith(src);
  });
};

const useFluidImageQuery = (src, colorScheme) => {
  const data = useStaticQuery(queryImages);

  const srcWithTheme = useMemo(() => (colorScheme ? `${colorScheme}/${src}` : src), [colorScheme, src]);
  const imageFluid = useMemo(() => {
    let imgSharp = findImageSharp(data, srcWithTheme);
    if (!imgSharp) {
      imgSharp = findImageSharp(data, src);
    }
    return imgSharp?.fluid;
  }, [data, srcWithTheme]);

  return imageFluid;
};

export default useFluidImageQuery;
