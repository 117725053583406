import React, { useState, useEffect, useRef } from 'react';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { sizes, staticSizes } from '@sendpayments/react-shared/design-system';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { Title } from '@send-components/Title';
import { Subtitle } from '@send-components/Subtitle';
import { Flex, View } from '@adobe/react-spectrum';
import AnchorSelect from '@spectrum-icons/workflow/AnchorSelect';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import PublishPending from '@spectrum-icons/workflow/PublishPending';
import Globe from '@spectrum-icons/workflow/Globe';
import Refresh from '@spectrum-icons/workflow/Refresh';
import ClockCheck from '@spectrum-icons/workflow/ClockCheck';
import Targeted from '@spectrum-icons/workflow/Targeted';
import Ribbon from '@spectrum-icons/workflow/Ribbon';
import GraphBarVertical from '@spectrum-icons/workflow/GraphBarVertical';
import JourneyData from '@spectrum-icons/workflow/JourneyData';
import Engagement from '@spectrum-icons/workflow/Engagement';
import GlobeOutline from '@spectrum-icons/workflow/GlobeOutline';
import Money from '@spectrum-icons/workflow/Money';
import Attributes from '@spectrum-icons/workflow/Attributes';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { AnimationTransition } from '@sendpayments/react-shared/components/AnimationTransition';
import { LayeredAnimation } from '@sendpayments/react-shared/components/LayeredAnimation';
import * as animations from '@send-animations';
import cn from 'class-names';
import { breakpoints } from '@send-base/design-system';
import styles from './Feature.module.less';

const availableIcons = {
  AnchorSelect,
  PublishPending,
  ClockCheck,
  Globe,
  Refresh,
  Ribbon,
  Targeted,
  GraphBarVertical,
  JourneyData,
  Engagement,
  GlobeOutline,
  Money,
  Attributes,
};

const Item = ({ icon, title, text, isExpanded, onClick, bgColour }) => {
  const Icon = availableIcons[icon];
  const rootRef = useRef();
  const contentRef = useRef();

  const { isMedia } = useBreakpoint(breakpoints);
  const isMediaMobile = isMedia({ to: breakpoints.sm });

  useEffect(() => {
    if (!!rootRef && !!contentRef) {
      if (isExpanded) {
        rootRef.current.style.maxHeight = `${(isMediaMobile ? 300 : 100) + contentRef.current.scrollHeight}px`;
      } else {
        rootRef.current.style.maxHeight = isMediaMobile ? '100px' : '74px';
      }
    }
  }, [isExpanded]);

  return (
    <div
      ref={rootRef}
      className={cn(styles.item, !isExpanded && styles.itemCollapsedHover)}
      onClick={onClick}
      style={{ backgroundColor: isExpanded ? bgColour : 'transparent' }}
    >
      <Flex direction="column">
        <Flex direction="row" alignItems="center" height={isMediaMobile ? 'auto' : sizes.size900}>
          <View flex={1}>
            <Flex direction="row" justifyContent="center" alignItems="center">
              <Icon size="M" />
            </Flex>
          </View>
          <View
            flex={isMediaMobile ? 3 : 6}
            paddingEnd={isMediaMobile ? sizes.size200 : sizes.size0}
            paddingY={isMediaMobile ? sizes.size100 : sizes.size0}
          >
            <Flex direction="row" alignItems="center" gap={sizes.size100}>
              <Title level={5} lineHeight="1.5" leftAlign={isMediaMobile}>
                <SecondaryFont>
                  <HtmlText>{title}</HtmlText>
                </SecondaryFont>
              </Title>
              <AnimationTransition
                isEnabled={isExpanded}
                startClassName={styles.arrowDown}
                endClassName={styles.arrowUp}
                applyTransition="transform 0.5s ease-in-out"
              >
                <ChevronDown size="M" />
              </AnimationTransition>
            </Flex>
          </View>
        </Flex>
        <div ref={contentRef} className={styles.content}>
          <View flex={1} />
          <View flex={isMediaMobile ? 3 : 6} paddingEnd={staticSizes.size300} paddingBottom={staticSizes.size300}>
            <AnimationTransition
              startClassName={styles.notVisible}
              endClassName={styles.visible}
              applyTransition="opacity 0.5s"
              isEnabled={isExpanded}
              delayMs="200"
            >
              <Flex direction="row" alignItems="center">
                <Subtitle level="4" leftAlign>
                  <HtmlText>{text}</HtmlText>
                </Subtitle>
              </Flex>
            </AnimationTransition>
          </View>
        </div>
      </Flex>
    </div>
  );
};

const Section = ({ name, theme, props }) => {
  const { minHeight, list, heading, reverse, animation, underlineColour } = props;
  const [openIndex, setOpenIndex] = useState(0);
  const isOpen = (currentIndex) => {
    return openIndex === currentIndex;
  };

  const { isMedia } = useBreakpoint(breakpoints);
  const isMediaMobile = isMedia({ to: breakpoints.sm });
  const isMediaMd = isMedia({ to: breakpoints.md });

  const getContentPos = () => {
    if (isMediaMobile) {
      return 'center';
    }
    if (reverse) {
      return 'start';
    }
    return 'end';
  };

  const getFlexDirection = () => {
    if (isMediaMobile) {
      return 'column-reverse';
    }

    if (reverse) {
      return 'row-reverse';
    }

    return 'row';
  };

  const getAnimationWidthAndHeight = () => {
    if (isMediaMd) {
      return {
        animationWidth: '100%',
        animationHeight: '420px',
        columnGap: '0px',
      };
    }

    return {
      animationWidth: '584px',
      animationHeight: '520px',
      columnGap: sizes.size300,
    };
  };
  const { animationWidth, animationHeight, animationColumnGap } = getAnimationWidthAndHeight();

  const heroAnimation = animation?.hero;

  const dataLayers = heroAnimation
    ? Object.keys(heroAnimation)
        .map((ele, key) => {
          if (key === openIndex && ele.includes('layer')) {
            return {
              data: animations[heroAnimation[ele].name],
              position: animations[heroAnimation[ele].position],
            };
          }
          return false;
        })
        .filter(Boolean)
    : [];

  return (
    <WebsiteTheme name={name} theme={theme}>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        minHeight={!isMediaMobile ? `${minHeight}px` : '0px'}
      >
        <View marginBottom={isMediaMobile ? staticSizes.size0 : staticSizes.size1000}>
          {heading && (
            <Title level={2} lineHeight="1.5" center underlineColour={underlineColour}>
              <SecondaryFont>
                <HtmlText>{heading}</HtmlText>
              </SecondaryFont>
            </Title>
          )}
        </View>
        <Flex
          direction={getFlexDirection()}
          width="100%"
          justifyContent="center"
          alignItems="center"
          columnGap={animationColumnGap}
        >
          <Flex direction="column" alignItems={getContentPos()}>
            {list.map((item, index) => (
              <Item
                index={index}
                key={index}
                isExpanded={isOpen(index)}
                onClick={() => setOpenIndex(index)}
                bgColor={item.bgColour}
                {...item}
              />
            ))}
          </Flex>

          <View width={animationWidth} height={animationHeight}>
            {dataLayers.length > 0 && (
              <LayeredAnimation
                name="hero"
                height={animationHeight}
                width={animationWidth}
                dataLayers={dataLayers}
                {...heroAnimation.configuration}
              />
            )}
          </View>
        </Flex>
      </Flex>
    </WebsiteTheme>
  );
};

Section.displayName = 'Feature';
export default Section;
