import React, { useEffect, useState } from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { MediaBreakpoint, useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { PrimaryDropdown } from '@sendpayments/react-shared/components/dropdown/PrimaryDropdown';
import { ActionIcon } from '@sendpayments/react-shared/components/ActionIcon';
import { PrimaryButton } from '@sendpayments/react-shared/components/buttons/PrimaryButton';
import { SecondaryActionButton } from '@send-components';
import { withTheme, ApplyTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import { SecondaryButton } from '@sendpayments/react-shared/components/buttons/SecondaryButton';
import { NavLink } from '@sendpayments/react-shared/components/NavLink';
import { Link, Flex, View } from '@adobe/react-spectrum';
import ShowMenu from '@spectrum-icons/workflow/ShowMenu';
import Close from '@spectrum-icons/workflow/Close';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import cn from 'class-names';
import { sortBy } from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Link as GatsbyLink, navigate } from 'gatsby';
import { forwardParams } from '@sendpayments/js-utils/services/analytics/legacy';
import { VARIATION_THEME_COLOUR } from '@send-static/constants';
import { breakpoints } from '@send-base/design-system';
import styles from './Header.module.less';
import { BaseLayout } from '../../layouts/BaseLayout';
import { SecondaryFont } from '../SecondaryFont';
import { StaticImage } from '../StaticImage';
import { JustHeading } from '../JustHeading';

const options = [
  { label: 'Log in', value: 'sign-in' },
  { label: 'Register', value: 'sign-up' },
];

const HeaderComponent = ({ selected, navigation, metadata, topGap, backgroundColor }) => {
  const [customClass, setCustomClass] = useState();
  const [showFullScreenMenu, setShowFullScreenMenu] = useState(false);

  const { isMedia } = useBreakpoint(breakpoints);
  const isMediaMobile = isMedia({ to: breakpoints.sm });

  const onPortalSignIn = () => window.open(metadata.portalSignIn);

  const onPortalSignUp = () => window.open(forwardParams(metadata.portalSignUp));

  const onSupport = () => window.open(metadata.sendPaymentsHelp);

  const onSendMoneyOptionSelected = (value) => {
    if (value === options[0].value) {
      onPortalSignIn();
    }

    if (value === options[1].value) {
      onPortalSignUp();
    }
  };

  useScrollPosition(({ prevPos, currPos }) => {
    const isHeaderSticky = currPos.y > prevPos.y;

    if (currPos.y === 0) {
      setTimeout(() => {
        setCustomClass(undefined);
      }, 260);

      return;
    }

    if (isHeaderSticky && customClass !== styles.stickyHeader) {
      setTimeout(() => {
        setCustomClass(styles.stickyHeader);
      }, 250);
    } else if (customClass !== styles.nonStickyHeader) {
      setCustomClass(styles.nonStickyHeader);
    }
  }, []);

  useEffect(() => {
    if (showFullScreenMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showFullScreenMenu]);

  const sortedNavigation = sortBy(navigation, ['index']).filter(({ name }) => name !== 'Home');

  return (
    <>
      <MediaBreakpoint to={breakpoints.sm}>
        {showFullScreenMenu && (
          <WebsiteTheme name={'Header'} theme="LIGHT">
            <div className={styles.menuOverlay}>
              <Flex direction="column" justifyContent="space-between">
                <Flex direction="column">
                  <Flex
                    justifyContent="space-between"
                    height={sizes.size800}
                    marginStart={sizes.size175}
                    marginEnd={sizes.size175}
                  >
                    <View alignSelf="center">
                      <ActionIcon onPress={() => navigate('/')}>
                        <StaticImage src="logo.svg" alt="Logo" width={sizes.size1200} />
                      </ActionIcon>
                    </View>
                    <View alignSelf="center">
                      <ActionIcon onPress={() => setShowFullScreenMenu(false)}>
                        <Close size="M" />
                      </ActionIcon>
                    </View>
                  </Flex>
                </Flex>
                <Flex direction="column">
                  {sortedNavigation.map(({ name, url }) => (
                    <View
                      paddingBottom={(isMediaMobile && sizes.size100) || sizes.size160}
                      key={name}
                      flexGrow="1"
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      <NavLink selected={selected === name} size="L">
                        <SecondaryFont>
                          <Link>
                            <GatsbyLink to={url}>{name}</GatsbyLink>
                          </Link>
                        </SecondaryFont>
                      </NavLink>
                    </View>
                  ))}
                  <View paddingBottom={(isMediaMobile && sizes.size100) || sizes.size160}>
                    <NavLink size="L">
                      <SecondaryFont>
                        <Link onPress={onSupport}>
                          <a>Support</a>
                        </Link>
                      </SecondaryFont>
                    </NavLink>
                  </View>
                  <View
                    alignSelf="center"
                    marginBottom={sizes.size250}
                    justifyContent="center"
                    alignItems="stretch"
                    borderRadius="medium"
                    width={(isMediaMobile && sizes.size3000) || sizes.size4600}
                    backgroundColor="gray-50"
                  >
                    <ApplyTheme displayName="LogInNavigationMobile">
                      <Flex direction="column" margin={(isMediaMobile && sizes.size130) || sizes.size300}>
                        <View>
                          <JustHeading level={3}>
                            <SecondaryFont>Ready to Send?</SecondaryFont>
                          </JustHeading>
                        </View>
                        <View marginBottom={sizes.size130}>
                          If you’re new to Send, register for a new account. Otherwise, log in to continue.
                        </View>
                        <Flex direction="row" gap={sizes.size130} justifyContent="center">
                          <View justifyContent="center" alignSelf="center">
                            <SecondaryActionButton onPress={onPortalSignUp}>Register now</SecondaryActionButton>
                          </View>
                          <View alignSelf="center">
                            <SecondaryButton onPress={onPortalSignIn}>
                              <SecondaryFont>Already a member? Log in</SecondaryFont>
                            </SecondaryButton>
                          </View>
                        </Flex>
                      </Flex>
                    </ApplyTheme>
                  </View>
                  <View alignSelf="center">
                    <PrimaryButton onPress={() => navigate('/partnerships')}>Become a Send Partner</PrimaryButton>
                  </View>
                </Flex>

                <div className={styles.social}>
                  <Flex height={(isMediaMobile && sizes.size500) || sizes.size600} justifyContent="center" gap={sizes.size400}>
                    <View alignSelf="center">
                      <a href="https://www.facebook.com/sendpaymentscom" target="_blank" rel="noreferrer">
                        <StaticImage src="facebook.svg" alt="Facebook" width={sizes.size300} />
                      </a>
                    </View>
                    <View alignSelf="center">
                      <a href="https://www.instagram.com/sendpayments" target="_blank" rel="noreferrer">
                        <StaticImage src="instagram.svg" alt="Instagram" width={sizes.size300} />
                      </a>
                    </View>
                    <View alignSelf="center">
                      <a href="https://www.linkedin.com/company/sendpayments" target="_blank" rel="noreferrer">
                        <StaticImage src="linkedin.svg" alt="Linkedin" width={sizes.size300} />
                      </a>
                    </View>
                    <View alignSelf="center">
                      <a href="https://www.twitter.com/sendpayments" target="_blank" rel="noreferrer">
                        <StaticImage src="twitter.svg" alt="Twitter" width={sizes.size300} />
                      </a>
                    </View>
                  </Flex>
                </div>
              </Flex>
            </div>
          </WebsiteTheme>
        )}
      </MediaBreakpoint>
      {customClass === styles.stickyHeader && <View height={sizes.size900} />}
      <div
        className={cn(styles.container, customClass)}
        style={{ top: customClass !== styles.stickyHeader ? 0 : topGap, backgroundColor }}
      >
        <BaseLayout>
          <Flex height={sizes.size900} gap={isMediaMobile ? sizes.size100 : sizes.size300}>
            <View flexGrow="1" alignSelf="center">
              <ActionIcon onPress={() => navigate('/')}>
                {backgroundColor === VARIATION_THEME_COLOUR && (
                  <StaticImage src="logo-variation.svg" alt="Logo" width={(isMediaMobile && sizes.size1000) || sizes.size1200} />
                )}
                {backgroundColor !== VARIATION_THEME_COLOUR && (
                  <StaticImage src="logo.svg" alt="Logo" width={(isMediaMobile && sizes.size1000) || sizes.size1200} />
                )}
              </ActionIcon>
            </View>
            {!isMediaMobile && (
              <>
                <div className={styles.linksContainer}>
                  {sortedNavigation.map(({ name, url }) => (
                    <View marginStart="10px" key={name} alignSelf="center">
                      <NavLink selected={selected === name}>
                        <SecondaryFont>
                          <Link>
                            <GatsbyLink to={url}>{name}</GatsbyLink>
                          </Link>
                        </SecondaryFont>
                      </NavLink>
                    </View>
                  ))}
                  <View marginStart="10px" alignSelf="center">
                    <NavLink>
                      <SecondaryFont>
                        <Link onPress={onSupport}>
                          <a>Support</a>
                        </Link>
                      </SecondaryFont>
                    </NavLink>
                  </View>
                </div>
              </>
            )}
            <View alignSelf="center" height="50px">
              {backgroundColor === VARIATION_THEME_COLOUR && (
                <ApplyTheme displayName="PrimaryDropdownDarkVariation">
                  <SecondaryFont>
                    <div className={cn(styles.dropdownContainer, styles.dropdownContainerPrimary)}>
                      <PrimaryDropdown
                        minWidth={sizes.size1000}
                        options={options}
                        onSelectionChange={onSendMoneyOptionSelected}
                        UNSAFE_className={styles.dropdown}
                      >
                        Send Money
                      </PrimaryDropdown>
                    </div>
                  </SecondaryFont>
                </ApplyTheme>
              )}
              {backgroundColor !== VARIATION_THEME_COLOUR && (
                <SecondaryFont>
                  <div className={cn(styles.dropdownContainer, styles.dropdownContainerSecondary)}>
                    <PrimaryDropdown
                      minWidth={sizes.size1000}
                      options={options}
                      onSelectionChange={onSendMoneyOptionSelected}
                      UNSAFE_className={styles.dropdown}
                    >
                      Send Money
                    </PrimaryDropdown>
                  </div>
                </SecondaryFont>
              )}
            </View>
            {isMediaMobile && (
              <View alignSelf="center">
                {backgroundColor === VARIATION_THEME_COLOUR && (
                  <ApplyTheme displayName="ActionIconDarkVariation">
                    <ActionIcon onPress={() => setShowFullScreenMenu(true)}>
                      <ShowMenu size="M" />
                    </ActionIcon>
                  </ApplyTheme>
                )}
                {backgroundColor !== VARIATION_THEME_COLOUR && (
                  <ActionIcon onPress={() => setShowFullScreenMenu(true)}>
                    <ShowMenu size="M" />
                  </ActionIcon>
                )}
              </View>
            )}
          </Flex>
        </BaseLayout>
      </div>
    </>
  );
};

HeaderComponent.displayName = 'Header';

export default withTheme(HeaderComponent);
