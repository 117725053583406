import React from 'react';
import cn from 'class-names';
import styles from './BaseLayout.module.less';

const BaseLayout = ({ children, className, bgColour }) => (
  <div className={cn(styles.container, className)} style={{ backgroundColor: bgColour }}>
    <div className={styles.item}>{children}</div>
  </div>
);

export default BaseLayout;
