import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FormsProvider } from '@send-components/Forms/Provider';
import Amplify from 'aws-amplify';
import awsenvconfig from '@sendpayments/graphql/aws-env-config';
import awsconfig from '@send-base/aws-exports';
import { unionBy } from 'lodash';
import Compose from './Compose';

let isInitialized;
const env = process.env.ENV;
const initializeEndpoints = () => {
  if (!isInitialized) {
    isInitialized = true;
    const awsEnvConfig = awsenvconfig[env];
    Amplify.configure({ ...awsEnvConfig, ...awsconfig, aws_cloud_logic_custom: [awsEnvConfig.aws_cloud_logic_custom[0]] });
  }
};
initializeEndpoints();

const Container = ({ pageContext: { data } }) => {
  const sections = data.components ? JSON.parse(data.components) : [];
  const layout = data.layout ? JSON.parse(data.layout) : {};
  const header = data.header ? JSON.parse(data.header) : {};
  const footer = data.header ? JSON.parse(data.footer) : {};
  const navigation = data.navigation ? JSON.parse(data.navigation) : [];

  const { site } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          description
          facebookDomainVerification
          portalSignIn
          portalSignUp
          sendPaymentsHelp
          tc
          privacyPolicy
          financialServiceGide
          productDisclosure
          targetMarketDetermination
        }
      }
    }
  `);

  const forms = sections.reduce((acc, { registerForms = [] }) => unionBy(acc, registerForms, 'formName'), []);

  // Below is a workaround to prevent incorrect styles being applied on first load.
  // mounting nothing at first seems to fix the issue. Other less-hacky solutions haven't seemed to work.
  // for further context see an example issue here: https://github.com/gatsbyjs/gatsby/issues/9121
  const windowExists = typeof window !== 'undefined'; // <-- need to ensure the window object exists as localStorage isn't available during SSR
  const [hasBeenPreviouslyLoaded, setHasBeenPreviouslyLoaded] = React.useState(
    windowExists ? JSON.parse(localStorage.getItem('hasBeenPreviouslyLoaded')) : false, // localstorage used here to persist the value when navigating around the site, otherwise each render is treated as 'first load'
  );
  React.useEffect(() => {
    if (windowExists && !hasBeenPreviouslyLoaded) {
      localStorage.setItem('hasBeenPreviouslyLoaded', true);
      setHasBeenPreviouslyLoaded(true);
    }
  }, []);
  if (!hasBeenPreviouslyLoaded) return null;

  return (
    <FormsProvider registeredForms={forms}>
      <Compose
        sections={sections}
        footer={footer}
        layout={layout}
        metadata={site.siteMetadata}
        header={header}
        navigation={navigation}
      />
    </FormsProvider>
  );
};

export default Container;
