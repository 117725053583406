import { Form as ContactUs } from './ContactUs';
import { Form as JoinUs } from './JoinUs';

const forms = {
  ContactUs,
  JoinUs,
};

export default forms;

export const getFormByName = (name) => {
  if (!forms?.[name]) {
    throw Error(`form with name ${name} do not exists`);
  }

  return forms[name];
};
