import img0 from '@send-images/animations/feature_partner_2_img_0.png';
import img1 from '@send-images/animations/feature_partner_2_img_1.png';
import img2 from '@send-images/animations/feature_partner_2_img_2.png';
import img3 from '@send-images/animations/feature_partner_2_img_3.png';
import img4 from '@send-images/animations/feature_partner_2_img_4.png';
import img5 from '@send-images/animations/feature_partner_2_img_5.png';
import img6 from '@send-images/animations/feature_partner_2_img_6.png';
import img7 from '@send-images/animations/feature_partner_2_img_7.png';
import img8 from '@send-images/animations/feature_partner_2_img_8.png';
import img9 from '@send-images/animations/feature_partner_2_img_9.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 100,
  w: 650,
  h: 510,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 124, h: 157, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 170, h: 126, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 106, h: 106, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 106, h: 106, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 106, h: 106, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 106, h: 106, u: '', p: img5, e: 0 },
    { id: 'image_6', w: 106, h: 106, u: '', p: img6, e: 0 },
    { id: 'image_7', w: 106, h: 106, u: '', p: img7, e: 0 },
    { id: 'image_8', w: 106, h: 106, u: '', p: img8, e: 0 },
    { id: 'image_9', w: 743, h: 205, u: '', p: img9, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'img_0.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 91, s: [100] },
                { t: 101, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 58, s: [0] },
                { t: 101, s: [16] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [242, 88, 0], ix: 2 },
            a: { a: 0, k: [62, 78.5, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 58,
                  s: [26, 26, 100],
                },
                { t: 65, s: [30, 30, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 58,
          op: 101,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'img_1.png',
          cl: 'png',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 91, s: [100] },
                { t: 101, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 58, s: [0] },
                { t: 101, s: [16] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [200.5, 131.5, 0], ix: 2 },
            a: { a: 0, k: [85, 63, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 58,
                  s: [25, 25, 100],
                },
                { t: 65, s: [29, 29, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          ip: 58,
          op: 101,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'img_2.png',
          cl: 'png',
          parent: 4,
          refId: 'image_2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 59, s: [1] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 69, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 91, s: [100] },
                { t: 101, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [54, 52, 0], ix: 2 },
            a: { a: 0, k: [53, 53, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'img_3.png',
          cl: 'png',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [223, 111.5, 0], ix: 2 },
            a: { a: 0, k: [53, 53, 0], ix: 1 },
            s: { a: 0, k: [27, 27, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'img_4.png',
          cl: 'png',
          parent: 6,
          refId: 'image_4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 37, s: [1] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 48, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 91, s: [100] },
                { t: 101, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [53, 53, 0], ix: 2 },
            a: { a: 0, k: [53, 53, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'img_5.png',
          cl: 'png',
          refId: 'image_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [177, 111.5, 0], ix: 2 },
            a: { a: 0, k: [53, 53, 0], ix: 1 },
            s: { a: 0, k: [28, 28, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: 'img_6.png',
          cl: 'png',
          parent: 8,
          refId: 'image_6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 13, s: [1] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 26, s: [100] },
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 91, s: [100] },
                { t: 101, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [53, 53, 0], ix: 2 },
            a: { a: 0, k: [53, 53, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: 'img_7.png',
          cl: 'png',
          refId: 'image_7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [127, 111.5, 0], ix: 2 },
            a: { a: 0, k: [53, 53, 0], ix: 1 },
            s: { a: 0, k: [28, 28, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 2,
          nm: 'img_8.png',
          cl: 'png',
          refId: 'image_8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [76, 111.5, 0], ix: 2 },
            a: { a: 0, k: [53, 53, 0], ix: 1 },
            s: { a: 0, k: [28, 28, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 91, s: [100] },
                { t: 101, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [154, 115.375, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [97.5, 97.5, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-80, -3.5],
                        [70, -3.5],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 1,
                    k: [
                      { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 3, s: [0] },
                      { t: 71, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.56862745098, 0.891176530427, 0.532352941176, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 30, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, -0.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 125,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 2,
          nm: 'img_9.png',
          cl: 'png',
          refId: 'image_9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [150, 112.5, 0], ix: 2 },
            a: { a: 0, k: [371.5, 102.5, 0], ix: 1 },
            s: { a: 0, k: [28, 28, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 101,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Comp 2',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -6, ix: 10 },
        p: { a: 0, k: [331, 275, 0], ix: 2 },
        a: { a: 0, k: [154, 115.5, 0], ix: 1 },
        s: { a: 0, k: [281.097, 281.097, 100], ix: 6 },
      },
      ao: 0,
      w: 308,
      h: 231,
      ip: 0,
      op: 125,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
