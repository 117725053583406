import React from 'react';
import PropTypes from 'prop-types';
import cn from 'class-names';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import styles from './Title.module.less';

const getLevelClass = (level) => {
  const numericLevel = parseInt(level, 10);
  switch (numericLevel) {
    case 1: {
      return styles.one;
    }

    case 2: {
      return styles.two;
    }

    case 3: {
      return styles.three;
    }

    case 4: {
      return styles.four;
    }

    case 5: {
      return styles.five;
    }

    case 6: {
      return styles.six;
    }

    default: {
      return styles.one;
    }
  }
};

const Title = ({ children, level, lineHeight, leftAlign, center, color, weight, applyTransition, underlineColour }) => (
  <div
    style={{ lineHeight, fontWeight: weight, transition: applyTransition, '--underline-colour': underlineColour }}
    className={cn(
      styles.container,
      leftAlign && styles.leftAlign,
      center && styles.center,
      color && styles[color],
      getLevelClass(level),
    )}
  >
    {children}
  </div>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  leftAlign: PropTypes.bool,
  center: PropTypes.bool,
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.oneOf(['primary', 'secondary']),
  applyTransition: PropTypes.string,
  underlineColour: PropTypes.string,
};

Title.defaultProps = {
  level: 1,
  leftAlign: false,
  center: false,
  lineHeight: undefined,
  weight: undefined,
  color: undefined,
  applyTransition: undefined,
  underlineColour: undefined,
};

Title.displayName = 'Title';

export default withTheme(Title);
