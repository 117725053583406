import React from 'react';
import { sizes } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { WebsiteTheme } from '@send-components/WebsiteTheme';
import { Flex, View } from '@adobe/react-spectrum';
import { Title } from '@send-components/Title';
import { Subtitle } from '@send-components/Subtitle';
import { SecondaryFont } from '@send-components/SecondaryFont';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { StaticImage } from '@send-components/StaticImage';
import { withPrefix } from 'gatsby';
import { breakpoints } from '@send-base/design-system';
import ScrollContainer from 'react-indiana-drag-scroll';
import styles from './MeetTheTeam.module.less';

const CardItem = ({ item, height, width, padding }) => {
  const bgImageUrl = withPrefix(`/images/${item.image}`);

  return (
    <WebsiteTheme name={MeetTheTeam.displayName} theme={item.theme}>
      <div
        style={{
          backgroundColor: item.backgroundColor,
          overflow: 'hidden',
          backgroundImage: `url(${bgImageUrl})`,
          backgroundPositionY: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <View width={width} height={height}>
          <View padding={padding}>
            <Flex>
              <Flex direction="column" flexGrow="1">
                <Title leftAlign level={6} lineHeight="1.4">
                  <SecondaryFont>{item.heading}</SecondaryFont>
                </Title>
                <Subtitle level={6} leftAlign>
                  {item.subtitle}
                </Subtitle>
              </Flex>
              {item.link && (
                <a href={item.link} target="_blank" rel="noreferrer">
                  <StaticImage src="linkedin.svg" alt="Linkedin" width={sizes.size400} />
                </a>
              )}
            </Flex>
          </View>
        </View>
      </div>
    </WebsiteTheme>
  );
};

const CardRow = ({ items, idx, itemWidth, itemHeight, itemPadding }) => {
  return (
    <ScrollContainer vertical={false} key={idx}>
      <Flex height={itemHeight}>
        {items.map((item, index) => {
          return <CardItem key={index} index={index} item={item} width={itemWidth} height={itemHeight} padding={itemPadding} />;
        })}
      </Flex>
    </ScrollContainer>
  );
};

const MeetTheTeam = ({ name, theme, props }) => {
  const { minHeight, heading, underlineColour, subtitle, items, itemRow = 1, itemWidth, itemWidthMobile, itemRatio = 1 } = props;

  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.sm });

  // Temporarily hardcoding items per row to 3 to fix scrolling in mobile view whilst we're only displaying three cards.
  const itemPerRow = 3; // If this needs to be dynamic again, it was previously equal to  `Math.ceil(items.length / itemRow)`

  const cardWidth = isMobile ? itemWidthMobile : itemWidth;
  const cardHeight = cardWidth * itemRatio;

  const teamGallery = Array.from({ length: itemRow }, (_, i) => {
    const rowItems = items.slice(i * itemPerRow, (i + 1) * itemPerRow);
    return [...rowItems];
  }).map((rowItem, idx) => {
    return (
      <CardRow
        key={idx}
        idx={idx}
        items={rowItem}
        itemWidth={cardWidth}
        itemHeight={cardHeight}
        itemPadding={isMobile ? sizes.size115 : sizes.size200}
      />
    );
  });

  return (
    <WebsiteTheme name={name} theme={theme}>
      <div className={styles.container}>
        <Flex direction="column" gap={sizes.size600} minHeight={isMobile ? 'auto' : `${minHeight}px`}>
          <Flex direction="column" gap={sizes.size350}>
            <Title level="3" lineHeight="1.4" center underlineColour={underlineColour}>
              <SecondaryFont>
                <HtmlText>{heading}</HtmlText>
              </SecondaryFont>
            </Title>
            <div className={styles.subtitle}>
              <Subtitle center level="3" padding="0rem 2rem">
                <HtmlText>{subtitle}</HtmlText>
              </Subtitle>
            </div>
          </Flex>
          <Flex direction="row" justifyContent="center">
            {teamGallery}
          </Flex>
        </Flex>
      </div>
    </WebsiteTheme>
  );
};

MeetTheTeam.displayName = 'MeetTheTeam';

export default MeetTheTeam;
