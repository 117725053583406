import img0 from '@send-images/animations/callout_personal_3_img_0.png';
import img1 from '@send-images/animations/callout_personal_3_img_1.png';
import img2 from '@send-images/animations/callout_personal_3_img_2.png';
import img3 from '@send-images/animations/callout_personal_3_img_3.png';
import img4 from '@send-images/animations/callout_personal_3_img_4.png';
import img5 from '@send-images/animations/callout_personal_3_img_5.png';
import img6 from '@send-images/animations/callout_personal_3_img_6.png';
import img7 from '@send-images/animations/callout_personal_3_img_7.png';

export const data = {
  v: '5.7.1',
  fr: 25,
  ip: 0,
  op: 150,
  w: 486,
  h: 394,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 114, h: 114, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 59, h: 56, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 33, h: 31, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 57, h: 53, u: '', p: img3, e: 0 },
    { id: 'image_4', w: 105, h: 137, u: '', p: img4, e: 0 },
    { id: 'image_5', w: 45, h: 58, u: '', p: img5, e: 0 },
    { id: 'image_6', w: 164, h: 102, u: '', p: img6, e: 0 },
    { id: 'image_7', w: 146, h: 146, u: '', p: img7, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'Dlyface 2',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [243, 197, 0], ix: 2 },
        a: { a: 0, k: [57, 57, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 147,
              s: [0, 0, 100],
            },
            { t: 149, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 146,
      op: 150,
      st: 75,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Path9.pdf',
      cl: 'pdf',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 107, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 112, s: [100] },
            { t: 124, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 103, s: [0] },
            { t: 124, s: [-108] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 103,
              s: [232, 155, 0],
              to: [-0.318, -2.859, 0],
              ti: [0.759, 6.833, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 119,
              s: [227, 137.502, 0],
              to: [-0.436, -3.923, 0],
              ti: [0.182, 1.641, 0],
            },
            { t: 124, s: [229, 128, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [29.5, 28, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 103,
      op: 178,
      st: 103,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'Path8.pdf',
      cl: 'pdf',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 103, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 110, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 119, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 124, s: [0] },
            { t: 135, s: [1] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 103, s: [0] },
            { t: 135, s: [106] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 103,
              s: [201, 177, 0],
              to: [-3.429, -1.714, 0],
              ti: [8.537, 4.269, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 119,
              s: [182.535, 164.268, 0],
              to: [-7.231, -3.615, 0],
              ti: [2.904, 1.452, 0],
            },
            { t: 132, s: [163, 158, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [16.5, 15.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 103,
      op: 178,
      st: 103,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'Path7.pdf',
      cl: 'pdf',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 103, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 107, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 118, s: [100] },
            { t: 124, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 103, s: [0] },
            { t: 122, s: [43] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 103,
              s: [267, 223, 0],
              to: [4.499, 5.161, 0],
              ti: [-8.915, -10.226, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 119,
              s: [297.63, 253.34, 0],
              to: [2.313, 2.654, 0],
              ti: [-1.167, -1.339, 0],
            },
            { t: 122, s: [301, 262, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [28.5, 26.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 103,
      op: 178,
      st: 103,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'Path6.pdf',
      cl: 'pdf',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 69,
              s: [271, 197, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 91,
              s: [271, 197, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 99,
              s: [271, 197, 0],
              to: [-4.667, 0, 0],
              ti: [4.667, 0, 0],
            },
            { t: 105, s: [243, 197, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [52.5, 68.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 65,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 69,
              s: [41, 41, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 91,
              s: [41, 41, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 94,
              s: [45, 45, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 96,
              s: [39, 39, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 99,
              s: [41, 41, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 105,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 144,
              s: [100, 100, 100],
            },
            { t: 147, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 65,
      op: 151,
      st: 65,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: 'Path5.pdf',
      cl: 'pdf',
      refId: 'image_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [213, 197, 0], ix: 2 },
        a: { a: 0, k: [22.5, 29, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 65,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 69,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 99,
              s: [100, 100, 100],
            },
            { t: 103, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 65,
      op: 140,
      st: 65,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'Chat1',
      refId: 'image_6',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 12, s: [0] },
            { t: 22, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [262, 186, 0], ix: 2 },
        a: { a: 0, k: [-1, 104, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 12,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 17,
              s: [103, 103, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 22,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 60,
              s: [100, 100, 100],
            },
            { t: 64, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 12,
      op: 87,
      st: 12,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: 'Dlyface',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [243, 197, 0], ix: 2 },
        a: { a: 0, k: [57, 57, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 60,
              s: [100, 100, 100],
            },
            { t: 64, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: 'BG',
      refId: 'image_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [243, 197, 0], ix: 2 },
        a: { a: 0, k: [73, 73, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
