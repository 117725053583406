import React from 'react';
import PropTypes from 'prop-types';
import { View, Flex } from '@adobe/react-spectrum';

const CenteredExpandedSection = ({ order, children, horizontalCentered, className }) => (
  <div
    className={className}
    style={{ order, flex: 1, display: 'flex', justifyContent: (horizontalCentered && 'center') || undefined }}
  >
    <Flex justifyContent="center">
      <View alignSelf="center">{children}</View>
    </Flex>
  </div>
);

CenteredExpandedSection.propTypes = {
  order: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node.isRequired,
  horizontalCentered: PropTypes.bool,
  className: PropTypes.string,
};

CenteredExpandedSection.defaultProps = {
  order: undefined,
  horizontalCentered: undefined,
  className: undefined,
};

export default CenteredExpandedSection;
